import React, { Component } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import PersonIcon from "@material-ui/icons/Person";
import Divider from "@material-ui/core/Divider";
import Auth from "../../common/auth";
import moment from "moment";

export default class MessageListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountDetails: null,
    };
  }

  componentDidMount() {
    let accountDetails = Auth.getAccountDetails();
    this.setState({
      accountDetails,
    });
  }

  onClickMessage = async () => {
    await this.storeMessageInLocal();
    this.props.history.replace(
      `/${this.state.accountDetails.slug}/send-message?subjectId=${this.props.data.subject.id}`
    );
    this.props.history.go(0);
  };

  storeMessageInLocal = () => {
    let message = this.props.data && this.props.data;
    Auth.storeMessageData(message);
  };

  renderMessageTextForUser = () => {
    let date = moment(
      this.props.data && this.props.data.lastMessage.createdAt
    ).format("MM.DD.YYYY");
    return (
      <ListItemText
        className="messageListItemText"
        primary={
          <div id="messageDateContainer" className="reverse">
            <Typography variant="body1" className="dullWhite">
              {" "}
              Admin{" "}
            </Typography>
            <Typography variant="body1" className="dullWhite">
              {date}
            </Typography>
          </div>
        }
        secondary={
          <Typography
            id="messageListItemText"
            variant="body1"
            className="whiteColor textAlignEnd reverse"
            style={{ marginRight: "16px" }}
          >
            {(this.props.data && this.props.data.lastMessage.text) || ""}
          </Typography>
        }
      />
    );
  };

  renderMessageTextForAdmin = () => {
    let date = moment(
      this.props.data &&
        this.props.data.lastMessage &&
        this.props.data.lastMessage.createdAt
    ).format("MM.DD.YYYY");
    return (
      <ListItemText
        className="messageListItemText"
        primary={
          <div id="messageDateContainer" className="reverse">
            <Typography variant="body1" className="dullWhite">
              {(this.props.data &&
                this.props.data.user &&
                this.props.data.user.name) ||
                ""}
            </Typography>
            <Typography variant="body1" className="dullWhite">
              {date}
            </Typography>
          </div>
        }
        secondary={
          <Typography
            id="messageListItemText"
            variant="body1"
            className="whiteColor textAlignEnd reverse"
            style={{ marginRight: "16px" }}
          >
            {(this.props.data &&
              this.props.data.lastMessage &&
              this.props.data.lastMessage.text) ||
              ""}
          </Typography>
        }
      />
    );
  };

  render() {
    let isAdmin = Auth.getAdminFlag();
    return (
      <React.Fragment>
        <ListItem
          onClick={this.onClickMessage}
          alignItems="flex-start"
          style={{ background: `${this.props.backgroundColor}` }}
          button
          className="messagListItem reverse"
        >
          <ListItemAvatar>
            <Avatar>
              <PersonIcon />
            </Avatar>
          </ListItemAvatar>
          {isAdmin
            ? this.renderMessageTextForAdmin()
            : this.renderMessageTextForUser()}
        </ListItem>
        <Divider
          aria-hidden="true"
          id="messageListItemDivider"
          style={{ backgroundColor: "white", width: "100%", height: "1px" }}
        />
      </React.Fragment>
    );
  }
}
