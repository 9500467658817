import React, { Component } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";

export default class ArenaMultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOptions: null,
    };
  }

  handleDropDownChange = (selectedOptions) => {
    this.setState({ selectedOptions });
    this.props.onChange(selectedOptions);
  };

  render() {
    if (this.props.isAsync) {
      return (
        <React.Fragment>
          <AsyncSelect
            {...this.props}
            isMulti
            cacheOptions
            defaultOptions
            name="colors"
            value={this.props.selectedOptions}
            loadOptions={this.props.loadOptions}
            onChange={this.handleDropDownChange}
            // menuIsOpen={true}
            className="reverse"
            classNamePrefix="arena-select"
            aria-describedby={`${this.props.id}`}
            aria-label="Arena Dropdown"
          />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Select
          isMulti
          name="colors"
          options={this.props.options}
          value={this.props.selectedOptions}
          onChange={this.handleDropDownChange}
          // menuIsOpen={true}
          className="reverse"
          classNamePrefix="arena-select"
          aria-describedby={`${this.props.id}`}
          aria-label="Arena Dropdown"
          {...this.props}
        />
      </React.Fragment>
    );
  }
}
