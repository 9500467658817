import React from "react";
import CommonStructure from "../../common/mainStructure/main";
import Typography from "@material-ui/core/Typography";
import SocialButton from "../../ui/arenaSocialButton/arenaSocialButton";
import Divider from "@material-ui/core/Divider";
import ArenaInputField from "../../ui/arenaInputField/arenaInput";
import SendButton from "../../ui/sendButton/sendButton";
import PasswordField from "../../ui/arenaPasswordField/passwordField";
import Link from "@material-ui/core/Link";
import Auth from "../../common/auth";
import { isEmail } from "validator";
import SocialLogin from "../../../utils/socialAuth";
import { USER_ROLE } from "../../../constants";
import { isBrowser, isMobile } from "react-device-detect";
import ControlPanel from "../../ui/controlPanel/controlPanel";
const jwtDecode = require("jwt-decode");

export default class Login extends CommonStructure {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      checkErrors: false,
    };
    this.backgroundImg = "/assets/img/header.png";
  }

  componentDidMount() {
    window.addEventListener("keypress", this.keyPressed);
  }

  keyPressed = (event) => {
    if (event.key === "Enter") {
      this.login();
    }
  };

  componentWillUnmount() {
    window.removeEventListener("keypress", this.keyPressed);
  }

  onChangeEmailField = (event) => {
    this.setState({
      email: event.target.value,
    });
  };

  onChangePasswordField = (event) => {
    this.setState({
      password: event.target.value,
    });
  };

  renderTopContent = () => {
    return (
      <div className="signupLoginTopContent">
        <img src="/assets/img/logoArena.png" alt="arenaLogo" className="logo" />
      </div>
    );
  };

  errorInEmail = () => {
    return !this.state.email || !isEmail(this.state.email);
  };

  errorInPassword = () => {
    return !this.state.password;
  };

  checkErrors() {
    if (!this.state.email || !isEmail(this.state.email)) {
      return true;
    }
    if (!this.state.password) {
      window.NotificationUtils.showError(window.t("invalidPasswordLogin"));
      return true;
    }
    return false;
  }

  checkUserAndRedirect = async (response) => {
    if (response && response.refreshToken) {
      Auth.storeUserData(response);
      let userDetails = await this.getUserData();
      Auth.storeUserDetails(userDetails);
      let accountDetails = Auth.getAccountDetails();

      if (!accountDetails) {
        window.NotificationUtils.showError(window.t("slugDetailNotFound"));
        return;
      }
      let isAdmin = await this.checkForUserValidation(
        userDetails,
        accountDetails
      );
      Auth.storeAdminFlag(isAdmin);
      if (isAdmin) {
        window.role = USER_ROLE.SYSTEM_ADMIN;
        window.NotificationUtils.showSuccess(window.t("welcomeToArena"));
        this.props.history.push(`/${accountDetails.slug}`);
      } else {
        window.role = USER_ROLE.USER;
        window.NotificationUtils.showSuccess(window.t("welcomeToArena"));
        this.props.history.push(`/${accountDetails.slug}`);
      }
      if (window.role !== USER_ROLE.GUEST) {
        window.chats.fetchChats = async () => {
          let chats = await this.fetchChats();
          return chats;
        };
      }
    }
  };

  getRecaptchaToken = async () => {
    const token = await this.props.googleReCaptchaProps.executeRecaptcha(
      "login"
    );
    return token;
  };

  login = async () => {
    this.setState({
      checkErrors: true,
    });
    if (this.checkErrors()) {
      return;
    }
    window.NotificationUtils.showInfo(window.t("initiatingLogin"));
    let user = {
      email: this.state.email,
      password: this.state.password,
    };
    try {
      let token = await this.getRecaptchaToken();

      if (!token) {
        throw new Error();
      }
      user.recaptchaToken = token;

      let response = await window.Arena.database.login(user);
      await this.checkUserAndRedirect(response);

      this.setState({
        checkErrors: false,
      });
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError(window.t("somethingWentWrong"));
      this.setState({
        checkErrors: false,
      });
    }
  };

  fetchChats = async () => {
    let response = await window.Arena.database.fetchChats();
    if (
      response &&
      response.data &&
      response.data.items &&
      response.data.items.length > 0
    ) {
      return response.data.items;
    } else {
      return null;
    }
  };

  checkForUserValidation = async (userDetails, accountDetails) => {
    let response = await window.Arena.database.fetchAccountDetailsForAdmin(
      accountDetails.slug
    );
    let details = response && response.data.items[0];
    if (details.canAdminister) {
      return true;
    }
    return false;
  };

  getUserData = async () => {
    let userData = this.getUserId();
    if (!userData) {
      this.setState({
        loading: false,
      });
      return;
    }
    try {
      let response = await window.Arena.database.fetchUserWithId(
        userData.userId
      );
      return response.data;
    } catch (e) {
      console.error(e);
      this.setState({
        loading: false,
      });
    }
  };

  getUserId = () => {
    let accessToken = Auth.getToken();
    if (!accessToken) {
      return null;
    }

    try {
      let userData = jwtDecode(accessToken);
      return userData;
    } catch (e) {
      console.error(e);
    }
  };

  onClickForgotPassword = async () => {
    this.props.history.push("enter-email");
  };

  redirectToSignup = () => {
    this.props.history.push("signup");
  };

  onClickFacebook = () => {
    SocialLogin.loginFacebook()
      .then(async (result) => {
        if (result && result.refreshToken) {
          await this.checkUserAndRedirect(result);
        }
      })
      .catch((e) => {
        console.error(e);
        e && window.NotificationUtils.showError(e?.response?.data?.message);
      });
  };

  onClickGoogle = () => {
    SocialLogin.loginGoogle()
      .then(async (result) => {
        if (result && result.refreshToken) {
          await this.checkUserAndRedirect(result);
        }
      })
      .catch((e) => {
        console.error(e);
        e && window.NotificationUtils.showError(e?.response?.data?.message);
      });
  };

  renderControlPanel = () => {
    if (isMobile) {
      return (
        <React.Fragment>
          <ControlPanel
            history={this.props.history}
            placement="top"
            showAuthControlPanel={true}
          />
        </React.Fragment>
      );
    }
    if (isBrowser) {
      return (
        <React.Fragment>
          <ControlPanel
            history={this.props.history}
            placement="left"
            showAuthControlPanel={true}
          />
        </React.Fragment>
      );
    }
  };

  handleVerify = (token) => {
    this.setState({
      recaptchaToken: token,
    });
  };

  renderBottomContent = () => {
    return (
      <div className="loginSignupOverlap">
        <div className="flexCenter loginToSystemText">
          <Typography variant="h4" className="whiteColor bold zIndex">
            {window.t("loginToSystem")}
          </Typography>
        </div>
        <div
          className="flexCenter signupSocialNetworkText"
          style={{ marginBottom: "26px" }}
        >
          <Typography variant="body1" className="whiteColor">
            {window.t("usingSocialNetwork")}
          </Typography>
        </div>

        <div className="displayFlex reverse justifyContentCenter">
          <div className="marginRight16">
            <SocialButton
              onClick={this.onClickFacebook}
              size={"medium"}
              buttonType="facebook"
            />
          </div>
          <div className="marginLeft16">
            <SocialButton
              onClick={this.onClickGoogle}
              size={"medium"}
              buttonType="google"
            />
          </div>
        </div>
        <div className="diplayFlex loginDividerContainer">
          <Divider
            aria-hidden="true"
            style={{
              backgroundColor: "white",
              width: "40%",
              height: "1px",
              opacity: "0.5",
              marginRight: "8px",
            }}
          />
          <Typography variant="body1" className="dullWhite">
            {window.t("or")}
          </Typography>
          <Divider
            aria-hidden="true"
            style={{
              backgroundColor: "white",
              width: "40%",
              height: "1px",
              opacity: "0.5",
              marginLeft: "8px",
            }}
          />
        </div>
        <div className="loginPageEmailLink reverse">
          <Typography variant="body1" className="dullWhite">
            {window.t("email")}
          </Typography>
        </div>
        <div className="loginInputFieldContainer">
          <div className="fieldWidth">
            <ArenaInputField
              placeholder={window.t("email")}
              error={this.state.checkErrors && this.errorInEmail()}
              helperText={
                this.state.checkErrors && this.errorInEmail() && "Invalid Email"
              }
              onChange={this.onChangeEmailField}
              value={this.state.activateField}
            />
          </div>
        </div>

        <div className="loginPageEmailLink reverse">
          <Typography variant="body1" className="dullWhite">
            {window.t("password")}
          </Typography>
        </div>
        <div className="loginInputFieldContainer">
          <div className="fieldWidth">
            <PasswordField
              placeholder={window.t("password")}
              noEye={false}
              error={this.state.checkErrors && this.errorInPassword()}
              label={
                this.state.checkErrors &&
                this.errorInPassword() &&
                window.t("invalidPassword")
              }
              helperText={
                this.state.checkErrors &&
                this.errorInPassword() &&
                window.t("invalidPassword")
              }
              onChange={this.onChangePasswordField}
              value={this.state.password}
            />
          </div>
        </div>
        <div className="loginPageEmailLink reverse">
          <Typography variant="body1" className="dullWhite">
            <Link id="messagesLinkTag" onClick={this.onClickForgotPassword}>
              {window.t("forgotPassword")}
            </Link>
          </Typography>
        </div>
        <div id="loginSendButtonContainer">
          <SendButton
            size={"small"}
            onClick={this.login}
            text={window.t("enter")}
          />
        </div>

        {this.renderControlPanel()}
      </div>
    );
  };
}
