import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import UserPill from "../userAndIcon/userPill";
import CommentActionButton from "../commentSectionButton/commentActionButton";
import Divider from "@material-ui/core/Divider";
import Lottie from "react-lottie";
import animationData from "../../../like.json";
import Auth from "../../common/auth";
import { ReactComponent as Like } from "../../../icons/like.svg";
import { ReactComponent as Delete } from "../../../icons/Delete.svg";

const options = {
  loop: false,
  autoplay: false,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
export default class SubComment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      replyCommentText: "",
      showReplyPrompt: false,
      isLiked: this.props.data.isLiked || false,
      stopAnimation: true,
      userDetails: null,
    };
  }

  componentDidMount() {
    this.fetchUserAndSaveDetails();
  }

  fetchUserAndSaveDetails = () => {
    let userDetails = Auth.getUserDetails();
    this.setState({
      userDetails: userDetails,
    });
    return userDetails;
  };

  onChangeReplyText = (e) => {
    this.setState({
      replyCommentText: e.target.value,
    });
  };

  openReplyPrompt = () => {
    this.setState({
      showReplyPrompt: !this.state.showReplyPrompt,
    });
  };

  getRecaptchaToken = async () => {
    const token = await this.props.googleReCaptchaProps.executeRecaptcha(
      "subcomments"
    );
    return token;
  };

  onClickSendButton = async () => {
    let stateTextValue = this.state.replyCommentText;
    let data = {
      replyTo: this.props.data.id,
      text: stateTextValue,
      // subject: this.state.subjectData && this.state.subjectData.id
    };
    try {
      let token = await this.getRecaptchaToken();
      if (!token) {
        throw new Error();
      }
      data.recaptchaToken = token;

      await window.Arena.database.commentOnSubject(
        data,
        this.props.data && this.props.data.subject
      );

      window.NotificationUtils.showSuccess(
        window.t("commentPostedSuccessfully")
      );
      this.setState({
        showReplyPrompt: false,
        replyCommentText: "",
      });
    } catch (e) {
      window.NotificationUtils.showError(window.t("somethingWentWrong"));
    }
  };

  showAnimation = () => {
    setTimeout(() => {
      this.setState({
        isLiked: true,
        stopAnimation: true,
      });
    }, 1000);
  };

  onClickLike = async () => {
    let commentId = this.props.data && this.props.data.id;
    let index = this.props.index;
    let previousLikeCount =
      (this.props.data && this.props.data.likesCount) || 0;
    let subComment = true;
    let parentIndex = this.props.parentCommentIndex;

    this.props.onClickLike &&
      this.props.onClickLike(
        commentId,
        index,
        previousLikeCount,
        subComment,
        parentIndex
      );
    this.setState(
      {
        stopAnimation: false,
      },
      this.showAnimation
    );
  };

  onClickUnLike = async () => {
    let commentId = this.props.data && this.props.data.id;
    let index = this.props.index;
    let previousLikeCount =
      (this.props.data && this.props.data.likesCount) || 0;
    let subComment = true;
    let parentIndex = this.props.parentCommentIndex;
    this.props.onClickUnLike &&
      this.props.onClickUnLike(
        commentId,
        index,
        previousLikeCount,
        subComment,
        parentIndex
      );
    this.setState({
      isLiked: false,
    });
  };

  onClickDelete = async () => {
    let commentId = this.props.data && this.props.data.id;
    let index = this.props.index;
    let parentIndex = this.props.parentCommentIndex;
    this.props.onClickDelete &&
      this.props.onClickDelete(
        this.props.data.user.name || "Guest",
        commentId,
        index,
        true,
        parentIndex
      );
  };

  renderDeleteButton() {
    if (Auth.getAdminFlag()) {
      return (
        <CommentActionButton
          aria-label={"Delete"}
          value={Delete}
          onClick={this.onClickDelete}
        />
      );
    }
  }

  renderLikeButton = () => {
    if (this.state.isLiked) {
      return (
        <div
          className={` ${this.props.onClickUnLike ? "" : "pointerEventsNone"}`}
        >
          <CommentActionButton
            viewBox="0 0 250.12 235.35"
            aria-label={"Unlike"}
            value={Like}
            onClick={this.onClickUnLike}
            extraClass={"likeFilledButton"}
          />
        </div>
      );
    }
    return (
      <div
        className={` animationHeart ${
          this.props.onClickLike ? "" : "pointerEventsNone"
        }`}
        onClick={this.onClickLike}
      >
        <Lottie
          ariaLabel={`${window.t("like")}`}
          options={options}
          height={60}
          width={60}
          isStopped={this.state.stopAnimation}
        />
      </div>
    );
  };

  render() {
    let data = this.props.data;
    let color = data.user.isAdmin ? "#FEA030" : "#fff";
    return (
      <React.Fragment>
        <div
          className="commentMainContainer subCommentPadding"
          style={{ background: `${this.props.backgroundColor}` }}
        >
          <div className="commentAndDivider reverse">
            <Divider
              aria-hidden="true"
              className="subCommentDivider"
              orientation="vertical"
              variant="middle"
            />
            <div>
              <UserPill
                iconColor={color}
                user={
                  this.props.data.user &&
                  (this.props.data.user.name || window.t("guest"))
                }
              />
              <Typography
                variant="body1"
                className="dullerWhite textAlignEnd marginTopBottom reverse"
                aria-label={this.props.data.text}
                tabIndex={0}
              >
                {this.props.data.text}
              </Typography>
            </div>
          </div>
          <div className="commentComActionButtons reverse">
            {this.renderLikeButton()}
            {this.renderDeleteButton()}
            <div className="commentMainButton" />
            <CommentActionButton
              text={window.t("likes")}
              value={this.props.data.likesCount || 0}
              aria-label={`${this.props.data.likesCount || 0} ${window.t(
                "likes"
              )}`}
              extraClass="pointerEventsNone"
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
