import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import CategoryDiscussionCard from "../categoryDiscussionCard/categoryDiscussionCard";
import DateCountdown from "../dateCountdown/dateCountdown";
import moment from "moment";
import "moment/locale/he";
export default class ActiveDiscussionCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  convertDateToTimeStamp = () => {
    let date = this.props.data && this.props.data.endDate;
    let timeStamp = moment(date).format("x");
    return +timeStamp;
  };

  getDate = () => {
    let date = this.props.data && this.props.data.endDate;
    let locale = window.navigator.language;
    let readableDate = moment(date).locale(locale).format("DD MMM YYYY");
    return readableDate;
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="activeDiscussionCardContainer"
          style={{
            background: `${this.props.backgroundColor}`,
          }}
          onClick={this.props.onClick}
        >
          <CategoryDiscussionCard
            t={this.props.t}
            onClickShare={this.props.onClickShare}
            data={this.props.data}
            isActiveDiscussion={true}
          />
          <div className="activeDiscussionDeadlineContainer">
            <Typography
              variant="body1"
              className="whiteColor bold marginRight8"
            >
              {this.getDate()}
            </Typography>

            <Typography
              variant="body1"
              className="whiteColor bold marginRight8"
            >
              {this.props.t("openUntil")}
            </Typography>
          </div>
          <div className="activeDiscussDateCountdown">
            <DateCountdown
              t={this.props.t}
              timeStamp={this.convertDateToTimeStamp()}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
