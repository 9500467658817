import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

export default class PasswordField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="arenaPassworField">
          <FormControl variant="outlined">
            <OutlinedInput
              className="outlined-adornment-password"
              type={this.state.showPassword ? "text" : "password"}
              value={this.props.value}
              onChange={this.props.onChange}
              placeholder={window.t("password")}
              {...this.props}
              endAdornment={
                <InputAdornment position="end">
                  {this.props.noEye ? null : (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      edge="end"
                    >
                      {this.state.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  )}
                </InputAdornment>
              }
              notched={false}
              inputProps={{ "aria-label": "Arena Password-Field" }}
            />
          </FormControl>
        </div>
      </React.Fragment>
    );
  }
}
