import React, { Component } from "react";
import Countdown from "react-countdown";
import Typography from "@material-ui/core/Typography";

export default class DateCountdown extends Component {
  renderDateComponent = ({ days, hours, minutes, seconds }) => {
    return (
      <React.Fragment>
        <div
          className="dateComponentContainer"
          style={{
            background: `${this.props.backgroundColor}`,
          }}
        >
          <div className="dateSubContainer">
            <Typography
              role="heading"
              aria-level="4"
              className="whiteColor"
              variant="h5"
            >
              {days}
            </Typography>
            <Typography className="dullWhite" variant="body1">
              {this.props.t("days")}
            </Typography>
          </div>
          <div className="dateComponentBullet">.</div>
          <div className="dateSubContainer">
            <Typography
              role="heading"
              aria-level="4"
              className="whiteColor"
              variant="h5"
            >
              {hours}
            </Typography>
            <Typography className="dullWhite" variant="body1">
              {this.props.t("hours")}
            </Typography>
          </div>
          <div className="dateComponentBullet">.</div>
          <div className="dateSubContainer">
            <Typography
              role="heading"
              aria-level="4"
              className="whiteColor"
              variant="h5"
            >
              {minutes}
            </Typography>
            <Typography className="dullWhite" variant="body1">
              {this.props.t("minutes")}
            </Typography>
          </div>
          <div className="dateComponentBullet">.</div>
          <div className="dateSubContainer">
            <Typography
              role="heading"
              aria-level="4"
              className="whiteColor"
              variant="h5"
            >
              {seconds}
            </Typography>
            <Typography className="dullWhite" variant="body1">
              {this.props.t("seconds")}
            </Typography>
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Countdown
          date={this.props.timeStamp}
          renderer={this.renderDateComponent}
          {...this.props}
        />
      </React.Fragment>
    );
  }
}
