import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import UserPill from "../userAndIcon/userPill";
import CommentActionButton from "../commentSectionButton/commentActionButton";
import SendButton from "../sendButton/sendButton";
import ReplyComment from "../replyComment/replyComment";
import Auth from "../../common/auth";
import Lottie from "react-lottie";
import animationData from "../../../like.json";
import { ReactComponent as Delete } from "../../../icons/Delete.svg";
import { ReactComponent as Like } from "../../../icons/like.svg";
import { isMobile } from "react-device-detect";

const options = {
  loop: false,
  autoplay: false,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default class Comment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      replyCommentText: "",
      showReplyPrompt: false,
      userDetails: Auth.getUserDetails(),
      isLiked: this.props.data?.isLiked || false,
      stopAnimation: true,
    };
  }

  componentDidMount() {
    this.fetchUserAndSaveDetails();
  }

  fetchUserAndSaveDetails = () => {
    let userDetails = Auth.getUserDetails();
    this.setState({
      userDetails: userDetails,
    });
    return userDetails;
  };

  onChangeReplyText = (e) => {
    this.setState({
      replyCommentText: e.target.value,
    });
  };

  showAnimation = () => {
    setTimeout(() => {
      this.setState({
        isLiked: true,
        stopAnimation: true,
      });
    }, 1000);
  };

  onClickLike = async () => {
    let commentId = this.props.data && this.props.data.id;
    let index = this.props.index;
    let previousLikeCount =
      (this.props.data && this.props.data.likesCount) || 0;
    this.props.onClickLike &&
      this.props.onClickLike(commentId, index, previousLikeCount);
    this.setState(
      {
        stopAnimation: false,
      },
      this.showAnimation
    );
  };

  onClickUnLike = async () => {
    let commentId = this.props.data && this.props.data.id;
    let index = this.props.index;
    let previousLikeCount =
      (this.props.data && this.props.data.likesCount) || 0;
    this.props.onClickUnLike &&
      this.props.onClickUnLike(commentId, index, previousLikeCount);
    this.setState({
      isLiked: false,
    });
  };

  onClickDelete = async () => {
    let commentId = this.props.data && this.props.data.id;
    let index = this.props.index;
    this.props.onClickDelete &&
      this.props.onClickDelete(
        this.props.data.user.name || "Guest",
        commentId,
        index
      );
  };

  openReplyPrompt = () => {
    this.setState({
      showReplyPrompt: !this.state.showReplyPrompt,
    });
  };

  getRecaptchaToken = async () => {
    const token = await this.props.googleReCaptchaProps.executeRecaptcha(
      "comments"
    );
    return token;
  };

  onClickSendButton = async () => {
    let stateTextValue = this.state.replyCommentText;

    if (!stateTextValue.length) {
      window.NotificationUtils.showError(window.t("enterComment"));
      return;
    }
    window.NotificationUtils.showInfo(window.t("processingYourRequest"));
    let index = this.props.index;
    let replyCount = this.props.data.repliesCount || 0;
    let data = {
      replyTo: this.props.data.id,
      text: stateTextValue,

      // subject: this.state.subjectData && this.state.subjectData.id
    };
    try {
      let token = await this.getRecaptchaToken();
      if (!token) {
        throw new Error();
      }
      data.recaptchaToken = token;

      let response = await window.Arena.database.commentOnSubject(
        data,
        this.props.data && this.props.data.subject
      );
      window.NotificationUtils.showSuccess(
        window.t("commentPostedSuccessfully")
      );
      this.setState({
        showReplyPrompt: false,
        replyCommentText: "",
      });
      this.props.addReplyCommentLocally &&
        this.props.addReplyCommentLocally(response.data, index, replyCount);
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError(window.t("somethingWentWrong"));
    }
  };

  renderDeleteButton() {
    if (Auth.getAdminFlag()) {
      return (
        <CommentActionButton
          aria-label={"Delete"}
          value={Delete}
          onClick={this.onClickDelete}
        />
      );
    }
  }

  renderLikeButton() {
    if (this.state.isLiked) {
      return (
        <div
          className={`spaceBetweenLikeCount ${
            this.props.onClickUnLike ? "" : "pointerEventsNone"
          }`}
        >
          <CommentActionButton
            viewBox="0 0 250.12 235.35"
            aria-label={"Unlike"}
            value={Like}
            onClick={this.onClickUnLike}
            extraClass={"likeFilledButton"}
          />
        </div>
      );
    }
    return (
      <div
        className={`spaceBetweenAnimatedLike animationHeart ${
          this.props.onClickLike ? "" : "pointerEventsNone"
        }`}
        onClick={this.onClickLike}
      >
        <Lottie
          ariaLabel={`${window.t("like")}`}
          options={options}
          height={60}
          width={isMobile ? 30 : 60}
          isStopped={this.state.stopAnimation}
        />
      </div>
    );
  }

  getColor = (data) => {
    if (data.user.isAdmin) {
      return "#FEA030";
    }
    for (let expert of this.props.subjectExperts) {
      if (data.user.id === expert.id) {
        return "#FEA030";
      }
    }
    return "#fff";
  };

  render() {
    let data = this.props.data;
    let color = this.getColor(data);
    return (
      <React.Fragment>
        <div
          className="commentMainContainer"
          style={{ background: `${this.props.backgroundColor}` }}
        >
          <UserPill
            iconColor={color}
            user={this.props.data.user.name || window.t("guest")}
          />
          <Typography
            variant="body1"
            className="dullerWhite textAlignEnd marginTopBottom reverse fontSize18"
            aria-label={this.props.data.text}
            tabIndex={0}
          >
            {this.props.data.text}
          </Typography>
          <div className="commentComActionButtons reverse">
            <div className="displayFlex alignItemsCenter reverse commentsActionButtons">
              {this.renderLikeButton()}
              {this.renderDeleteButton()}
            </div>
            {this.props.showSendButton && (
              <SendButton
                extraClass="commentMainButton"
                text={window.t("addComment")}
                fullWidth={true}
                size={"small"}
                onClick={this.openReplyPrompt}
              />
            )}
            <div className="displayFlex reverse commentsActionButtons">
              <div className="spaceBetweenLikeCount ">
                <CommentActionButton
                  extraClass="pointerEventsNone"
                  text={window.t("likes")}
                  value={this.props.data.likesCount || 0}
                  aria-label={`${this.props.data.likesCount || 0} ${window.t(
                    "likes"
                  )}`}
                />
              </div>
              <CommentActionButton
                extraClass="pointerEventsNone"
                text={window.t("replies")}
                value={this.props.data.repliesCount || 0}
                aria-label={`${this.props.data.repliesCount || 0} ${window.t(
                  "replies"
                )}`}
              />
            </div>
          </div>
          {this.state.showReplyPrompt && (
            <ReplyComment
              value={this.state.replyCommentText}
              onChange={this.onChangeReplyText}
              onClick={this.onClickSendButton}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}
