import React from "react";
import CommonStructure from "../../common/mainStructure/main";
import LandingPageContent from "../landingPageContent/landingPageContent";
import Auth from "../../common/auth";

import ArenaChangePassword from "../arenaChangePassword/arenaChangePassword";

export default class LandingPage extends CommonStructure {
  constructor(props) {
    super(props);
    this.state = {
      changePassword: false,
    };
    this.backgroundImg = "/assets/img/header.png";
  }

  componentDidMount = async () => {
    let response = await window.Arena.database.isLoggedIn();

    if (!response) {
      let response = await window.Arena.database.guestSignup();
      window.NotificationUtils.showInfo(window.t("signedInAsGuestUser"));
      Auth.storeUserData(response);
    }
    let accountDetails = Auth.getAccountDetails();
    this.setState({
      accountDetails,
    });
  };

  openChangePassword = () => {
    this.setState({
      changePassword: true,
    });
  };

  closeChangePassword = () => {
    this.setState({
      changePassword: false,
    });
  };

  onClickSignUpButton = () => {
    this.props.history.push(`${this.props.history.location.pathname}/signup`);
  };

  renderTopContent = () => {
    return (
      <React.Fragment>
        <div className="signupLoginTopContent">
          <img
            src="/assets/img/logoArena.png"
            alt="arenaLogo"
            className="logo"
          />
        </div>
      </React.Fragment>
    );
  };

  renderBottomContent = () => {
    return (
      <div className="landingPageMainContainer">
        <LandingPageContent
          data={{
            paraOne:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut              labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut           aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum",
            paraTwo:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut              labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut           aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum",
            highlighted:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut                        prehenderit in voluptate velit esse cillum",
          }}
        />
        {this.state.changePassword && (
          <ArenaChangePassword open={true} onClose={this.closeChangePassword} />
        )}
      </div>
    );
  };
}
