import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export default class ProgressBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div id="progressBar" className="flexColumnCenter">
          <CircularProgressbar
            value={this.props.value}
            // text={this.props.value}
            styles={buildStyles({
              pathColor: "#65D2FC",
              textColor: "##ffffff",
              trailColor: "#262833",
              backgroundColor: "#000",
            })}
          />
          <Typography
            id="progressValue"
            variant="h6"
            className="dullWhite textAlignCenter"
            style={{ color: "#ECE9F1" }}
          >
            {this.props.data}
          </Typography>
          <Typography
            variant="body1"
            className="dullWhite textAlignCenter"
            style={{ color: "#ECE9F1" }}
          >
            {this.props.text}
          </Typography>
        </div>
      </React.Fragment>
    );
  }
}
