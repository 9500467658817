import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";

import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { isBrowser, isMobile } from "react-device-detect";
import ControlPanel from "../../ui/controlPanel/controlPanel";
import Footer from "../../ui/footer/footer";
import { USER_ROLE } from "../../../constants";
import Link from "@material-ui/core/Link";
export default class AccessibilityDisclaimer extends Component {
  renderControlPanel = () => {
    let role = window.role || USER_ROLE.GUEST;
    let isGuest = Boolean(role === USER_ROLE.GUEST);
    if (isMobile) {
      return (
        <React.Fragment>
          <ControlPanel
            history={this.props.history}
            placement="top"
            showAuthControlPanel={isGuest ? true : false}
          />
        </React.Fragment>
      );
    }
    if (isBrowser) {
      return (
        <React.Fragment>
          <ControlPanel
            history={this.props.history}
            placement="left"
            showAuthControlPanel={isGuest ? true : false}
          />
        </React.Fragment>
      );
    }
  };

  render = () => {
    return (
      <React.Fragment>
        <div id="termsContainer">
          <Typography
            className="dullWhite marginBottom48 textAlignCenter"
            variant="h4"
          >
            הצהרת נגישות
          </Typography>

          <Typography
            className="dullWhite marginBottom24 textAlignStart bold"
            variant="h5"
          >
            רקע כללי
          </Typography>

          <Typography
            className="dullWhite marginBottom24 textAlignStart"
            variant="body2"
          >
            חחברתנו שואפת לספק חווית שימוש מרבית באתר לכלל הציבור לרבות ציבור
            אנשים עם מוגבלויות. השקענו מאמצים רבים במטרה לאפשר, להקל ולייעל את
            השימוש באתר בדגש על צרכי ציבור זה. הנגשת האתר בוצעה לרמה AA לפי תקן
            ישראלי 5568 (המבוסס על התקן העולמי WCAG 2.0).
          </Typography>
          <Typography
            className="dullWhite marginBottom24 textAlignStart bold"
            variant="h5"
          >
            כיצד הנגישות באה לידי ביטוי באתר
          </Typography>

          <div className="marginBottom24">
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                אפשרות ניווט באמצעות מקלדת בלבד וזיהוי קל של האזור אשר במיקוד
              </Typography>
            </div>
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                שינוי ניגודיות צבעים.
              </Typography>
            </div>
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                הדגשת קישורים ( מופעל בעת שינוי ניגודיות צבעים).
              </Typography>
            </div>
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                שינוי צבע טקסט וצבע רקע עבור לקוי ראייה ועיוורי צבעים ( מופעל
                בעת שינוי ניגודיות צבעים).
              </Typography>
            </div>
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                תיאור טקסטואלי לאייקונים עבור טכנולוגיות מסייעות.
              </Typography>
            </div>
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                הנגשת תפריטים, טפסים, היררכיית כותרות, רכיב טאבים, חלונות קופצים
                ועוד.
              </Typography>
            </div>
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                הצהרת נגישות עם הסבר על תאימות, פערים ואחראי נגישות.
              </Typography>
            </div>
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                התאמת הנגישות נבדקה באמצעי עזר כגון מסכים ומכשירים שונים.
              </Typography>
            </div>
          </div>
          <Typography
            className="dullWhite marginBottom24 textAlignStart bold"
            variant="h5"
          >
            הערה:
          </Typography>

          <div className="marginBottom24">
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                שינוי גודל הגופן מתבצע באמצעות המקלדת,
              </Typography>
            </div>
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                עבור הגדלת הגופן יש ללחוץ על המקשים ctrl +,
              </Typography>
            </div>
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                עבור הקטנת הגופן יש ללחוץ על המקשים ctrl-
              </Typography>
            </div>
          </div>
          <Typography
            className="dullWhite marginBottom24 textAlignStart bold"
            variant="h5"
          >
            כיצד להשתמש בתוסף הנגישות
          </Typography>
          <Typography
            className="dullWhite marginBottom24 textAlignStart"
            variant="body2"
          >
            לחיצה על תוסף הנגישות תפעיל את התוסף.
          </Typography>
          <Typography
            className="dullWhite marginBottom24 textAlignStart"
            variant="body2"
          >
            באתר זה מוטמע תוסף שהינו רכיב נגישות של חברת enable המתמחה בהנגשת
            אתרים. התוסף פועל על פי התקן הישראלי ת”י 5568 ברמה AA. התוסף כפוף
            לתנאי השימוש של היצרן.
          </Typography>

          <Typography
            className="dullWhite marginBottom24 textAlignStart bold"
            variant="h5"
          >
            מדריך למשתמש בתפריט:
          </Typography>

          <div className="marginBottom24">
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                כפתור התאמת האתר ותגיות האתר עבור מכשירי עזר וטכנולוגיות עבור
                בעלי מוגבלויות
              </Typography>
            </div>
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                כפתור אפשור ניווט בעזרת מקשי המקלדת בין הקישורים שבאתר
              </Typography>
            </div>
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                כפתור השבתת הבהובים ו/או אלמנטים נעים על המסך
              </Typography>
            </div>
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                כפתור אפשור מצב מונוכרום שחור לבן עבור עיוורי צבעים
              </Typography>
            </div>
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                כפתור ספיה (גוון חום)
              </Typography>
            </div>
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                כפתור שינוי ניגודיות גבוהה
              </Typography>
            </div>
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                כפתור שחור צהוב
              </Typography>
            </div>
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                כפתור היפוך צבעים
              </Typography>
            </div>
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                כפתור המדגיש בצורה ברורה את כל תגי הכותרות המופיעים באתר
              </Typography>
            </div>
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                כפתור המדגיש בצורה ברורה את כל הקישורים המופיעים באתר
              </Typography>
            </div>
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                כפתור המציג את התיאור החלופי של כל התמונות המופיעות באתר במעבר
                עכבר
              </Typography>
            </div>
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                כפתור המציג תיאור קבוע של התמונות באתר
              </Typography>
            </div>
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                כפתור ביטול שימוש בגופן קריא
              </Typography>
            </div>
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                כפתור הגדלת גודל הגופנים באתר
              </Typography>
            </div>
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                כפתור הקטנת גודל הגופנים באתר
              </Typography>
            </div>
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                כפתור הגדלת כל התצוגה לכ־200%
              </Typography>
            </div>
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                כפתור הקטנת כל התצוגה לכ־70%
              </Typography>
            </div>
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                כפתור הגדלת סמן העכבר
              </Typography>
            </div>
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                כפתור הגדלת סמן העכבר ושינוי צבעו לשחור
              </Typography>
            </div>
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                כפתור מצב קריאת האתר
              </Typography>
            </div>
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                כפתור המציג את הצהרת הנגישות
              </Typography>
            </div>
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                כפתור איפוס המבטל את הנגישות
              </Typography>
            </div>
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                כפתור שליחת משוב נגישות
              </Typography>
            </div>
            <div className="displayFlex marginBottom24">
              <FiberManualRecordIcon className="dullWhite" fontSize="small" />
              <Typography className="dullWhite marginRight8" variant="body2">
                כפתור שינוי שפת הסרגל והצהרת הנגישות בהתאם
              </Typography>
            </div>
          </div>
          <Typography
            className="dullWhite marginBottom24 textAlignStart bold"
            variant="h5"
          >
            תאימות דפדפנים
          </Typography>
          <Typography
            className="dullWhite marginBottom24 textAlignStart"
            variant="body2"
          >
            האתר נבדק בדפדפנים:
          </Typography>
          <Typography
            className="dullWhite marginBottom24 textAlignStart"
            variant="body2"
          >
            כרום (google crome גרסה 67.0.3396.99) , מוזילה פיירפוקס (Mozilla
            firefox) גרסה 60.0.2 מיקרוסופט אדג׳ (Microsoft Edge) , ספארי
          </Typography>
          <Typography
            className="dullWhite marginBottom24 textAlignStart"
            variant="body2"
          >
            האתר נבדק במערכות הפעלה חלונות 10, אנדרואיד X , אייפון
          </Typography>
          <Typography
            className="dullWhite marginBottom24 textAlignStart bold"
            variant="h5"
          >
            רכיבים ואזורים לא נגישים
          </Typography>
          <Typography
            className="dullWhite marginBottom24 textAlignStart"
            variant="body2"
          >
            אנו מודעים לכך כי ישנם מספר רכיבים באתר אשר אינם נגישים באופן חלקי
            או מלא. לצערנו אין באפשרותנו להנגיש רכיבים ואזורים אלו ואנו עושים
            מאמצים לאתר חלופות ופתרונות לכך. עד אשר נאתר חלופות אלו, ניתן לפנות
            לאחראי הנגישות שפרטיו רשומים בהמשך עמוד זה לקבלת שיורת נגיש עבור
            אזורים אלו
          </Typography>
          <Typography
            className="dullWhite marginBottom24 textAlignStart bold"
            variant="h5"
          >
            להלן רשימת הרכיבים ואזורים אשר לא נגישים באתר:
          </Typography>
          <Typography
            className="dullWhite marginBottom24 textAlignStart"
            variant="body2"
          >
            רכיבי צד ג'- facebook , google + לטובת הרשמה וכניסה
          </Typography>
          <Typography
            className="dullWhite marginBottom24 textAlignStart bold"
            variant="h5"
          >
            פרטי אחראי נגישות
          </Typography>
          <Typography
            className="dullWhite marginBottom24 textAlignStart"
            variant="body2"
          >
            לצערנו, למרות המאמצים והמשאבים הרבים שהשקענו בהנגשת האתר, יתכן
            ועדיין חלק מהתכנים פחות מונגשים, ולכן אם בכל זאת נתקלתם בפער בהנגשה
            אשר מפריע לכם ומקשה על שימוש תקין וקל באתר- נשמח לקבל פנייתכם בנושא.
            עמכם הסליחה מראש.
          </Typography>
          <Typography
            className="dullWhite marginBottom24 textAlignStart"
            variant="body2"
          >
            נתקלתם בבעיה? שלחו אלינו מייל:{" "}
            <Link href={"mailto:office@inql.co.il"}>office@inql.co.il</Link>
          </Typography>
          <Typography
            className="dullWhite marginBottom24 textAlignStart"
            variant="body2"
          >
            תאריך עידכון הצהרת נגישות: 08/02/2021
          </Typography>
        </div>
        {this.renderControlPanel()}
        <Footer {...this.props} />
      </React.Fragment>
    );
  };
}
