import React, { Component } from "react";
import SendButton from "../sendButton/sendButton";
import ArenaInputField from "../arenaInputField/arenaInput";

export default class SubComment extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div style={{ marginTop: "16px" }}>
          <ArenaInputField
            multiline={true}
            rows={4}
            value={this.props.value}
            onChange={this.props.onChange}
          />
          <div style={{ marginTop: "10px" }}>
            <SendButton size={"small"} onClick={this.props.onClick} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
