import React from "react";
import CommonStructure from "../../common/mainStructure/main";
import Typography from "@material-ui/core/Typography";
import MyComment from "../myComment/myComment";
import ControlPanel from "../controlPanel/controlPanel";
import { isBrowser, isMobile } from "react-device-detect";
import Auth from "../../common/auth";
import Avatar from "@material-ui/core/Avatar";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "@material-ui/core/CircularProgress";
const MESSAGE_DATA = [
  {
    message: "Brunch this afternoon",
    name: "Tamar kumar",
    date: "20/5/2020",
  },
  {
    message: "Brunch this weekend ?",
    name: "Pamar kumar",
    date: "20/5/2020",
  },
  {
    message: "Brunch this weekend and party",
    name: "Vamar kumar",
    date: "20/5/2020",
  },
  {
    message: "Brunch this weekend at noon",
    name: "Amar kumar",
    date: "20/5/2020",
  },
];

export default class LandingPage extends CommonStructure {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: null,
      loading: true,
      userComments: [],
      userCommentsCount: null,
    };
    this.backgroundImg = "/assets/img/header.png";
  }

  componentDidMount = async () => {
    let userDetails = this.fetchUserAndSaveDetails();
    this.fetchAccountDetails();
    await this.fetchUserComments(userDetails && userDetails.id);
    this.setState({
      loading: false,
      userDetails: userDetails,
    });
  };

  fetchAccountDetails = () => {
    let accountDetails = Auth.getAccountDetails();
    this.backgroundImg =
      accountDetails?.cover?.original || "/assets/img/header.png";
    this.setState({
      accountDetails,
    });
  };

  fetchUserAndSaveDetails = () => {
    let userDetails = Auth.getUserDetails();
    this.setState({
      userDetails: userDetails,
    });
    return userDetails;
  };

  fetchUserComments = async (userId) => {
    try {
      let offset = this.state.userComments.length;
      let response = await window.Arena.database.fetchUserComments(
        userId,
        offset
      );
      let commentsCount = response?.data?.count;
      let commentData = response?.data?.items || [];
      commentData = this.state.userComments.concat(commentData);

      this.setState({
        userComments: commentData,
        userCommentsCount: commentsCount,
      });
    } catch (e) {
      console.error(e);
    }
  };

  renderEmptyPlaceholderComments() {
    return (
      <div className="marginTop-16 textAlignCenter">
        <div style={{ color: "#65D2FC", fontSize: "24px" }}>
          {window.t("noCommentsToShow")}
        </div>
      </div>
    );
  }

  renderMyCommentsList = () => {
    if (!this.state.userComments || this.state.userComments.length < 1) {
      return this.renderEmptyPlaceholderComments();
    }
    return (
      <InfiniteScroll
        dataLength={this.state.userComments.length} //This is important field to render the next data
        next={() => this.fetchUserComments(this.state.userDetails.id)}
        hasMore={this.state.userCommentsCount > this.state.userComments.length}
        loader={
          <div className="textAlignCenter">
            <Spinner size="22px" style={{ color: "#65D2FC" }} />
          </div>
        }
        endMessage={
          <Typography
            variant="subtitle1"
            className="bold blueColor textAlignCenter"
            style={{ marginTop: "16px" }}
          >
            {window.t("noMoreCommentsToShow")}
          </Typography>
        }
      >
        {this.state.userComments &&
          this.state.userComments.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <MyComment data={item} history={this.props.history} />
                <div style={{ marginBottom: "16px" }}></div>
              </React.Fragment>
            );
          })}
      </InfiniteScroll>
    );
  };

  renderControlPanel = () => {
    if (isMobile) {
      return (
        <React.Fragment>
          <ControlPanel
            history={this.props.history}
            data={MESSAGE_DATA}
            placement="top"
          />
        </React.Fragment>
      );
    }
    if (isBrowser) {
      return (
        <React.Fragment>
          <ControlPanel
            history={this.props.history}
            data={MESSAGE_DATA}
            placement="left"
          />
        </React.Fragment>
      );
    }
  };

  renderTopContent = () => {
    return (
      <div className="myCommentPageTopContent">
        {this.state.accountDetails?.icon?.sizes["240x240"] ? (
          <img
            src={this.state.accountDetails?.icon?.sizes["240x240"]}
            alt="arenaLogo"
            className="logo commentLogo"
          />
        ) : (
          <Avatar
            alt={this.state.accountDetails.name}
            src={
              this.state.accountDetails?.icon || "/public/assets/img/client.png"
            }
            className={`categoryListItemAvatar`}
          />
        )}

        {this.renderBackButton()}
      </div>
    );
  };

  renderBottomContent = () => {
    return (
      <React.Fragment>
        <div className="myCommentsPageMainContainer">
          <div id="myCommentsPageRedHeading">
            <Typography
              tabIndex={0}
              aria-label={window.t("myComments")}
              variant="h4"
              className="redColor bold"
            >
              {window.t("myComments")}
            </Typography>
          </div>
          <div id="myCommentsHeadingInfo">
            <Typography
              tabIndex={0}
              aria-label={window.t("commentSubjectJump")}
              variant="body1"
              className="dullWhite textAlignCenter"
            >
              {window.t("commentSubjectJump")}
            </Typography>
          </div>

          <div id="commentListContainer">{this.renderMyCommentsList()}</div>
        </div>
        {this.renderControlPanel()}
      </React.Fragment>
    );
  };
}
