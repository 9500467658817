import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";

export default class AllocationLegend extends Component {
  render() {
    return (
      <React.Fragment>
        <div
          className="allocationLegendContainer reverse"
          style={{ background: `${this.props.backgroundColor}` }}
        >
          <Typography variant="body1" className="dullWhite">
            {this.props.text}
          </Typography>
          <div
            className="allocationLegendBox"
            style={{ background: `${this.props.color}` }}
          ></div>
        </div>
      </React.Fragment>
    );
  }
}
