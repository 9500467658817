const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL;
const WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL;
const STORAGE_KEYS = {
  TOKEN: "token",
  USER_TOKEN: "userToken",
  USER_TYPE: "user",
  USER_DETAILS: "userDetails",
  MESSAGE_DATA: "messageData",
  ACCOUNT_DETAILS: "accountDetails",
  IS_ADMIN: "isAdmin",
};
const FORM_TYPE_MAP = {
  discussion: "FORUM",
  allocation: "ALLOCATION",
  choice: "CHOICE",
};
const SUBJECT_STATUS = {
  FINISHED: "FINISHED",
  PUBLISHED: "PUBLISHED",
  DRAFT: "DRAFT",
};
const USER_ROLE = {
  USER: "USER",
  SYSTEM_ADMIN: "SYSTEM_ADMIN",
  GUEST: "GUEST",
};

const SOCIAL_SHARE_ICONS = {
  FACEBOOK: "facebook",
  LINKEDIN: "linkedin",
  WHATSAPP: "whatsapp",
  TWITTER: "twitter",
};

module.exports = {
  ADMIN_API_URL,
  STORAGE_KEYS,
  FORM_TYPE_MAP,
  SUBJECT_STATUS,
  USER_ROLE,
  WEBSITE_URL,
  SOCIAL_SHARE_ICONS,
};
