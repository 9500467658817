import React, { Component } from "react";
import Slider from "@material-ui/core/Slider";

export default class ArenaSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderValue: 10,
    };
  }

  handleChange = (event, newValue) => {
    this.setState({
      sliderValue: newValue,
    });
    this.props.onChange && this.props.onChange(newValue);
  };

  render() {
    return (
      <React.Fragment>
        <Slider
          className="arenaSlider"
          style={{ color: `${this.props.color}` }}
          aria-label={`${this.props.name} slider`}
          min={this.props.min}
          max={this.props.max}
          step={this.props.step || 1}
          value={this.props.value}
          onChange={this.handleChange}
          valueLabelDisplay="auto"
          aria-valuetext={`${window.t("currentValue")} ${
            this.props.value
          }. ${window.t("minValueIs")} ${this.props.min} ${window.t(
            "and"
          )} ${window.t("maxValueIs")} ${this.props.max}`}
        />
      </React.Fragment>
    );
  }
}
