import React from "react";
import CommonStructure from "../../common/mainStructure/main";
import ClientNameLarge from "../commentClientNamelarge/commentClientNameLarge";
import IconAndText from "../categoryMsgAndShare/shareAndMsg";
import Typography from "@material-ui/core/Typography";
import HeadWithImg from "../headingWithImage/headingWithImage";
import Comment from "../comment/comment";
import CommentFilter from "../commentFilter/commentFilter";
import Divider from "@material-ui/core/Divider";
import ReadMore from "../readMoreComponent/readMoreComponent";
import ControlPanel from "../controlPanel/controlPanel";
import ArenaTextField from "../arenaInputField/arenaInput";
import SendButton from "../sendButton/sendButton";
import UserPill from "../userAndIcon/userPill";
import SuccessLogo from "../success/success";
import SubComment from "../subComment/subComment";
import { isBrowser, isMobile, isMobileOnly } from "react-device-detect";
import Auth from "../../common/auth";
import { USER_ROLE, WEBSITE_URL } from "../../../constants";
import ArenaModal from "../arenaModal/arenaModal";
import FileSaver from "file-saver";
import ArenaPopover from "../arenaPopover/arenaPopover";
import Link from "@material-ui/core/Link";
import { ReactComponent as AttchedFile } from "../../../icons/AttchedFile.svg";
import { ReactComponent as Message } from "../../../icons/Message.svg";
import { ReactComponent as Share } from "../../../icons/Share.svg";
import { ReactComponent as Sent } from "../../../icons/Sent.svg";
import renderHTML from "react-render-html";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "@material-ui/core/CircularProgress";

export default class DiscussSubject extends CommonStructure {
  constructor(props) {
    super(props);
    this.state = {
      showSuccess: false,
      subjectId: null,
      subjectData: null,
      subjectComments: [],
      textValue: null,
      accountDetails: null,
      backgroundSticky: true,
      questionSticky: false,
      underlineDate: false,
      underlinePopularity: false,
      showCommentDeleteConfirmModal: false,
      deleteCommentName: null,
      deleteCommentId: null,
      deleteCommentIndex: null,
      isSubComment: false,
      commentsCount: null,
    };
  }

  componentDidMount = async () => {
    this.setState({
      loading: true,
    });
    this.fetchAndSaveUserDetails();
    let querySearchParams = this.props.history.location.search;
    let params = new URLSearchParams(querySearchParams);
    let accountDetails = Auth.getAccountDetails();
    let subjectId = params.get("subjectId");
    if (!subjectId) {
      this.props.history.replace(`/404`);
    } else {
      this.setState({
        subjectId: subjectId,
      });
      try {
        let subjectData = await window.Arena.database.fetchSubjectWithId(
          subjectId
        );
        if (subjectData?.data?.canAdminister) {
          window.canAdministerSubject = true;
        } else {
          window.canAdministerSubject = false;
        }
        await this.fetchSubjectComments(subjectId);
        let image =
          subjectData &&
          subjectData.data &&
          subjectData.data.cover &&
          subjectData.data.cover.original;
        this.backgroundImg = image || "/assets/img/header.png";
        this.setState({
          subjectData: subjectData.data,
          accountDetails,
          loading: false,
        });
      } catch (e) {
        console.error(e);
        window.NotificationUtils.showError(window.t("somethingWentWrong"));
        this.props.history.replace(`/404`);
      }
    }
  };

  fetchSubjectComments = async (subjectId) => {
    try {
      let offset = this.state.subjectComments.length;
      let subjectCommentsData = await window.Arena.database.fetchSubjectComments(
        subjectId,
        offset
      );
      let commentsCount = subjectCommentsData.data.count;
      let commentData =
        (subjectCommentsData &&
          subjectCommentsData.data &&
          subjectCommentsData.data.items) ||
        [];
      commentData = this.state.subjectComments.concat(commentData);
      this.setState({
        commentsCount: commentsCount,
        subjectComments: commentData,
      });
    } catch (e) {
      throw e;
    }
  };

  onChangeText = (e) => {
    let textValue = e.target.value;
    this.setState({
      textValue: textValue,
    });
  };

  showSuccessTimeout = () => {
    setTimeout(() => {
      this.setState({
        showSuccess: false,
      });
    }, 2000);
  };

  increaseCounterLocally = () => {
    let stateSubjectData = Object.assign({}, this.state.subjectData);

    if (this.state.subjectData && this.state.subjectData.activityCount) {
      stateSubjectData.activityCount = stateSubjectData.activityCount + 1;
    } else {
      stateSubjectData.activityCount = 1;
    }

    this.setState({
      subjectData: stateSubjectData,
    });
  };

  getRecaptchaToken = async () => {
    const token = await this.props.googleReCaptchaProps.executeRecaptcha(
      "login"
    );
    return token;
  };

  onClickSendButton = async () => {
    let stateTextValue = this.state.textValue;
    let data = {
      // replyTo: "",
      text: stateTextValue,
      // subject: this.state.subjectData && this.state.subjectData.id
    };
    try {
      let token = await this.getRecaptchaToken();
      if (!token) {
        throw new Error();
      }
      data.recaptchaToken = token;
      let response = await window.Arena.database.commentOnSubject(
        data,
        this.state.subjectData && this.state.subjectData.id
      );
      this.addCommentLocally(response.data);
      this.increaseCounterLocally();
      this.setState(
        {
          showSuccess: true,
        },
        this.showSuccessTimeout
      );
    } catch (e) {
      window.NotificationUtils.showError(
        window.t("somethingWentWrongWhilePostingComment")
      );
    }
  };

  addCommentLocally = (comment) => {
    let commentCopy = Object.assign([], this.state.subjectComments);
    if (window.role === USER_ROLE.SYSTEM_ADMIN) {
      let user = {
        isAdmin: true,
        name: this.state.userDetails.name,
      };
      comment.user = user;
    }
    commentCopy.unshift(comment);
    this.setState({
      subjectComments: commentCopy,
    });
  };

  addReplyCommentLocally = (replyComment, index, replyCount) => {
    let commentCopy = Object.assign([], this.state.subjectComments);
    if (window.role === USER_ROLE.SYSTEM_ADMIN) {
      let user = {
        isAdmin: true,
        name: this.state.userDetails.name,
      };
      replyComment.user = user;
    }
    if (commentCopy[index].replies) {
      commentCopy[index].replies.unshift(replyComment);
    } else {
      let replies = [];
      replies.unshift(replyComment);
      commentCopy[index].replies = replies;
    }
    commentCopy[index].repliesCount = replyCount + 1;
    this.increaseCounterLocally();
    this.setState({
      subjectComments: commentCopy,
    });
  };

  fetchAndSaveUserDetails = () => {
    let userDetails = Auth.getUserDetails();
    this.setState({
      userDetails: userDetails,
    });
    return userDetails;
  };

  removeCommentLocally = (index) => {
    let commentCopy = Object.assign([], this.state.subjectComments);
    commentCopy.splice(index, 1);
    this.setState({
      subjectComments: commentCopy,
    });
  };

  removeReplyCommentLocally = (index) => {
    let commentCopy = Object.assign([], this.state.subjectComments);
    let replies = commentCopy[this.state.parentIndex].replies;
    replies.splice(index, 1);
    commentCopy[this.state.parentIndex].replies = replies;
    commentCopy[this.state.parentIndex].repliesCount =
      commentCopy[this.state.parentIndex].repliesCount - 1;

    this.setState({
      subjectComments: commentCopy,
    });
  };

  decreaseCounterLocally = (index) => {
    let stateSubjectData = Object.assign({}, this.state.subjectData);
    let commentCopy = Object.assign([], this.state.subjectComments);
    let count = 1;

    if (commentCopy && commentCopy[index].replies) {
      count = count + commentCopy[index].replies.length;
    }
    if (this.state.subjectData && this.state.subjectData.activityCount) {
      stateSubjectData.activityCount = stateSubjectData.activityCount - count;
    }

    this.setState({
      subjectData: stateSubjectData,
    });
  };

  decreaseReplyCounterLocally = () => {
    let stateSubjectData = Object.assign({}, this.state.subjectData);
    if (stateSubjectData?.activityCount) {
      stateSubjectData.activityCount = stateSubjectData.activityCount - 1;
    }

    this.setState({
      subjectData: stateSubjectData,
    });
  };

  onClickDelete = async (singleCommentId, index) => {
    window.NotificationUtils.showInfo(window.t("initiatingCommentDeletion"));
    let commentId = singleCommentId;
    if (!commentId) {
      window.NotificationUtils.showError(window.t("somethingWentWrong"));
      return;
    }
    try {
      await window.Arena.database.deleteComment(commentId);
      this.state.isSubComment
        ? this.decreaseReplyCounterLocally()
        : this.decreaseCounterLocally(index);
      this.state.isSubComment
        ? this.removeReplyCommentLocally(index)
        : this.removeCommentLocally(index);
      window.NotificationUtils.showSuccess(
        window.t("commentDeletedSuccessfully")
      );
      this.setState({
        showCommentDeleteConfirmModal: false,
        deleteCommentName: null,
        deleteCommentId: null,
        deleteCommentIndex: null,
        isSubComment: false,
        parentIndex: false,
      });
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError(window.t("somethingWentWrong"));
    }
  };

  onClickLike = async (
    singleCommentId,
    index,
    likeCount,
    subComment,
    parentIndex
  ) => {
    window.NotificationUtils.showInfo(window.t("processingYourRequest"));
    let data = {
      liked: true,
    };
    let commentId = singleCommentId;
    if (!commentId) {
      window.NotificationUtils.showError(window.t("somethingWentWrong"));
      return;
    }
    try {
      await window.Arena.database.likeComment(commentId, data);
      subComment
        ? this.addReplyCommentLikeLocally(index, parentIndex, likeCount, true)
        : this.addCommentLikeLocally(index, likeCount, true);
      window.NotificationUtils.showSuccess(window.t("likedComment"));
    } catch (e) {
      window.NotificationUtils.showError(window.t("somethingWentWrong"));
    }
  };

  onClickUnLike = async (
    singleCommentId,
    index,
    likeCount,
    subComment,
    parentIndex
  ) => {
    window.NotificationUtils.showInfo(window.t("processingYourRequest"));
    let data = {
      liked: false,
    };
    let commentId = singleCommentId;
    if (!commentId) {
      window.NotificationUtils.showError(window.t("somethingWentWrong"));
      return;
    }
    try {
      await window.Arena.database.likeComment(commentId, data);
      subComment
        ? this.addReplyCommentLikeLocally(index, parentIndex, likeCount, false)
        : this.addCommentLikeLocally(index, likeCount, false);
      window.NotificationUtils.showSuccess(window.t("unLikedComment"));
    } catch (e) {
      window.NotificationUtils.showError(window.t("somethingWentWrong"));
    }
  };

  addCommentLikeLocally = (index, likesCount, liked) => {
    let commentCopy = Object.assign([], this.state.subjectComments);
    if (liked) {
      commentCopy[index].likesCount = likesCount + 1;
    } else {
      commentCopy[index].likesCount = likesCount - 1;
    }
    this.setState({
      subjectComments: commentCopy,
    });
  };

  addReplyCommentLikeLocally = (index, parentIndex, likesCount, liked) => {
    let commentCopy = Object.assign([], this.state.subjectComments);
    if (liked) {
      commentCopy[parentIndex].replies[index].likesCount = likesCount + 1;
    } else {
      commentCopy[parentIndex].replies[index].likesCount = likesCount - 1;
    }
    this.setState({
      subjectComments: commentCopy,
    });
  };

  closeDeleteConfirmModal = () => {
    this.setState({
      showCommentDeleteConfirmModal: false,
      deleteCommentName: null,
      deleteCommentId: null,
      deleteCommentIndex: null,
    });
  };

  renderDeleteConfirmModal = () => {
    return (
      <ArenaModal
        text={`${window.t("commentDeleteConfirm")} ${
          this.state.deleteCommentName
        } ?`}
        openArenaModal={this.state.showCommentDeleteConfirmModal}
        handleArenaModalClose={this.closeDeleteConfirmModal}
        primaryButtonText={window.t("approve")}
        secondaryButtonText={window.t("cancel")}
        onClickSecondaryButton={this.closeDeleteConfirmModal}
        onClickPrimaryButton={() =>
          this.onClickDelete(
            this.state.deleteCommentId,
            this.state.deleteCommentIndex
          )
        }
        headerTitle={window.t("commentDelete")}
      />
    );
  };

  showDeleteModal = (
    name,
    commentId,
    index,
    subComment = false,
    parentIndex = false
  ) => {
    this.setState({
      showCommentDeleteConfirmModal: true,
      deleteCommentName: name,
      deleteCommentId: commentId,
      deleteCommentIndex: index,
      isSubComment: subComment,
      parentIndex: parentIndex,
    });
  };

  renderComments = () => {
    let subjectExperts = this.state.subjectData?.experts;
    return (
      <InfiniteScroll
        dataLength={this.state.subjectComments.length} //This is important field to render the next data
        next={() => this.fetchSubjectComments(this.state.subjectId)}
        hasMore={this.state.commentsCount > this.state.subjectComments.length}
        loader={
          <div className="textAlignCenter">
            <Spinner
              style={{ marginBottom: "24px" }}
              size="22px"
              style={{ color: "#65D2FC" }}
            />
          </div>
        }
        endMessage={
          <Typography
            variant="subtitle1"
            className="bold blueColor textAlignCenter"
            style={{ marginBottom: "24px" }}
          >
            {window.t("noMoreCommentsToShow")}
          </Typography>
        }
      >
        {this.state.subjectComments &&
          this.state.subjectComments.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <Comment
                  data={item}
                  onClickLike={this.onClickLike}
                  onClickDelete={this.showDeleteModal}
                  index={index}
                  addReplyCommentLocally={this.addReplyCommentLocally}
                  showSendButton={true}
                  onClickUnLike={this.onClickUnLike}
                  {...this.props}
                  subjectExperts={subjectExperts}
                />
                {item.replies && this.renderReplyComments(item.replies, index)}
                <Divider
                  aria-hidden="true"
                  className="commentListDivider"
                  style={{
                    backgroundColor: "white",
                    width: "100%",
                    height: "1px",
                  }}
                />
              </React.Fragment>
            );
          })}
      </InfiniteScroll>
    );
  };

  renderEmptyComments = () => {
    return (
      <React.Fragment>
        <Typography
          variant="h6"
          className="bold blueColor textAlignCenter"
          style={{ marginTop: "16px" }}
        >
          {window.t("firstToComment")}
        </Typography>
      </React.Fragment>
    );
  };

  renderReplyComments = (subComments, parentIndex) => {
    return (
      subComments &&
      subComments.map((item, index) => {
        return (
          <React.Fragment>
            <SubComment
              data={item}
              index={index}
              onClickLike={this.onClickLike}
              parentCommentIndex={parentIndex}
              onClickUnLike={this.onClickUnLike}
              onClickDelete={this.showDeleteModal}
              {...this.props}
            />
          </React.Fragment>
        );
      })
    );
  };

  renderControlPanel = () => {
    if (isMobile) {
      return (
        <React.Fragment>
          <ControlPanel
            openEditMode={this.openEditModeSubject}
            history={this.props.history}
            placement="top"
            subjectText={window.t("editSubject")}
            t={this.props.t}
            onClickDownloadReport={this.onClickDownloadReport}
            subjectId={this.state.subjectId}
            showSearch={true}
            categoryId={this.state.subjectData?.category?.id}
          />
        </React.Fragment>
      );
    }
    if (isBrowser) {
      return (
        <React.Fragment>
          <ControlPanel
            openEditMode={this.openEditModeSubject}
            history={this.props.history}
            placement="left"
            subjectText={window.t("editSubject")}
            t={this.props.t}
            onClickDownloadReport={this.onClickDownloadReport}
            subjectId={this.state.subjectId}
            showSearch={true}
            categoryId={this.state.subjectData?.category?.id}
          />
        </React.Fragment>
      );
    }
  };

  openEditModeSubject = () => {
    if (!Auth.getAdminFlag() && !window.canAdministerSubject) {
      window.NotificationUtils.showInfo(window.t("editSubjectNotAllowed"));
      return;
    }
    let subjectId = this.state.subjectData && this.state.subjectData.id;
    this.props.history.push(`edit-subject?subjectId=${subjectId}`);
  };

  onClickShareButton = () => {
    let url = `${WEBSITE_URL}${this.state.accountDetails.slug}/discuss-subject?subjectId=${this.state.subjectData.id}`;
    window.ShareModal.showShareModal(url);
  };

  sendMessage = () => {
    let subjectId = this.state.subjectId && this.state.subjectId;
    this.props.history.push(`send-message?subjectId=${subjectId}`);
  };

  sortCommentsPopularity = () => {
    let commentsCopy = Object.assign([], this.state.subjectComments);
    if (!commentsCopy.length) {
      window.NotificationUtils.showInfo(window.t("noCommentSort"));
      return;
    }
    commentsCopy.sort((a, b) => {
      if (a.likesCount <= b.likesCount) return 1;
      if (a.likesCount >= b.likesCount) return -1;
      return 0;
    });
    this.setState({
      subjectComments: commentsCopy,
      underlinePopularity: true,
      underlineDate: false,
    });
    window.NotificationUtils.showInfo(window.t("commentSortedByPopularity"));
  };

  sortCommentsDate = () => {
    let commentsCopy = Object.assign([], this.state.subjectComments);
    if (!commentsCopy.length) {
      window.NotificationUtils.showInfo(window.t("noCommentSort"));
      return;
    }
    commentsCopy.sort((a, b) => {
      if (a.createdAt <= b.createdAt) return 1;
      if (a.createdAt >= b.createdAt) return -1;
      return 0;
    });
    this.setState({
      subjectComments: commentsCopy,
      underlineDate: true,
      underlinePopularity: false,
    });
    window.NotificationUtils.showInfo(window.t("commentSortedByDate"));
  };

  onClickAvatar = () => {
    let accountDetails = Auth.getAccountDetails();
    this.goBack(
      `/${accountDetails.slug}/category-discussion?categoryId=${this.state.subjectData.category.id}`
    );
  };

  getCategoryImageUrl = () => {
    let categoryData =
      this.state.subjectData && this.state.subjectData.category;
    if (categoryData && categoryData.icon && categoryData.icon.sizes) {
      return categoryData.icon.sizes["240x240"];
    }
    return "";
  };

  renderPopoverContent = () => {
    let attachmentFiles =
      this.state.subjectData && this.state.subjectData.files;
    if (attachmentFiles && attachmentFiles.length < 1) {
      return (
        <Typography className="whiteColor">
          {window.t("noAttachedFiles")}
        </Typography>
      );
    }
    return (
      attachmentFiles &&
      attachmentFiles.map((file) => {
        return (
          <Link
            className="displayFlex marginBottom8 blueColor justContentFlexEnd"
            href={file.url}
            target="_blank"
          >
            {file.name}
          </Link>
        );
      })
    );
  };

  onClickAttachmentsButton = () => {
    this.setState({
      showAttachmentPopover: true,
    });
  };

  onClickIconButton = () => {
    let showPopover = false;
    if (this.state.showAttachmentPopover) {
      showPopover = false;
    } else {
      showPopover = true;
    }
    this.setState({
      showAttachmentPopover: showPopover,
    });
  };

  renderTopContent = () => {
    let attachmentFiles =
      this.state.subjectData && this.state.subjectData.files;
    let role = Auth.getUserRole();
    return (
      <React.Fragment>
        <div className="discussSubjectTopMainContainer">
          <div className="discussSubjectPageTopContent">
            <ClientNameLarge
              imgURL={this.getCategoryImageUrl()}
              name={this.state.subjectData && this.state.subjectData.name}
              isLarge={true}
              onClickAvatar={this.onClickAvatar}
              ariaLabel={window.t("backToCategory")}
            />
          </div>
          <div id="headerInfoContainer" className="reverse">
            <div className="discussSubjecHeaderInfo reverse">
              {attachmentFiles && attachmentFiles.length > 0 && (
                <IconAndText
                  id={"attachments"}
                  text={window.t("attachments")}
                  value={AttchedFile}
                  onClick={this.onClickAttachmentsButton}
                />
              )}
              {role === USER_ROLE.USER && (
                <IconAndText
                  text={window.t("message")}
                  value={Message}
                  onClick={this.sendMessage}
                />
              )}
              <IconAndText
                text={window.t("comments")}
                istext={true}
                value={
                  (this.state.subjectData &&
                    this.state.subjectData.activityCount) ||
                  0
                }
              />
              <IconAndText
                text={window.t("share")}
                value={Share}
                onClick={this.onClickShareButton}
              />
            </div>
          </div>
          {attachmentFiles && attachmentFiles.length > 0 && (
            <ArenaPopover
              className="arenaPopover attachmentPopover"
              toggle={this.onClickIconButton}
              history={this.props.history}
              placement={"bottom"}
              isOpen={this.state.showAttachmentPopover}
              target={"#attachments"}
            >
              <div>{this.renderPopoverContent()}</div>
            </ArenaPopover>
          )}
        </div>
      </React.Fragment>
    );
  };

  closeDownloadConfirmModal = () => {
    this.setState({
      showDownloadConfirmModal: false,
    });
  };
  renderDownloadConfirmModal = () => {
    return (
      <ArenaModal
        text={`${window.t("downloadReportText")} ${
          this.state.subjectData.name
        } ?`}
        openArenaModal={this.state.showDownloadConfirmModal}
        handleArenaModalClose={this.closeDownloadConfirmModal}
        secondaryButtonText={window.t("cancel")}
        onClickSecondaryButton={this.closeDownloadConfirmModal}
        onClickPrimaryButton={this.getDownloadReport}
        headerTitle={window.t("downloadReport")}
      />
    );
  };

  getDownloadReport = async () => {
    let result = await window.Arena.database.downloadDiscussSubjectReport(
      this.state.subjectId
    );
    FileSaver.saveAs(result.data, `report-${this.state.subjectId}.xlsx`);
    this.setState({
      showDownloadConfirmModal: false,
    });
  };

  onClickDownloadReport = () => {
    this.setState({
      showDownloadConfirmModal: true,
    });
  };

  renderBottomContent = () => {
    let date = moment.utc(new Date());

    let startDate = moment.utc(
      this.state.subjectData && this.state.subjectData.startDate
    );
    if (startDate > date) {
      return this.renderEmptyPlaceholder();
    }
    return (
      <React.Fragment>
        <div className="discussSubjectMainContainer">
          <div
            className={`positionSticky discussSubjectHeadingContainer`}
            id="discussSubjectHeadingImgContainer"
          >
            <div
              className="marginBottom12 reverse"
              id="conclusionPageHeaderHeading"
            >
              <Typography
                role="heading"
                aria-level="2"
                variant="h6"
                className="redColor bold textAlignEnd reverse conclusionTitleExtra"
              >
                {window.t("subjectKind")}
              </Typography>
            </div>
            <HeadWithImg
              imgURL="/assets/img/question.png"
              text={this.state.subjectData && this.state.subjectData.question}
            />
          </div>
          {isMobileOnly ? (
            <>
              <div>
                <div style={{ marginBottom: "16px" }}>
                  <ReadMore
                    t={this.props.t}
                    text={
                      this.state.subjectData?.description &&
                      renderHTML(this.state.subjectData.description)
                    }
                    textData={
                      this.state.subjectData &&
                      this.state.subjectData.description
                    }
                  />
                </div>

                <div id="discussSubjectKindMobile">
                  <div>
                    <div className="conclusionPageHeaderHeading">
                      <Typography
                        variant="h6"
                        className="redColor bold textAlignEnd reverse"
                      >
                        {window.t("subjectKind")}
                      </Typography>
                    </div>
                    <HeadWithImg
                      imgURL="/assets/img/question.png"
                      text={
                        this.state.subjectData &&
                        this.state.subjectData.question
                      }
                    />
                  </div>
                  <div style={{ margin: "24px 0" }}>
                    {!this.state.showSuccess ? (
                      <ArenaTextField
                        id="discussSubjectTextField"
                        multiline={true}
                        rows={6}
                        placeholder={window.t("writeComment")}
                        onChange={(e) => this.onChangeText(e)}
                        ariaLabelText={window.t("writeComment")}
                      />
                    ) : (
                      <SuccessLogo
                        text={window.t("commentSentSuccessfully")}
                        icon={Sent}
                      />
                    )}
                  </div>
                  <div>
                    {!this.state.showSuccess ? (
                      <SendButton
                        extraClass="commentMainButton"
                        text={window.t("addToComment")}
                        onClick={this.onClickSendButton}
                        size={"small"}
                      />
                    ) : null}
                  </div>
                </div>

                <div className="conclusionPageCommentContainer commentConclusionFlexContainer zIndex1Mobile fullWidthMobile">
                  <Typography
                    role="heading"
                    aria-level="2"
                    variant="h6"
                    className="redColor bold textAlignEnd reverse discussTitleExtra zIndex0"
                    tabIndex={0}
                    aria-labelledBy={window.t("titleComment")}
                  >
                    {window.t("titleComment")}
                  </Typography>
                  <div
                    id="filterContainer"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      margin: "16px 0",
                    }}
                    className="reverse zIndex0"
                  >
                    <CommentFilter
                      onClickPopularity={this.sortCommentsPopularity}
                      onclickDate={this.sortCommentsDate}
                      underlineDate={this.state.underlineDate}
                      underlinePopularity={this.state.underlinePopularity}
                    />
                  </div>
                  <div className="discussSubjectCommentsListContainer">
                    {this.state.subjectComments &&
                    this.state.subjectComments.length
                      ? this.renderComments()
                      : this.renderEmptyComments()}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className="insideSticky discussSubjectCommentsContainer reverse"
                id="flexRespColRev"
              >
                <div className="conclusionPageCommentContainer commentConclusionFlexContainer zIndex1Mobile fullWidthMobile">
                  <Typography
                    role="heading"
                    aria-level="2"
                    variant="h6"
                    className="redColor bold textAlignEnd reverse discussTitleExtra zIndex0"
                    tabIndex={0}
                    aria-labelledBy={window.t("titleComment")}
                  >
                    {window.t("titleComment")}
                  </Typography>
                  <div
                    id="filterContainer"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      margin: "16px 0",
                    }}
                    className="reverse zIndex0"
                  >
                    <CommentFilter
                      onClickPopularity={this.sortCommentsPopularity}
                      onclickDate={this.sortCommentsDate}
                      underlineDate={this.state.underlineDate}
                      underlinePopularity={this.state.underlinePopularity}
                    />
                  </div>
                  <div className="discussSubjectCommentsListContainer">
                    {this.state.subjectComments &&
                    this.state.subjectComments.length
                      ? this.renderComments()
                      : this.renderEmptyComments()}
                  </div>
                </div>
                <div className="discussPageReadMoreContainer commentConclusionFlexContainer">
                  <div style={{ marginBottom: "16px" }}>
                    <ReadMore
                      t={this.props.t}
                      text={
                        this.state.subjectData &&
                        this.state.subjectData.description &&
                        renderHTML(this.state.subjectData.description)
                      }
                      textData={
                        this.state.subjectData &&
                        this.state.subjectData.description
                      }
                    />
                  </div>
                  <div id="discussSubjectKindMobile">
                    <div className="conclusionPageHeaderHeading">
                      <Typography
                        variant="h6"
                        className="redColor bold textAlignEnd reverse"
                      >
                        {window.t("subjectKind")}
                      </Typography>
                    </div>
                    <HeadWithImg
                      imgURL="/assets/img/question.png"
                      text={
                        this.state.subjectData &&
                        this.state.subjectData.question
                      }
                    />
                  </div>

                  <div
                    id="discussSubjectUserpillContainer"
                    style={{ marginBottom: "26px" }}
                  >
                    <UserPill
                      user={
                        (this.state.userDetails &&
                          this.state.userDetails.name) ||
                        window.t("guest")
                      }
                      iconColor={
                        this.state.subjectData &&
                        this.state.subjectData.canAdminister
                          ? "#FEA030"
                          : "#fff"
                      }
                    />
                  </div>

                  <div style={{ margin: "24px 0" }}>
                    {!this.state.showSuccess ? (
                      <ArenaTextField
                        id="discussSubjectTextField"
                        multiline={true}
                        rows={6}
                        placeholder={window.t("writeComment")}
                        onChange={(e) => this.onChangeText(e)}
                        ariaLabelText={window.t("writeComment")}
                      />
                    ) : (
                      <SuccessLogo
                        text={window.t("commentSentSuccessfully")}
                        icon={Sent}
                      />
                    )}
                  </div>

                  <div>
                    {!this.state.showSuccess ? (
                      <SendButton
                        extraClass="commentMainButton"
                        text={window.t("addToComment")}
                        onClick={this.onClickSendButton}
                        size={"small"}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        {this.renderControlPanel()}
        {this.state.showDownloadConfirmModal &&
          this.renderDownloadConfirmModal()}
        {this.state.showCommentDeleteConfirmModal &&
          this.renderDeleteConfirmModal()}
      </React.Fragment>
    );
  };
}
