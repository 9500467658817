import React, { Component } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";

export default class CommentClientNameLarge extends Component {
  render() {
    return (
      <React.Fragment>
        <ListItem
          style={{ background: `${this.props.backgroundColor}` }}
          className="commentNameLargeContainer reverse"
        >
          <Button
            id="listItemAvatar"
            onClick={this.props.onClickAvatar}
            tabIndex={0}
            aria-label={this.props.ariaLabel}
          >
            <Avatar alt={this.props.name} src={this.props.imgURL} />
          </Button>
          <ListItemText
            tabIndex="-1"
            primaryTypographyProps={{
              component: "h1",
              variant: "body1",
              tabIndex: 0,
            }}
            className="commentNameLargeText reverse"
            primary={this.props.name}
          />
        </ListItem>
      </React.Fragment>
    );
  }
}
