import React from "react";
import CommonStructure from "../../common/mainStructure/main";
import ClientNameLarge from "../commentClientNamelarge/commentClientNameLarge";
import Typography from "@material-ui/core/Typography";
import ArenaInput from "../arenaInputField/arenaInput";
import SendButton from "../sendButton/sendButton";
import ControlPanel from "../controlPanel/controlPanel";
import { isBrowser, isMobile } from "react-device-detect";
import Auth from "../../common/auth";
import { USER_ROLE, SUBJECT_STATUS } from "../../../constants";
import moment from "moment";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import Icon from "@material-ui/core/Icon";
import { FORM_TYPE_MAP } from "../editSubject/editSubject";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "@material-ui/core/CircularProgress";
const DATA = [
  {
    message: "Brunch this afternoon",
    name: "Tamar kumar",
    date: "20/5/2020",
  },
  {
    message: "Brunch this weekend ?",
    name: "Pamar kumar",
    date: "20/5/2020",
  },
  {
    message: "Brunch this weekend and party",
    name: "Vamar kumar",
    date: "20/5/2020",
  },
  {
    message: "Brunch this weekend at noon",
    name: "Amar kumar",
    date: "20/5/2020",
  },
];

export default class SendMessage extends CommonStructure {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      subjectId: "",
      allMessages: [],
      loading: true,
      subjectData: null,
      showMessagePopover: false,
      messagesCount: null,
    };
    this.backgroundImg = "/assets/img/building.png";
  }

  componentDidMount = async () => {
    let querySearchParams = this.props.history.location.search;
    let params = new URLSearchParams(querySearchParams);
    let subjectId = params.get("subjectId");
    if (subjectId) {
      this.setState({
        subjectId: subjectId,
      });
    }
    let subjectDetails = await this.fetchSubjectDetails(subjectId);
    if (!subjectDetails) {
      return;
    }
    // DO THIS this.fetchSubjectData();
    this.fetchUserAndSaveDetails();
    let message = Auth.getMessageData();
    let accountDetails = Auth.getAccountDetails();

    if (message) {
      await this.fetchAllMessages(message.id);
    }
    this.setState({
      loading: false,
      messageData: message,
      accountDetails,
    });
  };

  fetchAllMessages = async (messageId) => {
    try {
      let allMessages = [];
      let offset = this.state.allMessages.length;

      let response = await window.Arena.database.fetchAllChatsWithChatId(
        messageId,
        offset
      );
      allMessages = response.data.items;
      allMessages = this.state.allMessages.concat(allMessages);
      let messagesCount = response.data.count;

      this.setState({
        allMessages,
        messagesCount: messagesCount,
      });
    } catch (e) {
      throw e;
    }
  };

  fetchSubjectDetails = async (subjectId) => {
    try {
      let response = await window.Arena.database.fetchSubjectWithId(subjectId);
      if (response?.data?.canAdminister) {
        window.canAdministerSubject = true;
      } else {
        window.canAdministerSubject = false;
      }
      this.setState({
        subjectData: response.data,
      });
      return response.data;
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError(window.t("somethingWentWrong"));
    }
  };

  componentWillUnmount() {
    Auth.removeMessageData();
  }

  fetchUserAndSaveDetails = () => {
    let userDetails = Auth.getUserDetails();
    this.setState({
      userDetails: userDetails,
    });
    return userDetails;
  };

  getCategoryImageUrl = () => {
    let categoryData =
      this.state.subjectData && this.state.subjectData.category;
    if (categoryData && categoryData.icon && categoryData.icon.sizes) {
      return categoryData.icon.sizes["240x240"];
    }
    return "";
  };
  renderTopContent = () => {
    return (
      <div
        className="sendMessageTopContent"
        id="sendMessageContainerTopContent"
      >
        <ClientNameLarge
          name={this.state.subjectData && this.state.subjectData.name}
          isLarge={true}
          imgURL={this.getCategoryImageUrl()}
        />
      </div>
    );
  };
  renderControlPanel = () => {
    if (isMobile) {
      return (
        <React.Fragment>
          <ControlPanel
            history={this.props.history}
            data={DATA}
            placement="top"
            subjectId={this.state.subjectId}
            showMessagePopover={this.state.showMessagePopover}
          />
        </React.Fragment>
      );
    }
    if (isBrowser) {
      return (
        <React.Fragment>
          <ControlPanel
            history={this.props.history}
            data={DATA}
            placement="left"
            subjectId={this.state.subjectId}
            showMessagePopover={this.state.showMessagePopover}
          />
        </React.Fragment>
      );
    }
  };

  setInitialState = () => {
    this.setState({
      showMessagePopover: false,
    });
  };

  onClickMessageButton = () => {
    this.setState(
      {
        showMessagePopover: true,
      },
      this.setInitialState
    );
  };

  onChangeTextField = (e) => {
    this.setState({
      message: e.target.value,
    });
  };

  sendMessage = async () => {
    if (!this.state.message.length) {
      window.NotificationUtils.showError(window.t("enterMessageFirst"));
      return;
    }
    window.NotificationUtils.showInfo(window.t("sendingMessage"));
    let chatId = this.state.messageData && this.state.messageData.id;
    let data = {
      text: this.state.message,
    };
    try {
      if (this.state.messageData) {
        await window.Arena.database.sendChatMessageToChatId(data, chatId);
        window.NotificationUtils.showSuccess(
          window.t("messageSentSuccessfully")
        );
      } else {
        await window.Arena.database.sendChatMessageToSubjectId(
          data,
          this.state.subjectId
        );
        window.NotificationUtils.showSuccess(
          window.t("messageSentSuccessfully")
        );
      }
      this.addMessageLocally(data);
      this.setState({
        message: "",
      });
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError(window.t("somethingWentWrong"));
    }
  };

  getLastMessage = () => {
    let message = "";
    if (
      this.state.userDetails &&
      this.state.userDetails.role === USER_ROLE.USER
    ) {
      message = this.state.messageData && this.state.messageData.text;
      return message;
    }
    if (
      this.state.userDetails &&
      this.state.userDetails.role === USER_ROLE.SYSTEM_ADMIN
    ) {
      message =
        this.state.messageData &&
        this.state.messageData.lastMessage &&
        this.state.messageData.lastMessage.text;
      return message;
    }
  };

  renderTextContent = () => {
    if (this.state.messageData) {
      return this.state.messageData.user.name;
    }
    return window.t("subjectAdmin");
  };

  addMessageLocally = (data) => {
    let stateItems = Object.assign([], this.state.allMessages);
    let item = {
      createdAt: new Date(),
      text: data.text,
      user: {
        name: this.state.userDetails.name,
      },
    };
    stateItems.unshift(item);

    this.setState({
      allMessages: stateItems,
    });
    // this.props.history.go(0)
  };

  renderPastMessages = () => {
    return (
      <InfiniteScroll
        dataLength={this.state.allMessages.length} //This is important field to render the next data
        next={() => this.fetchAllMessages(this.state.messageData.id)}
        hasMore={this.state.messagesCount > this.state.allMessages.length}
        loader={
          <div className="textAlignCenter">
            <Spinner
              style={{ marginBottom: "24px" }}
              size="22px"
              style={{ color: "#65D2FC" }}
            />
          </div>
        }
        endMessage={
          <Typography
            variant="subtitle1"
            className="bold blueColor textAlignCenter"
            style={{ marginBottom: "24px" }}
          >
            {window.t("noMoreMessagesToShow")}
          </Typography>
        }
      >
        {this.state.allMessages &&
          this.state.allMessages.length > 0 &&
          this.state.allMessages.map((item, index) => {
            let date = moment(item.createdAt).format("HH.mm MM.DD.YYYY");
            let color = "#fff";
            if (Auth.getAdminFlag()) {
              color =
                item.user.name === this.state.userDetails.name
                  ? "goldenColor"
                  : "whiteColor";
            } else {
              color =
                item.user.name === this.state.userDetails.name
                  ? "whiteColor"
                  : "goldenColor";
            }
            return (
              <div
                key={index}
                className="dullerWhite textAlignEnd marginBottom24 reverse"
              >
                <div className="displayFlexEnd reverseMessageActionButton">
                  <Typography
                    id="messageUserAndDateText"
                    variant="body1"
                    className="marginRight16"
                  >
                    {date}
                  </Typography>
                  <Typography className={color} variant="body1">
                    {item.user.name}
                  </Typography>
                </div>

                <Typography variant="body1">{item.text}</Typography>
              </div>
            );
          })}
      </InfiniteScroll>
    );
  };

  getPageNameForFinishedSubjects = () => {
    switch (this.state.subjectData && this.state.subjectData.type) {
      case FORM_TYPE_MAP.discussion:
        return "conclusion";
      case FORM_TYPE_MAP.choice:
        return "multiple-choice-conclusion";
      case FORM_TYPE_MAP.allocation:
        return "allocation-conclusion";
      default:
        return;
    }
  };

  getPageNameForActiveSubjects = () => {
    switch (this.state.subjectData && this.state.subjectData.type) {
      case FORM_TYPE_MAP.discussion:
        return "discuss-subject";
      case FORM_TYPE_MAP.choice:
        return "multi-subject";
      case FORM_TYPE_MAP.allocation:
        return "allocation";
      default:
        return;
    }
  };

  onClickSubjectButton = () => {
    let pageName = null;
    let date = moment.utc(new Date());
    let endDate = moment.utc(this.state.subjectData.endDate);
    if (
      this.state.subjectData.status === SUBJECT_STATUS.FINISHED ||
      date > endDate
    ) {
      pageName = this.getPageNameForFinishedSubjects();
    } else {
      pageName = this.getPageNameForActiveSubjects();
    }
    this.props.history.replace(
      `/${this.state.accountDetails.slug}/${pageName}?subjectId=${this.state.subjectId}`
    );
  };

  renderActionButtons = () => {
    return (
      <React.Fragment>
        <div className="displayFlexEnd marginBottom16 reverseMessageActionButton">
          <div
            className="displayFlex blueColor marginRight16 actionButtonSendMessage"
            onClick={this.onClickSubjectButton}
          >
            <Typography className="actionButtonSpacing">
              {window.t("subject")}
            </Typography>
            <Icon>
              <HelpOutlineIcon />
            </Icon>
          </div>
          <div
            className="displayFlex blueColor actionButtonSendMessage"
            onClick={this.onClickMessageButton}
          >
            <Typography className="actionButtonSpacing">
              {window.t("message")}
            </Typography>
            <Icon>
              <MailOutlineOutlinedIcon />
            </Icon>
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderBottomContent = () => {
    return (
      <React.Fragment>
        <div className="sendMessageMainContainer">
          <div id="sendMessageHeading" style={{ marginBottom: "36px" }}>
            <Typography variant="h5" className="redColor bold">
              {window.t("messageTo")} {this.renderTextContent()}
            </Typography>
          </div>
          <div>{this.renderActionButtons()}</div>
          <div id="pastMessageContainer">{this.renderPastMessages()}</div>

          <div>
            {/* <div className="sendMessageMarginBottom displayFlexEnd">
                        <Typography className="dullerWhite textAlignEnd marginRight16" variant="body1">
                            10.47 | 03/03/2020
                        </Typography>
                        <UserPill user={(this.state.userDetails && this.state.userDetails.name) || window.t("guest")}/>
                    </div>
                    {this.state.messageData && <Typography style={{marginBottom: "16px"}} variant="body1" className="whiteColor textAlignEnd">{this.getLastMessage()}</Typography>
                    } */}
            <div className="sendMessageMarginBottom">
              <ArenaInput
                rows={4}
                multiline={true}
                onChange={this.onChangeTextField}
                value={this.state.message}
                ariaLabelText={`${window.t(
                  "messageTo"
                )} ${this.renderTextContent()}`}
              />
            </div>
            <div id="sendMessageButtonContainer">
              <SendButton
                size={"small"}
                onClick={this.sendMessage}
                text={window.t("sendMessage")}
                disabled={!this.state.message.length ? true : false}
              />
            </div>
          </div>
        </div>
        {this.renderControlPanel()}
      </React.Fragment>
    );
  };
}
