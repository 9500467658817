import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
} from "react-share";
import { SOCIAL_SHARE_ICONS } from "../../constants";
class ShareModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shareModal: false,
      url: null,
    };
  }

  showModal = (url) => {
    if (!url) {
      return;
    }
    this.setState({
      shareModal: true,
      url: url,
    });
  };

  handleClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ shareModal: false });
  };

  onClickSocialIcon = async (data) => {
    let trackerData = {
      network: data,
    };

    let subjectId = new URL(this.state.url).searchParams.get("subjectId");
    if (!subjectId) {
      return;
    }
    try {
      await window.Arena.database.socialTracker(subjectId, trackerData);
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError(window.t("somethingWentWrong"));
    }
  };

  render() {
    return (
      <React.Fragment>
        <Dialog
          className="socialShareModal"
          onClose={this.handleClose}
          open={this.state.shareModal}
          aria-labelledby="shareModal"
          aria-modal="true"
        >
          <DialogTitle
            id="shareModal"
            className="redColor bold textAlignCenter"
          >
            {this.props.t("share")}
          </DialogTitle>
          <IconButton
            aria-label={`${window.t("close")} ${window.t("share")}`}
            className="changePasswordCloseIcon"
            onClick={this.handleClose}
          >
            <CloseIcon />
          </IconButton>
          <div id="shareModalButtonContainer" className="reverse">
            <FacebookShareButton
              url={this.state.url}
              autoFocus={true}
              onClick={() => {
                this.onClickSocialIcon(SOCIAL_SHARE_ICONS.FACEBOOK);
              }}
              className="shareIconContainer"
            >
              <Icon
                class="fab fa-facebook-f"
                style={{ color: "#3B5998", fontSize: "40px" }}
              ></Icon>
              <Typography variant="caption" className="dullWhite bold">
                {this.props.t("Facebook")}
              </Typography>
            </FacebookShareButton>

            <TwitterShareButton
              url={this.state.url}
              onClick={() => {
                this.onClickSocialIcon(SOCIAL_SHARE_ICONS.TWITTER);
              }}
              className="shareIconContainer"
            >
              <Icon
                class="fab fa-twitter"
                style={{ color: "#00acee", fontSize: "40px" }}
              ></Icon>
              <Typography variant="caption" className="dullWhite bold">
                {this.props.t("Twitter")}
              </Typography>
            </TwitterShareButton>

            <WhatsappShareButton
              url={this.state.url}
              onClick={() => {
                this.onClickSocialIcon(SOCIAL_SHARE_ICONS.WHATSAPP);
              }}
              className="shareIconContainer"
            >
              <Icon
                class="fab fa-whatsapp"
                style={{ color: "#25D366", fontSize: "40px" }}
              ></Icon>
              <Typography variant="caption" className="dullWhite bold">
                {this.props.t("WhatsApp")}
              </Typography>
            </WhatsappShareButton>
            <LinkedinShareButton
              url={this.state.url}
              onClick={() => {
                this.onClickSocialIcon(SOCIAL_SHARE_ICONS.LINKEDIN);
              }}
              className="shareIconContainer"
            >
              <Icon
                class="fab fa-linkedin"
                style={{ color: "#3B5998", fontSize: "40px" }}
              ></Icon>
              <Typography variant="caption" className="dullWhite bold">
                {this.props.t("LinkedIn")}
              </Typography>
            </LinkedinShareButton>
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default ShareModal;
