import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { withStyles } from "@material-ui/core/styles";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = (theme) => ({
  root: {
    width: "100%",
    // "& > * + *": {
    //   marginTop: theme.spacing(2),
    // },
    alignItems: "center",
  },
});

class SnackbarWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  showSuccess = (message, extras) => {
    this.setState({
      open: true,
      severity: "success",
      message,
      extras,
    });
  };

  showError = (message, extras) => {
    this.setState({
      open: true,
      severity: "error",
      message,
      extras,
    });
  };

  showWarning = (message, extras) => {
    this.setState({
      open: true,
      severity: "warning",
      message,
      extras,
    });
  };

  showInfo = (message, extras) => {
    this.setState({
      open: true,
      severity: "info",
      message,
      extras,
    });
  };

  handleClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    let autoHideDuration = 4000;
    if (this.state.extras && this.state.extras.duration) {
      autoHideDuration = this.state.extras.duration;
    }

    return (
      <div className={classes.root}>
        <Snackbar
          open={this.state.open}
          autoHideDuration={autoHideDuration}
          onClose={this.handleClose}
        >
          <Alert onClose={this.handleClose} severity={this.state.severity}>
            {this.state.message}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

export default withStyles(styles)(SnackbarWrapper);
