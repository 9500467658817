import React from "react";
import moment from "moment";
import CommonStructure from "../../common/mainStructure/main";
import Typography from "@material-ui/core/Typography";
import ControlPanel from "../controlPanel/controlPanel";
import SendButton from "../sendButton/sendButton";
import ArenaDropdown from "../arenaDropdown/arenaDropdown";
import ArenaInput from "../arenaInputField/arenaInput";
import RichTextField from "../arenaRichText/richTextField";
import ArenaDatePicker from "../arenaDatePicker/arenaDatePicker";
import ArenaMultiSelect from "../arenaMultiSelect/arenaMultiSelect";
import ArenaUploader from "../arenaUploader/arenaUploader";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ArenaButton from "../arenaButton/arenaButton";
import IconButton from "@material-ui/core/IconButton";
import { isBrowser, isMobile } from "react-device-detect";
import Icon from "@material-ui/core/Icon";
import { SUBJECT_STATUS } from "../../../constants";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import Auth from "../../common/auth";
import ArenaModal from "../arenaModal/arenaModal";
import Button from "@material-ui/core/Button";
import { ReactComponent as Delete } from "../../../icons/Delete.svg";
import CommentActionButton from "../commentSectionButton/commentActionButton";
import FileSaver from "file-saver";
import ChipInput from "material-ui-chip-input";

const CustomOption = ({ innerProps, data, isFocused }) => {
  //TODO: bring label from translation for aria label
  return (
    <div
      className={`dropDownIconsContainer reverse ${isFocused && "border"}`}
      {...innerProps}
      aria-label={data.label}
    >
      <Icon className={`${data.value} dropDownIcon`}></Icon>
      <span className="dullWhite">{data.label}</span>{" "}
    </div>
  );
};

const FORM_TYPE = {
  DISCUSSION: "discussion",
  ALLOCATION: "allocation",
  CHOICE: "choice",
};

export const FORM_TYPE_MAP = {
  discussion: "FORUM",
  allocation: "ALLOCATION",
  choice: "CHOICE",
};

const INITIAL_STATE = {
  account: "",
  name: "",
  description: "",
  question: "",
  category: null,
  startDate: new Date(),
  endDate: new Date(),
  tags: [],
  private: false,
  intermediateReport: false,
  status: SUBJECT_STATUS.PUBLISHED,
  type: FORM_TYPE_MAP.discussion,
  categoryDropdownOption: null,
  loading: false,
  radioValue: FORM_TYPE.DISCUSSION,
  conclusion: [
    {
      title: "",
      icon: "",
      text1: "",
      text2: "",
    },
  ],
  accountDetails: null,
  showMoreFields: false,
  groups: [],
  groupOptions: [],
};

const CONCLUSION_DATA = [
  {
    title: "",
    icon: "",
    text1: "",
    text2: "",
  },
];
const INTERMEDIATE_REPORT = "intermediateReport";

export default class EditSubject extends CommonStructure {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
    this.backgroundImg = "/assets/img/couple.png";
    this.categoryId = "";
    this.IMAGE_DROPDOWN = [
      {
        value: "fab fa-500px",
        label: window.t("fab fa-500px"),
      },
      {
        value: "fab fa-accessible-icon",
        label: window.t("fab fa-accessible-icon"),
      },
      {
        value: "fab fa-accusoft",
        label: window.t("fab fa-accusoft"),
      },
      {
        value: "fas fa-address-book",
        label: window.t("fas fa-address-book"),
      },
      {
        value: "far fa-address-book",
        label: window.t("far fa-address-book"),
      },
      {
        value: "fas fa-address-card",
        label: window.t("fas fa-address-card"),
      },
      {
        value: "far fa-address-card",
        label: window.t("far fa-address-card"),
      },
      {
        value: "fas fa-adjust",
        label: window.t("fas fa-adjust"),
      },
      {
        value: "fab fa-adn",
        label: window.t("fab fa-adn"),
      },
      {
        value: "fab fa-adversal",
        label: window.t("fab fa-adversal"),
      },
      {
        value: "fab fa-affiliatetheme",
        label: window.t("fab fa-affiliatetheme"),
      },
      {
        value: "fab fa-algolia",
        label: window.t("fab fa-algolia"),
      },
      {
        value: "fas fa-align-center",
        label: window.t("fas fa-align-center"),
      },
      {
        value: "fas fa-align-justify",
        label: window.t("fas fa-align-justify"),
      },
      {
        value: "fas fa-align-left",
        label: window.t("fas fa-align-left"),
      },
      {
        value: "fas fa-align-right",
        label: window.t("fas fa-align-right"),
      },
      {
        value: "fab fa-amazon",
        label: window.t("fab fa-amazon"),
      },
      {
        value: "fas fa-ambulance",
        label: window.t("fas fa-ambulance"),
      },
      {
        value: "fas fa-american-sign-language-interpreting",
        label: window.t("fas fa-american-sign-language-interpreting"),
      },
      {
        value: "fab fa-amilia",
        label: window.t("fab fa-amilia"),
      },
      {
        value: "fas fa-anchor",
        label: window.t("fas fa-anchor"),
      },
      {
        value: "fab fa-android",
        label: window.t("fab fa-android"),
      },
      {
        value: "fab fa-angellist",
        label: window.t("fab fa-angellist"),
      },
      {
        value: "fas fa-angle-double-down",
        label: window.t("fas fa-angle-double-down"),
      },
      {
        value: "fas fa-angle-double-left",
        label: window.t("fas fa-angle-double-left"),
      },
      {
        value: "fas fa-angle-double-right",
        label: window.t("fas fa-angle-double-right"),
      },
      {
        value: "fas fa-angle-double-up",
        label: window.t("fas fa-angle-double-up"),
      },
      {
        value: "fas fa-angle-down",
        label: window.t("fas fa-angle-down"),
      },
      {
        value: "fas fa-angle-left",
        label: window.t("fas fa-angle-left"),
      },
      {
        value: "fas fa-angle-right",
        label: window.t("fas fa-angle-right"),
      },
      {
        value: "fas fa-angle-up",
        label: window.t("fas fa-angle-up"),
      },
      {
        value: "fab fa-angrycreative",
        label: window.t("fab fa-angrycreative"),
      },
      {
        value: "fab fa-angular",
        label: window.t("fab fa-angular"),
      },
      {
        value: "fab fa-app-store",
        label: window.t("fab fa-app-store"),
      },
      {
        value: "fab fa-app-store-ios",
        label: window.t("fab fa-app-store-ios"),
      },
      {
        value: "fab fa-apper",
        label: window.t("fab fa-apper"),
      },
      {
        value: "fab fa-apple",
        label: window.t("fab fa-apple"),
      },
      {
        value: "fab fa-apple-pay",
        label: window.t("fab fa-apple-pay"),
      },
      {
        value: "fas fa-archive",
        label: window.t("fas fa-archive"),
      },
      {
        value: "fas fa-arrow-alt-circle-down",
        label: window.t("fas fa-arrow-alt-circle-down"),
      },
      {
        value: "far fa-arrow-alt-circle-down",
        label: window.t("far fa-arrow-alt-circle-down"),
      },
      {
        value: "fas fa-arrow-alt-circle-left",
        label: window.t("fas fa-arrow-alt-circle-left"),
      },
      {
        value: "far fa-arrow-alt-circle-left",
        label: window.t("far fa-arrow-alt-circle-left"),
      },
      {
        value: "fas fa-arrow-alt-circle-right",
        label: window.t("fas fa-arrow-alt-circle-right"),
      },
      {
        value: "far fa-arrow-alt-circle-right",
        label: window.t("far fa-arrow-alt-circle-right"),
      },
      {
        value: "fas fa-arrow-alt-circle-up",
        label: window.t("fas fa-arrow-alt-circle-up"),
      },
      {
        value: "far fa-arrow-alt-circle-up",
        label: window.t("far fa-arrow-alt-circle-up"),
      },
      {
        value: "fas fa-arrow-circle-down",
        label: window.t("fas fa-arrow-circle-down"),
      },
      {
        value: "fas fa-arrow-circle-left",
        label: window.t("fas fa-arrow-circle-left"),
      },
      {
        value: "fas fa-arrow-circle-right",
        label: window.t("fas fa-arrow-circle-right"),
      },
      {
        value: "fas fa-arrow-circle-up",
        label: window.t("fas fa-arrow-circle-up"),
      },
      {
        value: "fas fa-arrow-down",
        label: window.t("fas fa-arrow-down"),
      },
      {
        value: "fas fa-arrow-left",
        label: window.t("fas fa-arrow-left"),
      },
      {
        value: "fas fa-arrow-right",
        label: window.t("fas fa-arrow-right"),
      },
      {
        value: "fas fa-arrow-up",
        label: window.t("fas fa-arrow-up"),
      },
      {
        value: "fas fa-arrows-alt",
        label: window.t("fas fa-arrows-alt"),
      },
      {
        value: "fas fa-arrows-alt-h",
        label: window.t("fas fa-arrows-alt-h"),
      },
      {
        value: "fas fa-arrows-alt-v",
        label: window.t("fas fa-arrows-alt-v"),
      },
      {
        value: "fas fa-assistive-listening-systems",
        label: window.t("fas fa-assistive-listening-systems"),
      },
      {
        value: "fas fa-asterisk",
        label: window.t("fas fa-asterisk"),
      },
      {
        value: "fab fa-asymmetrik",
        label: window.t("fab fa-asymmetrik"),
      },
      {
        value: "fas fa-at",
        label: window.t("fas fa-at"),
      },
      {
        value: "fab fa-audible",
        label: window.t("fab fa-audible"),
      },
      {
        value: "fas fa-audio-description",
        label: window.t("fas fa-audio-description"),
      },
      {
        value: "fab fa-autoprefixer",
        label: window.t("fab fa-autoprefixer"),
      },
      {
        value: "fab fa-avianex",
        label: window.t("fab fa-avianex"),
      },
      {
        value: "fab fa-aviato",
        label: window.t("fab fa-aviato"),
      },
      {
        value: "fab fa-aws",
        label: window.t("fab fa-aws"),
      },
      {
        value: "fas fa-backward",
        label: window.t("fas fa-backward"),
      },
      {
        value: "fas fa-balance-scale",
        label: window.t("fas fa-balance-scale"),
      },
      {
        value: "fas fa-ban",
        label: window.t("fas fa-ban"),
      },
      {
        value: "fab fa-bandcamp",
        label: window.t("fab fa-bandcamp"),
      },
      {
        value: "fas fa-barcode",
        label: window.t("fas fa-barcode"),
      },
      {
        value: "fas fa-bars",
        label: window.t("fas fa-bars"),
      },
      {
        value: "fas fa-bath",
        label: window.t("fas fa-bath"),
      },
      {
        value: "fas fa-battery-empty",
        label: window.t("fas fa-battery-empty"),
      },
      {
        value: "fas fa-battery-full",
        label: window.t("fas fa-battery-full"),
      },
      {
        value: "fas fa-battery-half",
        label: window.t("fas fa-battery-half"),
      },
      {
        value: "fas fa-battery-quarter",
        label: window.t("fas fa-battery-quarter"),
      },
      {
        value: "fas fa-battery-three-quarters",
        label: window.t("fas fa-battery-three-quarters"),
      },
      {
        value: "fas fa-bed",
        label: window.t("fas fa-bed"),
      },
      {
        value: "fas fa-beer",
        label: window.t("fas fa-beer"),
      },
      {
        value: "fab fa-behance",
        label: window.t("fab fa-behance"),
      },
      {
        value: "fab fa-behance-square",
        label: window.t("fab fa-behance-square"),
      },
      {
        value: "fas fa-bell",
        label: window.t("fas fa-bell"),
      },
      {
        value: "far fa-bell",
        label: window.t("far fa-bell"),
      },
      {
        value: "fas fa-bell-slash",
        label: window.t("fas fa-bell-slash"),
      },
      {
        value: "far fa-bell-slash",
        label: window.t("far fa-bell-slash"),
      },
      {
        value: "fas fa-bicycle",
        label: window.t("fas fa-bicycle"),
      },
      {
        value: "fab fa-bimobject",
        label: window.t("fab fa-bimobject"),
      },
      {
        value: "fas fa-binoculars",
        label: window.t("fas fa-binoculars"),
      },
      {
        value: "fas fa-birthday-cake",
        label: window.t("fas fa-birthday-cake"),
      },
      {
        value: "fab fa-bitbucket",
        label: window.t("fab fa-bitbucket"),
      },
      {
        value: "fab fa-bitcoin",
        label: window.t("fab fa-bitcoin"),
      },
      {
        value: "fab fa-bity",
        label: window.t("fab fa-bity"),
      },
      {
        value: "fab fa-black-tie",
        label: window.t("fab fa-black-tie"),
      },
      {
        value: "fab fa-blackberry",
        label: window.t("fab fa-blackberry"),
      },
      {
        value: "fas fa-blind",
        label: window.t("fas fa-blind"),
      },
      {
        value: "fab fa-blogger",
        label: window.t("fab fa-blogger"),
      },
      {
        value: "fab fa-blogger-b",
        label: window.t("fab fa-blogger-b"),
      },
      {
        value: "fab fa-bluetooth",
        label: window.t("fab fa-bluetooth"),
      },
      {
        value: "fab fa-bluetooth-b",
        label: window.t("fab fa-bluetooth-b"),
      },
      {
        value: "fas fa-bold",
        label: window.t("fas fa-bold"),
      },
      {
        value: "fas fa-bolt",
        label: window.t("fas fa-bolt"),
      },
      {
        value: "fas fa-bomb",
        label: window.t("fas fa-bomb"),
      },
      {
        value: "fas fa-book",
        label: window.t("fas fa-book"),
      },
      {
        value: "fas fa-bookmark",
        label: window.t("fas fa-bookmark"),
      },
      {
        value: "far fa-bookmark",
        label: window.t("far fa-bookmark"),
      },
      {
        value: "fas fa-braille",
        label: window.t("fas fa-braille"),
      },
      {
        value: "fas fa-briefcase",
        label: window.t("fas fa-briefcase"),
      },
      {
        value: "fab fa-btc",
        label: window.t("fab fa-btc"),
      },
      {
        value: "fas fa-bug",
        label: window.t("fas fa-bug"),
      },
      {
        value: "fas fa-building",
        label: window.t("fas fa-building"),
      },
      {
        value: "far fa-building",
        label: window.t("far fa-building"),
      },
      {
        value: "fas fa-bullhorn",
        label: window.t("fas fa-bullhorn"),
      },
      {
        value: "fas fa-bullseye",
        label: window.t("fas fa-bullseye"),
      },
      {
        value: "fab fa-buromobelexperte",
        label: window.t("fab fa-buromobelexperte"),
      },
      {
        value: "fas fa-bus",
        label: window.t("fas fa-bus"),
      },
      {
        value: "fab fa-buysellads",
        label: window.t("fab fa-buysellads"),
      },
      {
        value: "fas fa-calculator",
        label: window.t("fas fa-calculator"),
      },
      {
        value: "fas fa-calendar",
        label: window.t("fas fa-calendar"),
      },
      {
        value: "far fa-calendar",
        label: window.t("far fa-calendar"),
      },
      {
        value: "fas fa-calendar-alt",
        label: window.t("fas fa-calendar-alt"),
      },
      {
        value: "far fa-calendar-alt",
        label: window.t("far fa-calendar-alt"),
      },
      {
        value: "fas fa-calendar-check",
        label: window.t("fas fa-calendar-check"),
      },
      {
        value: "far fa-calendar-check",
        label: window.t("far fa-calendar-check"),
      },
      {
        value: "fas fa-calendar-minus",
        label: window.t("fas fa-calendar-minus"),
      },
      {
        value: "far fa-calendar-minus",
        label: window.t("far fa-calendar-minus"),
      },
      {
        value: "fas fa-calendar-plus",
        label: window.t("fas fa-calendar-plus"),
      },
      {
        value: "far fa-calendar-plus",
        label: window.t("far fa-calendar-plus"),
      },
      {
        value: "fas fa-calendar-times",
        label: window.t("fas fa-calendar-times"),
      },
      {
        value: "far fa-calendar-times",
        label: window.t("far fa-calendar-times"),
      },
      {
        value: "fas fa-camera",
        label: window.t("fas fa-camera"),
      },
      {
        value: "fas fa-camera-retro",
        label: window.t("fas fa-camera-retro"),
      },
      {
        value: "fas fa-car",
        label: window.t("fas fa-car"),
      },
      {
        value: "fas fa-caret-down",
        label: window.t("fas fa-caret-down"),
      },
      {
        value: "fas fa-caret-left",
        label: window.t("fas fa-caret-left"),
      },
      {
        value: "fas fa-caret-right",
        label: window.t("fas fa-caret-right"),
      },
      {
        value: "fas fa-caret-square-down",
        label: window.t("fas fa-caret-square-down"),
      },
      {
        value: "far fa-caret-square-down",
        label: window.t("far fa-caret-square-down"),
      },
      {
        value: "fas fa-caret-square-left",
        label: window.t("fas fa-caret-square-left"),
      },
      {
        value: "far fa-caret-square-left",
        label: window.t("far fa-caret-square-left"),
      },
      {
        value: "fas fa-caret-square-right",
        label: window.t("fas fa-caret-square-right"),
      },
      {
        value: "far fa-caret-square-right",
        label: window.t("far fa-caret-square-right"),
      },
      {
        value: "fas fa-caret-square-up",
        label: window.t("fas fa-caret-square-up"),
      },
      {
        value: "far fa-caret-square-up",
        label: window.t("far fa-caret-square-up"),
      },
      {
        value: "fas fa-caret-up",
        label: window.t("fas fa-caret-up"),
      },
      {
        value: "fas fa-cart-arrow-down",
        label: window.t("fas fa-cart-arrow-down"),
      },
      {
        value: "fas fa-cart-plus",
        label: window.t("fas fa-cart-plus"),
      },
      {
        value: "fab fa-cc-amex",
        label: window.t("fab fa-cc-amex"),
      },
      {
        value: "fab fa-cc-apple-pay",
        label: window.t("fab fa-cc-apple-pay"),
      },
      {
        value: "fab fa-cc-diners-club",
        label: window.t("fab fa-cc-diners-club"),
      },
      {
        value: "fab fa-cc-discover",
        label: window.t("fab fa-cc-discover"),
      },
      {
        value: "fab fa-cc-jcb",
        label: window.t("fab fa-cc-jcb"),
      },
      {
        value: "fab fa-cc-mastercard",
        label: window.t("fab fa-cc-mastercard"),
      },
      {
        value: "fab fa-cc-paypal",
        label: window.t("fab fa-cc-paypal"),
      },
      {
        value: "fab fa-cc-stripe",
        label: window.t("fab fa-cc-stripe"),
      },
      {
        value: "fab fa-cc-visa",
        label: window.t("fab fa-cc-visa"),
      },
      {
        value: "fab fa-centercode",
        label: window.t("fab fa-centercode"),
      },
      {
        value: "fas fa-certificate",
        label: window.t("fas fa-certificate"),
      },
      {
        value: "fas fa-chart-area",
        label: window.t("fas fa-chart-area"),
      },
      {
        value: "fas fa-chart-bar",
        label: window.t("fas fa-chart-bar"),
      },
      {
        value: "far fa-chart-bar",
        label: window.t("far fa-chart-bar"),
      },
      {
        value: "fas fa-chart-line",
        label: window.t("fas fa-chart-line"),
      },
      {
        value: "fas fa-chart-pie",
        label: window.t("fas fa-chart-pie"),
      },
      {
        value: "fas fa-check",
        label: window.t("fas fa-check"),
      },
      {
        value: "fas fa-check-circle",
        label: window.t("fas fa-check-circle"),
      },
      {
        value: "far fa-check-circle",
        label: window.t("far fa-check-circle"),
      },
      {
        value: "fas fa-check-square",
        label: window.t("fas fa-check-square"),
      },
      {
        value: "far fa-check-square",
        label: window.t("far fa-check-square"),
      },
      {
        value: "fas fa-chevron-circle-down",
        label: window.t("fas fa-chevron-circle-down"),
      },
      {
        value: "fas fa-chevron-circle-left",
        label: window.t("fas fa-chevron-circle-left"),
      },
      {
        value: "fas fa-chevron-circle-right",
        label: window.t("fas fa-chevron-circle-right"),
      },
      {
        value: "fas fa-chevron-circle-up",
        label: window.t("fas fa-chevron-circle-up"),
      },
      {
        value: "fas fa-chevron-down",
        label: window.t("fas fa-chevron-down"),
      },
      {
        value: "fas fa-chevron-left",
        label: window.t("fas fa-chevron-left"),
      },
      {
        value: "fas fa-chevron-right",
        label: window.t("fas fa-chevron-right"),
      },
      {
        value: "fas fa-chevron-up",
        label: window.t("fas fa-chevron-up"),
      },
      {
        value: "fas fa-child",
        label: window.t("fas fa-child"),
      },
      {
        value: "fab fa-chrome",
        label: window.t("fab fa-chrome"),
      },
      {
        value: "fas fa-circle",
        label: window.t("fas fa-circle"),
      },
      {
        value: "far fa-circle",
        label: window.t("far fa-circle"),
      },
      {
        value: "fas fa-circle-notch",
        label: window.t("fas fa-circle-notch"),
      },
      {
        value: "fas fa-clipboard",
        label: window.t("fas fa-clipboard"),
      },
      {
        value: "far fa-clipboard",
        label: window.t("far fa-clipboard"),
      },
      {
        value: "fas fa-clock",
        label: window.t("fas fa-clock"),
      },
      {
        value: "far fa-clock",
        label: window.t("far fa-clock"),
      },
      {
        value: "fas fa-clone",
        label: window.t("fas fa-clone"),
      },
      {
        value: "far fa-clone",
        label: window.t("far fa-clone"),
      },
      {
        value: "fas fa-closed-body1ing",
        label: window.t("fas fa-closed-body1ing"),
      },
      {
        value: "far fa-closed-body1ing",
        label: window.t("far fa-closed-body1ing"),
      },
      {
        value: "fas fa-cloud",
        label: window.t("fas fa-cloud"),
      },
      {
        value: "fas fa-cloud-download-alt",
        label: window.t("fas fa-cloud-download-alt"),
      },
      {
        value: "fas fa-cloud-upload-alt",
        label: window.t("fas fa-cloud-upload-alt"),
      },
      {
        value: "fab fa-cloudscale",
        label: window.t("fab fa-cloudscale"),
      },
      {
        value: "fab fa-cloudsmith",
        label: window.t("fab fa-cloudsmith"),
      },
      {
        value: "fab fa-cloudversify",
        label: window.t("fab fa-cloudversify"),
      },
      {
        value: "fas fa-code",
        label: window.t("fas fa-code"),
      },
      {
        value: "fas fa-code-branch",
        label: window.t("fas fa-code-branch"),
      },
      {
        value: "fab fa-codepen",
        label: window.t("fab fa-codepen"),
      },
      {
        value: "fab fa-codiepie",
        label: window.t("fab fa-codiepie"),
      },
      {
        value: "fas fa-coffee",
        label: window.t("fas fa-coffee"),
      },
      {
        value: "fas fa-cog",
        label: window.t("fas fa-cog"),
      },
      {
        value: "fas fa-cogs",
        label: window.t("fas fa-cogs"),
      },
      {
        value: "fas fa-columns",
        label: window.t("fas fa-columns"),
      },
      {
        value: "fas fa-comment",
        label: window.t("fas fa-comment"),
      },
      {
        value: "far fa-comment",
        label: window.t("far fa-comment"),
      },
      {
        value: "fas fa-comment-alt",
        label: window.t("fas fa-comment-alt"),
      },
      {
        value: "far fa-comment-alt",
        label: window.t("far fa-comment-alt"),
      },
      {
        value: "fas fa-comments",
        label: window.t("fas fa-comments"),
      },
      {
        value: "far fa-comments",
        label: window.t("far fa-comments"),
      },
      {
        value: "fas fa-compass",
        label: window.t("fas fa-compass"),
      },
      {
        value: "far fa-compass",
        label: window.t("far fa-compass"),
      },
      {
        value: "fas fa-compress",
        label: window.t("fas fa-compress"),
      },
      {
        value: "fab fa-connectdevelop",
        label: window.t("fab fa-connectdevelop"),
      },
      {
        value: "fab fa-contao",
        label: window.t("fab fa-contao"),
      },
      {
        value: "fas fa-copy",
        label: window.t("fas fa-copy"),
      },
      {
        value: "far fa-copy",
        label: window.t("far fa-copy"),
      },
      {
        value: "fas fa-copyright",
        label: window.t("fas fa-copyright"),
      },
      {
        value: "far fa-copyright",
        label: window.t("far fa-copyright"),
      },
      {
        value: "fab fa-cpanel",
        label: window.t("fab fa-cpanel"),
      },
      {
        value: "fab fa-creative-commons",
        label: window.t("fab fa-creative-commons"),
      },
      {
        value: "fas fa-credit-card",
        label: window.t("fas fa-credit-card"),
      },
      {
        value: "far fa-credit-card",
        label: window.t("far fa-credit-card"),
      },
      {
        value: "fas fa-crop",
        label: window.t("fas fa-crop"),
      },
      {
        value: "fas fa-crosshairs",
        label: window.t("fas fa-crosshairs"),
      },
      {
        value: "fab fa-css3",
        label: window.t("fab fa-css3"),
      },
      {
        value: "fab fa-css3-alt",
        label: window.t("fab fa-css3-alt"),
      },
      {
        value: "fas fa-cube",
        label: window.t("fas fa-cube"),
      },
      {
        value: "fas fa-cubes",
        label: window.t("fas fa-cubes"),
      },
      {
        value: "fas fa-cut",
        label: window.t("fas fa-cut"),
      },
      {
        value: "fab fa-cuttlefish",
        label: window.t("fab fa-cuttlefish"),
      },
      {
        value: "fab fa-d-and-d",
        label: window.t("fab fa-d-and-d"),
      },
      {
        value: "fab fa-dashcube",
        label: window.t("fab fa-dashcube"),
      },
      {
        value: "fas fa-database",
        label: window.t("fas fa-database"),
      },
      {
        value: "fas fa-deaf",
        label: window.t("fas fa-deaf"),
      },
      {
        value: "fab fa-delicious",
        label: window.t("fab fa-delicious"),
      },
      {
        value: "fab fa-deploydog",
        label: window.t("fab fa-deploydog"),
      },
      {
        value: "fab fa-deskpro",
        label: window.t("fab fa-deskpro"),
      },
      {
        value: "fas fa-desktop",
        label: window.t("fas fa-desktop"),
      },
      {
        value: "fab fa-deviantart",
        label: window.t("fab fa-deviantart"),
      },
      {
        value: "fab fa-digg",
        label: window.t("fab fa-digg"),
      },
      {
        value: "fab fa-digital-ocean",
        label: window.t("fab fa-digital-ocean"),
      },
      {
        value: "fab fa-discord",
        label: window.t("fab fa-discord"),
      },
      {
        value: "fab fa-discourse",
        label: window.t("fab fa-discourse"),
      },
      {
        value: "fab fa-dochub",
        label: window.t("fab fa-dochub"),
      },
      {
        value: "fab fa-docker",
        label: window.t("fab fa-docker"),
      },
      {
        value: "fas fa-dollar-sign",
        label: window.t("fas fa-dollar-sign"),
      },
      {
        value: "fas fa-dot-circle",
        label: window.t("fas fa-dot-circle"),
      },
      {
        value: "far fa-dot-circle",
        label: window.t("far fa-dot-circle"),
      },
      {
        value: "fas fa-download",
        label: window.t("fas fa-download"),
      },
      {
        value: "fab fa-draft2digital",
        label: window.t("fab fa-draft2digital"),
      },
      {
        value: "fab fa-dribbble",
        label: window.t("fab fa-dribbble"),
      },
      {
        value: "fab fa-dribbble-square",
        label: window.t("fab fa-dribbble-square"),
      },
      {
        value: "fab fa-dropbox",
        label: window.t("fab fa-dropbox"),
      },
      {
        value: "fab fa-drupal",
        label: window.t("fab fa-drupal"),
      },
      {
        value: "fab fa-dyalog",
        label: window.t("fab fa-dyalog"),
      },
      {
        value: "fab fa-earlybirds",
        label: window.t("fab fa-earlybirds"),
      },
      {
        value: "fab fa-edge",
        label: window.t("fab fa-edge"),
      },
      {
        value: "fas fa-edit",
        label: window.t("fas fa-edit"),
      },
      {
        value: "far fa-edit",
        label: window.t("far fa-edit"),
      },
      {
        value: "fas fa-eject",
        label: window.t("fas fa-eject"),
      },
      {
        value: "fas fa-ellipsis-h",
        label: window.t("fas fa-ellipsis-h"),
      },
      {
        value: "fas fa-ellipsis-v",
        label: window.t("fas fa-ellipsis-v"),
      },
      {
        value: "fab fa-ember",
        label: window.t("fab fa-ember"),
      },
      {
        value: "fab fa-empire",
        label: window.t("fab fa-empire"),
      },
      {
        value: "fas fa-envelope",
        label: window.t("fas fa-envelope"),
      },
      {
        value: "far fa-envelope",
        label: window.t("far fa-envelope"),
      },
      {
        value: "fas fa-envelope-open",
        label: window.t("fas fa-envelope-open"),
      },
      {
        value: "far fa-envelope-open",
        label: window.t("far fa-envelope-open"),
      },
      {
        value: "fas fa-envelope-square",
        label: window.t("fas fa-envelope-square"),
      },
      {
        value: "fab fa-envira",
        label: window.t("fab fa-envira"),
      },
      {
        value: "fas fa-eraser",
        label: window.t("fas fa-eraser"),
      },
      {
        value: "fab fa-erlang",
        label: window.t("fab fa-erlang"),
      },
      {
        value: "fab fa-etsy",
        label: window.t("fab fa-etsy"),
      },
      {
        value: "fas fa-euro-sign",
        label: window.t("fas fa-euro-sign"),
      },
      {
        value: "fas fa-exchange-alt",
        label: window.t("fas fa-exchange-alt"),
      },
      {
        value: "fas fa-exclamation",
        label: window.t("fas fa-exclamation"),
      },
      {
        value: "fas fa-exclamation-circle",
        label: window.t("fas fa-exclamation-circle"),
      },
      {
        value: "fas fa-exclamation-triangle",
        label: window.t("fas fa-exclamation-triangle"),
      },
      {
        value: "fas fa-expand",
        label: window.t("fas fa-expand"),
      },
      {
        value: "fas fa-expand-arrows-alt",
        label: window.t("fas fa-expand-arrows-alt"),
      },
      {
        value: "fab fa-expeditedssl",
        label: window.t("fab fa-expeditedssl"),
      },
      {
        value: "fas fa-external-link-alt",
        label: window.t("fas fa-external-link-alt"),
      },
      {
        value: "fas fa-external-link-square-alt",
        label: window.t("fas fa-external-link-square-alt"),
      },
      {
        value: "fas fa-eye",
        label: window.t("fas fa-eye"),
      },
      {
        value: "fas fa-eye-dropper",
        label: window.t("fas fa-eye-dropper"),
      },
      {
        value: "fas fa-eye-slash",
        label: window.t("fas fa-eye-slash"),
      },
      {
        value: "far fa-eye-slash",
        label: window.t("far fa-eye-slash"),
      },
      {
        value: "fab fa-facebook",
        label: window.t("fab fa-facebook"),
      },
      {
        value: "fab fa-facebook-f",
        label: window.t("fab fa-facebook-f"),
      },
      {
        value: "fab fa-facebook-messenger",
        label: window.t("fab fa-facebook-messenger"),
      },
      {
        value: "fab fa-facebook-square",
        label: window.t("fab fa-facebook-square"),
      },
      {
        value: "fas fa-fast-backward",
        label: window.t("fas fa-fast-backward"),
      },
      {
        value: "fas fa-fast-forward",
        label: window.t("fas fa-fast-forward"),
      },
      {
        value: "fas fa-fax",
        label: window.t("fas fa-fax"),
      },
      {
        value: "fas fa-female",
        label: window.t("fas fa-female"),
      },
      {
        value: "fas fa-fighter-jet",
        label: window.t("fas fa-fighter-jet"),
      },
      {
        value: "fas fa-file",
        label: window.t("fas fa-file"),
      },
      {
        value: "far fa-file",
        label: window.t("far fa-file"),
      },
      {
        value: "fas fa-file-alt",
        label: window.t("fas fa-file-alt"),
      },
      {
        value: "far fa-file-alt",
        label: window.t("far fa-file-alt"),
      },
      {
        value: "fas fa-file-archive",
        label: window.t("fas fa-file-archive"),
      },
      {
        value: "far fa-file-archive",
        label: window.t("far fa-file-archive"),
      },
      {
        value: "fas fa-file-audio",
        label: window.t("fas fa-file-audio"),
      },
      {
        value: "far fa-file-audio",
        label: window.t("far fa-file-audio"),
      },
      {
        value: "fas fa-file-code",
        label: window.t("fas fa-file-code"),
      },
      {
        value: "far fa-file-code",
        label: window.t("far fa-file-code"),
      },
      {
        value: "fas fa-file-excel",
        label: window.t("fas fa-file-excel"),
      },
      {
        value: "far fa-file-excel",
        label: window.t("far fa-file-excel"),
      },
      {
        value: "fas fa-file-image",
        label: window.t("fas fa-file-image"),
      },
      {
        value: "far fa-file-image",
        label: window.t("far fa-file-image"),
      },
      {
        value: "fas fa-file-pdf",
        label: window.t("fas fa-file-pdf"),
      },
      {
        value: "far fa-file-pdf",
        label: window.t("far fa-file-pdf"),
      },
      {
        value: "fas fa-file-powerpoint",
        label: window.t("fas fa-file-powerpoint"),
      },
      {
        value: "far fa-file-powerpoint",
        label: window.t("far fa-file-powerpoint"),
      },
      {
        value: "fas fa-file-video",
        label: window.t("fas fa-file-video"),
      },
      {
        value: "far fa-file-video",
        label: window.t("far fa-file-video"),
      },
      {
        value: "fas fa-file-word",
        label: window.t("fas fa-file-word"),
      },
      {
        value: "far fa-file-word",
        label: window.t("far fa-file-word"),
      },
      {
        value: "fas fa-film",
        label: window.t("fas fa-film"),
      },
      {
        value: "fas fa-filter",
        label: window.t("fas fa-filter"),
      },
      {
        value: "fas fa-fire",
        label: window.t("fas fa-fire"),
      },
      {
        value: "fas fa-fire-extinguisher",
        label: window.t("fas fa-fire-extinguisher"),
      },
      {
        value: "fab fa-firefox",
        label: window.t("fab fa-firefox"),
      },
      {
        value: "fab fa-first-order",
        label: window.t("fab fa-first-order"),
      },
      {
        value: "fab fa-firstdraft",
        label: window.t("fab fa-firstdraft"),
      },
      {
        value: "fas fa-flag",
        label: window.t("fas fa-flag"),
      },
      {
        value: "far fa-flag",
        label: window.t("far fa-flag"),
      },
      {
        value: "fas fa-flag-checkered",
        label: window.t("fas fa-flag-checkered"),
      },
      {
        value: "fas fa-flask",
        label: window.t("fas fa-flask"),
      },
      {
        value: "fab fa-flickr",
        label: window.t("fab fa-flickr"),
      },
      {
        value: "fab fa-fly",
        label: window.t("fab fa-fly"),
      },
      {
        value: "fas fa-folder",
        label: window.t("fas fa-folder"),
      },
      {
        value: "far fa-folder",
        label: window.t("far fa-folder"),
      },
      {
        value: "fas fa-folder-open",
        label: window.t("fas fa-folder-open"),
      },
      {
        value: "far fa-folder-open",
        label: window.t("far fa-folder-open"),
      },
      {
        value: "fas fa-font",
        label: window.t("fas fa-font"),
      },
      {
        value: "fab fa-font-awesome",
        label: window.t("fab fa-font-awesome"),
      },
      {
        value: "fab fa-font-awesome-alt",
        label: window.t("fab fa-font-awesome-alt"),
      },
      {
        value: "fab fa-font-awesome-flag",
        label: window.t("fab fa-font-awesome-flag"),
      },
      {
        value: "fab fa-fonticons",
        label: window.t("fab fa-fonticons"),
      },
      {
        value: "fab fa-fonticons-fi",
        label: window.t("fab fa-fonticons-fi"),
      },
      {
        value: "fab fa-fort-awesome",
        label: window.t("fab fa-fort-awesome"),
      },
      {
        value: "fab fa-fort-awesome-alt",
        label: window.t("fab fa-fort-awesome-alt"),
      },
      {
        value: "fab fa-forumbee",
        label: window.t("fab fa-forumbee"),
      },
      {
        value: "fas fa-forward",
        label: window.t("fas fa-forward"),
      },
      {
        value: "fab fa-foursquare",
        label: window.t("fab fa-foursquare"),
      },
      {
        value: "fab fa-free-code-camp",
        label: window.t("fab fa-free-code-camp"),
      },
      {
        value: "fab fa-freebsd",
        label: window.t("fab fa-freebsd"),
      },
      {
        value: "fas fa-frown",
        label: window.t("fas fa-frown"),
      },
      {
        value: "far fa-frown",
        label: window.t("far fa-frown"),
      },
      {
        value: "fas fa-futbol",
        label: window.t("fas fa-futbol"),
      },
      {
        value: "far fa-futbol",
        label: window.t("far fa-futbol"),
      },
      {
        value: "fas fa-gamepad",
        label: window.t("fas fa-gamepad"),
      },
      {
        value: "fas fa-gavel",
        label: window.t("fas fa-gavel"),
      },
      {
        value: "fas fa-gem",
        label: window.t("fas fa-gem"),
      },
      {
        value: "far fa-gem",
        label: window.t("far fa-gem"),
      },
      {
        value: "fas fa-genderless",
        label: window.t("fas fa-genderless"),
      },
      {
        value: "fab fa-get-pocket",
        label: window.t("fab fa-get-pocket"),
      },
      {
        value: "fab fa-gg",
        label: window.t("fab fa-gg"),
      },
      {
        value: "fab fa-gg-circle",
        label: window.t("fab fa-gg-circle"),
      },
      {
        value: "fas fa-gift",
        label: window.t("fas fa-gift"),
      },
      {
        value: "fab fa-git",
        label: window.t("fab fa-git"),
      },
      {
        value: "fab fa-git-square",
        label: window.t("fab fa-git-square"),
      },
      {
        value: "fab fa-github",
        label: window.t("fab fa-github"),
      },
      {
        value: "fab fa-github-alt",
        label: window.t("fab fa-github-alt"),
      },
      {
        value: "fab fa-github-square",
        label: window.t("fab fa-github-square"),
      },
      {
        value: "fab fa-gitkraken",
        label: window.t("fab fa-gitkraken"),
      },
      {
        value: "fab fa-gitlab",
        label: window.t("fab fa-gitlab"),
      },
      {
        value: "fab fa-gitter",
        label: window.t("fab fa-gitter"),
      },
      {
        value: "fas fa-glass-martini",
        label: window.t("fas fa-glass-martini"),
      },
      {
        value: "fab fa-glide",
        label: window.t("fab fa-glide"),
      },
      {
        value: "fab fa-glide-g",
        label: window.t("fab fa-glide-g"),
      },
      {
        value: "fas fa-globe",
        label: window.t("fas fa-globe"),
      },
      {
        value: "fab fa-gofore",
        label: window.t("fab fa-gofore"),
      },
      {
        value: "fab fa-goodreads",
        label: window.t("fab fa-goodreads"),
      },
      {
        value: "fab fa-goodreads-g",
        label: window.t("fab fa-goodreads-g"),
      },
      {
        value: "fab fa-google",
        label: window.t("fab fa-google"),
      },
      {
        value: "fab fa-google-drive",
        label: window.t("fab fa-google-drive"),
      },
      {
        value: "fab fa-google-play",
        label: window.t("fab fa-google-play"),
      },
      {
        value: "fab fa-google-plus",
        label: window.t("fab fa-google-plus"),
      },
      {
        value: "fab fa-google-plus-g",
        label: window.t("fab fa-google-plus-g"),
      },
      {
        value: "fab fa-google-plus-square",
        label: window.t("fab fa-google-plus-square"),
      },
      {
        value: "fab fa-google-wallet",
        label: window.t("fab fa-google-wallet"),
      },
      {
        value: "fas fa-graduation-cap",
        label: window.t("fas fa-graduation-cap"),
      },
      {
        value: "fab fa-gratipay",
        label: window.t("fab fa-gratipay"),
      },
      {
        value: "fab fa-grav",
        label: window.t("fab fa-grav"),
      },
      {
        value: "fab fa-gripfire",
        label: window.t("fab fa-gripfire"),
      },
      {
        value: "fab fa-grunt",
        label: window.t("fab fa-grunt"),
      },
      {
        value: "fab fa-gulp",
        label: window.t("fab fa-gulp"),
      },
      {
        value: "fas fa-h-square",
        label: window.t("fas fa-h-square"),
      },
      {
        value: "fab fa-hacker-news",
        label: window.t("fab fa-hacker-news"),
      },
      {
        value: "fab fa-hacker-news-square",
        label: window.t("fab fa-hacker-news-square"),
      },
      {
        value: "fas fa-hand-lizard",
        label: window.t("fas fa-hand-lizard"),
      },
      {
        value: "far fa-hand-lizard",
        label: window.t("far fa-hand-lizard"),
      },
      {
        value: "fas fa-hand-paper",
        label: window.t("fas fa-hand-paper"),
      },
      {
        value: "far fa-hand-paper",
        label: window.t("far fa-hand-paper"),
      },
      {
        value: "fas fa-hand-peace",
        label: window.t("fas fa-hand-peace"),
      },
      {
        value: "far fa-hand-peace",
        label: window.t("far fa-hand-peace"),
      },
      {
        value: "fas fa-hand-point-down",
        label: window.t("fas fa-hand-point-down"),
      },
      {
        value: "far fa-hand-point-down",
        label: window.t("far fa-hand-point-down"),
      },
      {
        value: "fas fa-hand-point-left",
        label: window.t("fas fa-hand-point-left"),
      },
      {
        value: "far fa-hand-point-left",
        label: window.t("far fa-hand-point-left"),
      },
      {
        value: "fas fa-hand-point-right",
        label: window.t("fas fa-hand-point-right"),
      },
      {
        value: "far fa-hand-point-right",
        label: window.t("far fa-hand-point-right"),
      },
      {
        value: "fas fa-hand-point-up",
        label: window.t("fas fa-hand-point-up"),
      },
      {
        value: "far fa-hand-point-up",
        label: window.t("far fa-hand-point-up"),
      },
      {
        value: "fas fa-hand-pointer",
        label: window.t("fas fa-hand-pointer"),
      },
      {
        value: "far fa-hand-pointer",
        label: window.t("far fa-hand-pointer"),
      },
      {
        value: "fas fa-hand-rock",
        label: window.t("fas fa-hand-rock"),
      },
      {
        value: "far fa-hand-rock",
        label: window.t("far fa-hand-rock"),
      },
      {
        value: "fas fa-hand-scissors",
        label: window.t("fas fa-hand-scissors"),
      },
      {
        value: "far fa-hand-scissors",
        label: window.t("far fa-hand-scissors"),
      },
      {
        value: "fas fa-hand-spock",
        label: window.t("fas fa-hand-spock"),
      },
      {
        value: "far fa-hand-spock",
        label: window.t("far fa-hand-spock"),
      },
      {
        value: "fas fa-handshake",
        label: window.t("fas fa-handshake"),
      },
      {
        value: "far fa-handshake",
        label: window.t("far fa-handshake"),
      },
      {
        value: "fas fa-hashtag",
        label: window.t("fas fa-hashtag"),
      },
      {
        value: "fas fa-hdd",
        label: window.t("fas fa-hdd"),
      },
      {
        value: "far fa-hdd",
        label: window.t("far fa-hdd"),
      },
      {
        value: "fas fa-heading",
        label: window.t("fas fa-heading"),
      },
      {
        value: "fas fa-headphones",
        label: window.t("fas fa-headphones"),
      },
      {
        value: "fas fa-heart",
        label: window.t("fas fa-heart"),
      },
      {
        value: "far fa-heart",
        label: window.t("far fa-heart"),
      },
      {
        value: "fas fa-heartbeat",
        label: window.t("fas fa-heartbeat"),
      },
      {
        value: "fab fa-hire-a-helper",
        label: window.t("fab fa-hire-a-helper"),
      },
      {
        value: "fas fa-history",
        label: window.t("fas fa-history"),
      },
      {
        value: "fas fa-home",
        label: window.t("fas fa-home"),
      },
      {
        value: "fab fa-hooli",
        label: window.t("fab fa-hooli"),
      },
      {
        value: "fas fa-hospital",
        label: window.t("fas fa-hospital"),
      },
      {
        value: "far fa-hospital",
        label: window.t("far fa-hospital"),
      },
      {
        value: "fab fa-hotjar",
        label: window.t("fab fa-hotjar"),
      },
      {
        value: "fas fa-hourglass",
        label: window.t("fas fa-hourglass"),
      },
      {
        value: "far fa-hourglass",
        label: window.t("far fa-hourglass"),
      },
      {
        value: "fas fa-hourglass-end",
        label: window.t("fas fa-hourglass-end"),
      },
      {
        value: "fas fa-hourglass-half",
        label: window.t("fas fa-hourglass-half"),
      },
      {
        value: "fas fa-hourglass-start",
        label: window.t("fas fa-hourglass-start"),
      },
      {
        value: "fab fa-houzz",
        label: window.t("fab fa-houzz"),
      },
      {
        value: "fab fa-html5",
        label: window.t("fab fa-html5"),
      },
      {
        value: "fab fa-hubspot",
        label: window.t("fab fa-hubspot"),
      },
      {
        value: "fas fa-i-cursor",
        label: window.t("fas fa-i-cursor"),
      },
      {
        value: "fas fa-id-badge",
        label: window.t("fas fa-id-badge"),
      },
      {
        value: "far fa-id-badge",
        label: window.t("far fa-id-badge"),
      },
      {
        value: "fas fa-id-card",
        label: window.t("fas fa-id-card"),
      },
      {
        value: "far fa-id-card",
        label: window.t("far fa-id-card"),
      },
      {
        value: "fas fa-image",
        label: window.t("fas fa-image"),
      },
      {
        value: "far fa-image",
        label: window.t("far fa-image"),
      },
      {
        value: "fas fa-images",
        label: window.t("fas fa-images"),
      },
      {
        value: "far fa-images",
        label: window.t("far fa-images"),
      },
      {
        value: "fab fa-imdb",
        label: window.t("fab fa-imdb"),
      },
      {
        value: "fas fa-inbox",
        label: window.t("fas fa-inbox"),
      },
      {
        value: "fas fa-indent",
        label: window.t("fas fa-indent"),
      },
      {
        value: "fas fa-industry",
        label: window.t("fas fa-industry"),
      },
      {
        value: "fas fa-info",
        label: window.t("fas fa-info"),
      },
      {
        value: "fas fa-info-circle",
        label: window.t("fas fa-info-circle"),
      },
      {
        value: "fab fa-instagram",
        label: window.t("fab fa-instagram"),
      },
      {
        value: "fab fa-internet-explorer",
        label: window.t("fab fa-internet-explorer"),
      },
      {
        value: "fab fa-ioxhost",
        label: window.t("fab fa-ioxhost"),
      },
      {
        value: "fas fa-italic",
        label: window.t("fas fa-italic"),
      },
      {
        value: "fab fa-itunes",
        label: window.t("fab fa-itunes"),
      },
      {
        value: "fab fa-itunes-note",
        label: window.t("fab fa-itunes-note"),
      },
      {
        value: "fab fa-jenkins",
        label: window.t("fab fa-jenkins"),
      },
      {
        value: "fab fa-joget",
        label: window.t("fab fa-joget"),
      },
      {
        value: "fab fa-joomla",
        label: window.t("fab fa-joomla"),
      },
      {
        value: "fab fa-js",
        label: window.t("fab fa-js"),
      },
      {
        value: "fab fa-js-square",
        label: window.t("fab fa-js-square"),
      },
      {
        value: "fab fa-jsfiddle",
        label: window.t("fab fa-jsfiddle"),
      },
      {
        value: "fas fa-key",
        label: window.t("fas fa-key"),
      },
      {
        value: "fas fa-keyboard",
        label: window.t("fas fa-keyboard"),
      },
      {
        value: "far fa-keyboard",
        label: window.t("far fa-keyboard"),
      },
      {
        value: "fab fa-keycdn",
        label: window.t("fab fa-keycdn"),
      },
      {
        value: "fab fa-kickstarter",
        label: window.t("fab fa-kickstarter"),
      },
      {
        value: "fab fa-kickstarter-k",
        label: window.t("fab fa-kickstarter-k"),
      },
      {
        value: "fas fa-language",
        label: window.t("fas fa-language"),
      },
      {
        value: "fas fa-laptop",
        label: window.t("fas fa-laptop"),
      },
      {
        value: "fab fa-laravel",
        label: window.t("fab fa-laravel"),
      },
      {
        value: "fab fa-lastfm",
        label: window.t("fab fa-lastfm"),
      },
      {
        value: "fab fa-lastfm-square",
        label: window.t("fab fa-lastfm-square"),
      },
      {
        value: "fas fa-leaf",
        label: window.t("fas fa-leaf"),
      },
      {
        value: "fab fa-leanpub",
        label: window.t("fab fa-leanpub"),
      },
      {
        value: "fas fa-lemon",
        label: window.t("fas fa-lemon"),
      },
      {
        value: "far fa-lemon",
        label: window.t("far fa-lemon"),
      },
      {
        value: "fab fa-less",
        label: window.t("fab fa-less"),
      },
      {
        value: "fas fa-level-down-alt",
        label: window.t("fas fa-level-down-alt"),
      },
      {
        value: "fas fa-level-up-alt",
        label: window.t("fas fa-level-up-alt"),
      },
      {
        value: "fas fa-life-ring",
        label: window.t("fas fa-life-ring"),
      },
      {
        value: "far fa-life-ring",
        label: window.t("far fa-life-ring"),
      },
      {
        value: "fas fa-lightbulb",
        label: window.t("fas fa-lightbulb"),
      },
      {
        value: "far fa-lightbulb",
        label: window.t("far fa-lightbulb"),
      },
      {
        value: "fab fa-line",
        label: window.t("fab fa-line"),
      },
      {
        value: "fas fa-link",
        label: window.t("fas fa-link"),
      },
      {
        value: "fab fa-linkedin",
        label: window.t("fab fa-linkedin"),
      },
      {
        value: "fab fa-linkedin-in",
        label: window.t("fab fa-linkedin-in"),
      },
      {
        value: "fab fa-linode",
        label: window.t("fab fa-linode"),
      },
      {
        value: "fab fa-linux",
        label: window.t("fab fa-linux"),
      },
      {
        value: "fas fa-lira-sign",
        label: window.t("fas fa-lira-sign"),
      },
      {
        value: "fas fa-list",
        label: window.t("fas fa-list"),
      },
      {
        value: "fas fa-list-alt",
        label: window.t("fas fa-list-alt"),
      },
      {
        value: "far fa-list-alt",
        label: window.t("far fa-list-alt"),
      },
      {
        value: "fas fa-list-ol",
        label: window.t("fas fa-list-ol"),
      },
      {
        value: "fas fa-list-ul",
        label: window.t("fas fa-list-ul"),
      },
      {
        value: "fas fa-location-arrow",
        label: window.t("fas fa-location-arrow"),
      },
      {
        value: "fas fa-lock",
        label: window.t("fas fa-lock"),
      },
      {
        value: "fas fa-lock-open",
        label: window.t("fas fa-lock-open"),
      },
      {
        value: "fas fa-long-arrow-alt-down",
        label: window.t("fas fa-long-arrow-alt-down"),
      },
      {
        value: "fas fa-long-arrow-alt-left",
        label: window.t("fas fa-long-arrow-alt-left"),
      },
      {
        value: "fas fa-long-arrow-alt-right",
        label: window.t("fas fa-long-arrow-alt-right"),
      },
      {
        value: "fas fa-long-arrow-alt-up",
        label: window.t("fas fa-long-arrow-alt-up"),
      },
      {
        value: "fas fa-low-vision",
        label: window.t("fas fa-low-vision"),
      },
      {
        value: "fab fa-lyft",
        label: window.t("fab fa-lyft"),
      },
      {
        value: "fab fa-magento",
        label: window.t("fab fa-magento"),
      },
      {
        value: "fas fa-magic",
        label: window.t("fas fa-magic"),
      },
      {
        value: "fas fa-magnet",
        label: window.t("fas fa-magnet"),
      },
      {
        value: "fas fa-male",
        label: window.t("fas fa-male"),
      },
      {
        value: "fas fa-map",
        label: window.t("fas fa-map"),
      },
      {
        value: "far fa-map",
        label: window.t("far fa-map"),
      },
      {
        value: "fas fa-map-marker",
        label: window.t("fas fa-map-marker"),
      },
      {
        value: "fas fa-map-marker-alt",
        label: window.t("fas fa-map-marker-alt"),
      },
      {
        value: "fas fa-map-pin",
        label: window.t("fas fa-map-pin"),
      },
      {
        value: "fas fa-map-signs",
        label: window.t("fas fa-map-signs"),
      },
      {
        value: "fas fa-mars",
        label: window.t("fas fa-mars"),
      },
      {
        value: "fas fa-mars-double",
        label: window.t("fas fa-mars-double"),
      },
      {
        value: "fas fa-mars-stroke",
        label: window.t("fas fa-mars-stroke"),
      },
      {
        value: "fas fa-mars-stroke-h",
        label: window.t("fas fa-mars-stroke-h"),
      },
      {
        value: "fas fa-mars-stroke-v",
        label: window.t("fas fa-mars-stroke-v"),
      },
      {
        value: "fab fa-maxcdn",
        label: window.t("fab fa-maxcdn"),
      },
      {
        value: "fab fa-medapps",
        label: window.t("fab fa-medapps"),
      },
      {
        value: "fab fa-medium",
        label: window.t("fab fa-medium"),
      },
      {
        value: "fab fa-medium-m",
        label: window.t("fab fa-medium-m"),
      },
      {
        value: "fas fa-medkit",
        label: window.t("fas fa-medkit"),
      },
      {
        value: "fab fa-medrt",
        label: window.t("fab fa-medrt"),
      },
      {
        value: "fab fa-meetup",
        label: window.t("fab fa-meetup"),
      },
      {
        value: "fas fa-meh",
        label: window.t("fas fa-meh"),
      },
      {
        value: "far fa-meh",
        label: window.t("far fa-meh"),
      },
      {
        value: "fas fa-mercury",
        label: window.t("fas fa-mercury"),
      },
      {
        value: "fas fa-microchip",
        label: window.t("fas fa-microchip"),
      },
      {
        value: "fas fa-microphone",
        label: window.t("fas fa-microphone"),
      },
      {
        value: "fas fa-microphone-slash",
        label: window.t("fas fa-microphone-slash"),
      },
      {
        value: "fab fa-microsoft",
        label: window.t("fab fa-microsoft"),
      },
      {
        value: "fas fa-minus",
        label: window.t("fas fa-minus"),
      },
      {
        value: "fas fa-minus-circle",
        label: window.t("fas fa-minus-circle"),
      },
      {
        value: "fas fa-minus-square",
        label: window.t("fas fa-minus-square"),
      },
      {
        value: "far fa-minus-square",
        label: window.t("far fa-minus-square"),
      },
      {
        value: "fab fa-mix",
        label: window.t("fab fa-mix"),
      },
      {
        value: "fab fa-mixcloud",
        label: window.t("fab fa-mixcloud"),
      },
      {
        value: "fab fa-mizuni",
        label: window.t("fab fa-mizuni"),
      },
      {
        value: "fas fa-mobile",
        label: window.t("fas fa-mobile"),
      },
      {
        value: "fas fa-mobile-alt",
        label: window.t("fas fa-mobile-alt"),
      },
      {
        value: "fab fa-modx",
        label: window.t("fab fa-modx"),
      },
      {
        value: "fab fa-monero",
        label: window.t("fab fa-monero"),
      },
      {
        value: "fas fa-money-bill-alt",
        label: window.t("fas fa-money-bill-alt"),
      },
      {
        value: "far fa-money-bill-alt",
        label: window.t("far fa-money-bill-alt"),
      },
      {
        value: "fas fa-moon",
        label: window.t("fas fa-moon"),
      },
      {
        value: "far fa-moon",
        label: window.t("far fa-moon"),
      },
      {
        value: "fas fa-motorcycle",
        label: window.t("fas fa-motorcycle"),
      },
      {
        value: "fas fa-mouse-pointer",
        label: window.t("fas fa-mouse-pointer"),
      },
      {
        value: "fas fa-music",
        label: window.t("fas fa-music"),
      },
      {
        value: "fab fa-napster",
        label: window.t("fab fa-napster"),
      },
      {
        value: "fas fa-neuter",
        label: window.t("fas fa-neuter"),
      },
      {
        value: "fas fa-newspaper",
        label: window.t("fas fa-newspaper"),
      },
      {
        value: "far fa-newspaper",
        label: window.t("far fa-newspaper"),
      },
      {
        value: "fab fa-nintendo-switch",
        label: window.t("fab fa-nintendo-switch"),
      },
      {
        value: "fab fa-node",
        label: window.t("fab fa-node"),
      },
      {
        value: "fab fa-node-js",
        label: window.t("fab fa-node-js"),
      },
      {
        value: "fab fa-npm",
        label: window.t("fab fa-npm"),
      },
      {
        value: "fab fa-ns8",
        label: window.t("fab fa-ns8"),
      },
      {
        value: "fab fa-nutritionix",
        label: window.t("fab fa-nutritionix"),
      },
      {
        value: "fas fa-object-group",
        label: window.t("fas fa-object-group"),
      },
      {
        value: "far fa-object-group",
        label: window.t("far fa-object-group"),
      },
      {
        value: "fas fa-object-ungroup",
        label: window.t("fas fa-object-ungroup"),
      },
      {
        value: "far fa-object-ungroup",
        label: window.t("far fa-object-ungroup"),
      },
      {
        value: "fab fa-odnoklassniki",
        label: window.t("fab fa-odnoklassniki"),
      },
      {
        value: "fab fa-odnoklassniki-square",
        label: window.t("fab fa-odnoklassniki-square"),
      },
      {
        value: "fab fa-opencart",
        label: window.t("fab fa-opencart"),
      },
      {
        value: "fab fa-openid",
        label: window.t("fab fa-openid"),
      },
      {
        value: "fab fa-opera",
        label: window.t("fab fa-opera"),
      },
      {
        value: "fab fa-optin-monster",
        label: window.t("fab fa-optin-monster"),
      },
      {
        value: "fab fa-osi",
        label: window.t("fab fa-osi"),
      },
      {
        value: "fas fa-outdent",
        label: window.t("fas fa-outdent"),
      },
      {
        value: "fab fa-page4",
        label: window.t("fab fa-page4"),
      },
      {
        value: "fab fa-pagelines",
        label: window.t("fab fa-pagelines"),
      },
      {
        value: "fas fa-paint-brush",
        label: window.t("fas fa-paint-brush"),
      },
      {
        value: "fab fa-palfed",
        label: window.t("fab fa-palfed"),
      },
      {
        value: "fas fa-paper-plane",
        label: window.t("fas fa-paper-plane"),
      },
      {
        value: "far fa-paper-plane",
        label: window.t("far fa-paper-plane"),
      },
      {
        value: "fas fa-paperclip",
        label: window.t("fas fa-paperclip"),
      },
      {
        value: "fas fa-paragraph",
        label: window.t("fas fa-paragraph"),
      },
      {
        value: "fas fa-paste",
        label: window.t("fas fa-paste"),
      },
      {
        value: "fab fa-patreon",
        label: window.t("fab fa-patreon"),
      },
      {
        value: "fas fa-pause",
        label: window.t("fas fa-pause"),
      },
      {
        value: "fas fa-pause-circle",
        label: window.t("fas fa-pause-circle"),
      },
      {
        value: "far fa-pause-circle",
        label: window.t("far fa-pause-circle"),
      },
      {
        value: "fas fa-paw",
        label: window.t("fas fa-paw"),
      },
      {
        value: "fab fa-paypal",
        label: window.t("fab fa-paypal"),
      },
      {
        value: "fas fa-pen-square",
        label: window.t("fas fa-pen-square"),
      },
      {
        value: "fas fa-pencil-alt",
        label: window.t("fas fa-pencil-alt"),
      },
      {
        value: "fas fa-percent",
        label: window.t("fas fa-percent"),
      },
      {
        value: "fab fa-periscope",
        label: window.t("fab fa-periscope"),
      },
      {
        value: "fab fa-phabricator",
        label: window.t("fab fa-phabricator"),
      },
      {
        value: "fab fa-phoenix-framework",
        label: window.t("fab fa-phoenix-framework"),
      },
      {
        value: "fas fa-phone",
        label: window.t("fas fa-phone"),
      },
      {
        value: "fas fa-phone-square",
        label: window.t("fas fa-phone-square"),
      },
      {
        value: "fas fa-phone-volume",
        label: window.t("fas fa-phone-volume"),
      },
      {
        value: "fab fa-pied-piper",
        label: window.t("fab fa-pied-piper"),
      },
      {
        value: "fab fa-pied-piper-alt",
        label: window.t("fab fa-pied-piper-alt"),
      },
      {
        value: "fab fa-pied-piper-pp",
        label: window.t("fab fa-pied-piper-pp"),
      },
      {
        value: "fab fa-pinterest",
        label: window.t("fab fa-pinterest"),
      },
      {
        value: "fab fa-pinterest-p",
        label: window.t("fab fa-pinterest-p"),
      },
      {
        value: "fab fa-pinterest-square",
        label: window.t("fab fa-pinterest-square"),
      },
      {
        value: "fas fa-plane",
        label: window.t("fas fa-plane"),
      },
      {
        value: "fas fa-play",
        label: window.t("fas fa-play"),
      },
      {
        value: "fas fa-play-circle",
        label: window.t("fas fa-play-circle"),
      },
      {
        value: "far fa-play-circle",
        label: window.t("far fa-play-circle"),
      },
      {
        value: "fab fa-playstation",
        label: window.t("fab fa-playstation"),
      },
      {
        value: "fas fa-plug",
        label: window.t("fas fa-plug"),
      },
      {
        value: "fas fa-plus",
        label: window.t("fas fa-plus"),
      },
      {
        value: "fas fa-plus-circle",
        label: window.t("fas fa-plus-circle"),
      },
      {
        value: "fas fa-plus-square",
        label: window.t("fas fa-plus-square"),
      },
      {
        value: "far fa-plus-square",
        label: window.t("far fa-plus-square"),
      },
      {
        value: "fas fa-podcast",
        label: window.t("fas fa-podcast"),
      },
      {
        value: "fas fa-pound-sign",
        label: window.t("fas fa-pound-sign"),
      },
      {
        value: "fas fa-power-off",
        label: window.t("fas fa-power-off"),
      },
      {
        value: "fas fa-print",
        label: window.t("fas fa-print"),
      },
      {
        value: "fab fa-product-hunt",
        label: window.t("fab fa-product-hunt"),
      },
      {
        value: "fab fa-pushed",
        label: window.t("fab fa-pushed"),
      },
      {
        value: "fas fa-puzzle-piece",
        label: window.t("fas fa-puzzle-piece"),
      },
      {
        value: "fab fa-python",
        label: window.t("fab fa-python"),
      },
      {
        value: "fab fa-qq",
        label: window.t("fab fa-qq"),
      },
      {
        value: "fas fa-qrcode",
        label: window.t("fas fa-qrcode"),
      },
      {
        value: "fas fa-question",
        label: window.t("fas fa-question"),
      },
      {
        value: "fas fa-question-circle",
        label: window.t("fas fa-question-circle"),
      },
      {
        value: "far fa-question-circle",
        label: window.t("far fa-question-circle"),
      },
      {
        value: "fab fa-quora",
        label: window.t("fab fa-quora"),
      },
      {
        value: "fas fa-quote-left",
        label: window.t("fas fa-quote-left"),
      },
      {
        value: "fas fa-quote-right",
        label: window.t("fas fa-quote-right"),
      },
      {
        value: "fas fa-random",
        label: window.t("fas fa-random"),
      },
      {
        value: "fab fa-ravelry",
        label: window.t("fab fa-ravelry"),
      },
      {
        value: "fab fa-react",
        label: window.t("fab fa-react"),
      },
      {
        value: "fab fa-rebel",
        label: window.t("fab fa-rebel"),
      },
      {
        value: "fas fa-recycle",
        label: window.t("fas fa-recycle"),
      },
      {
        value: "fab fa-red-river",
        label: window.t("fab fa-red-river"),
      },
      {
        value: "fab fa-reddit",
        label: window.t("fab fa-reddit"),
      },
      {
        value: "fab fa-reddit-alien",
        label: window.t("fab fa-reddit-alien"),
      },
      {
        value: "fab fa-reddit-square",
        label: window.t("fab fa-reddit-square"),
      },
      {
        value: "fas fa-redo",
        label: window.t("fas fa-redo"),
      },
      {
        value: "fas fa-redo-alt",
        label: window.t("fas fa-redo-alt"),
      },
      {
        value: "fas fa-registered",
        label: window.t("fas fa-registered"),
      },
      {
        value: "far fa-registered",
        label: window.t("far fa-registered"),
      },
      {
        value: "fab fa-rendact",
        label: window.t("fab fa-rendact"),
      },
      {
        value: "fab fa-renren",
        label: window.t("fab fa-renren"),
      },
      {
        value: "fas fa-reply",
        label: window.t("fas fa-reply"),
      },
      {
        value: "fas fa-reply-all",
        label: window.t("fas fa-reply-all"),
      },
      {
        value: "fab fa-replyd",
        label: window.t("fab fa-replyd"),
      },
      {
        value: "fab fa-resolving",
        label: window.t("fab fa-resolving"),
      },
      {
        value: "fas fa-retweet",
        label: window.t("fas fa-retweet"),
      },
      {
        value: "fas fa-road",
        label: window.t("fas fa-road"),
      },
      {
        value: "fas fa-rocket",
        label: window.t("fas fa-rocket"),
      },
      {
        value: "fab fa-rocketchat",
        label: window.t("fab fa-rocketchat"),
      },
      {
        value: "fab fa-rockrms",
        label: window.t("fab fa-rockrms"),
      },
      {
        value: "fas fa-rss",
        label: window.t("fas fa-rss"),
      },
      {
        value: "fas fa-rss-square",
        label: window.t("fas fa-rss-square"),
      },
      {
        value: "fas fa-ruble-sign",
        label: window.t("fas fa-ruble-sign"),
      },
      {
        value: "fas fa-rupee-sign",
        label: window.t("fas fa-rupee-sign"),
      },
      {
        value: "fab fa-safari",
        label: window.t("fab fa-safari"),
      },
      {
        value: "fab fa-sass",
        label: window.t("fab fa-sass"),
      },
      {
        value: "fas fa-save",
        label: window.t("fas fa-save"),
      },
      {
        value: "far fa-save",
        label: window.t("far fa-save"),
      },
      {
        value: "fab fa-schlix",
        label: window.t("fab fa-schlix"),
      },
      {
        value: "fab fa-scribd",
        label: window.t("fab fa-scribd"),
      },
      {
        value: "fas fa-search",
        label: window.t("fas fa-search"),
      },
      {
        value: "fas fa-search-minus",
        label: window.t("fas fa-search-minus"),
      },
      {
        value: "fas fa-search-plus",
        label: window.t("fas fa-search-plus"),
      },
      {
        value: "fab fa-searchengin",
        label: window.t("fab fa-searchengin"),
      },
      {
        value: "fab fa-sellcast",
        label: window.t("fab fa-sellcast"),
      },
      {
        value: "fab fa-sellsy",
        label: window.t("fab fa-sellsy"),
      },
      {
        value: "fas fa-server",
        label: window.t("fas fa-server"),
      },
      {
        value: "fab fa-servicestack",
        label: window.t("fab fa-servicestack"),
      },
      {
        value: "fas fa-share",
        label: window.t("fas fa-share"),
      },
      {
        value: "fas fa-share-alt",
        label: window.t("fas fa-share-alt"),
      },
      {
        value: "fas fa-share-alt-square",
        label: window.t("fas fa-share-alt-square"),
      },
      {
        value: "fas fa-share-square",
        label: window.t("fas fa-share-square"),
      },
      {
        value: "far fa-share-square",
        label: window.t("far fa-share-square"),
      },
      {
        value: "fas fa-shekel-sign",
        label: window.t("fas fa-shekel-sign"),
      },
      {
        value: "fas fa-shield-alt",
        label: window.t("fas fa-shield-alt"),
      },
      {
        value: "fas fa-ship",
        label: window.t("fas fa-ship"),
      },
      {
        value: "fab fa-shirtsinbulk",
        label: window.t("fab fa-shirtsinbulk"),
      },
      {
        value: "fas fa-shopping-bag",
        label: window.t("fas fa-shopping-bag"),
      },
      {
        value: "fas fa-shopping-basket",
        label: window.t("fas fa-shopping-basket"),
      },
      {
        value: "fas fa-shopping-cart",
        label: window.t("fas fa-shopping-cart"),
      },
      {
        value: "fas fa-shower",
        label: window.t("fas fa-shower"),
      },
      {
        value: "fas fa-sign-in-alt",
        label: window.t("fas fa-sign-in-alt"),
      },
      {
        value: "fas fa-sign-language",
        label: window.t("fas fa-sign-language"),
      },
      {
        value: "fas fa-sign-out-alt",
        label: window.t("fas fa-sign-out-alt"),
      },
      {
        value: "fas fa-signal",
        label: window.t("fas fa-signal"),
      },
      {
        value: "fab fa-simplybuilt",
        label: window.t("fab fa-simplybuilt"),
      },
      {
        value: "fab fa-sistrix",
        label: window.t("fab fa-sistrix"),
      },
      {
        value: "fas fa-sitemap",
        label: window.t("fas fa-sitemap"),
      },
      {
        value: "fab fa-skyatlas",
        label: window.t("fab fa-skyatlas"),
      },
      {
        value: "fab fa-skype",
        label: window.t("fab fa-skype"),
      },
      {
        value: "fab fa-slack",
        label: window.t("fab fa-slack"),
      },
      {
        value: "fab fa-slack-hash",
        label: window.t("fab fa-slack-hash"),
      },
      {
        value: "fas fa-sliders-h",
        label: window.t("fas fa-sliders-h"),
      },
      {
        value: "fab fa-slideshare",
        label: window.t("fab fa-slideshare"),
      },
      {
        value: "fas fa-smile",
        label: window.t("fas fa-smile"),
      },
      {
        value: "far fa-smile",
        label: window.t("far fa-smile"),
      },
      {
        value: "fab fa-snapchat",
        label: window.t("fab fa-snapchat"),
      },
      {
        value: "fab fa-snapchat-ghost",
        label: window.t("fab fa-snapchat-ghost"),
      },
      {
        value: "fab fa-snapchat-square",
        label: window.t("fab fa-snapchat-square"),
      },
      {
        value: "fas fa-snowflake",
        label: window.t("fas fa-snowflake"),
      },
      {
        value: "far fa-snowflake",
        label: window.t("far fa-snowflake"),
      },
      {
        value: "fas fa-sort",
        label: window.t("fas fa-sort"),
      },
      {
        value: "fas fa-sort-alpha-down",
        label: window.t("fas fa-sort-alpha-down"),
      },
      {
        value: "fas fa-sort-alpha-up",
        label: window.t("fas fa-sort-alpha-up"),
      },
      {
        value: "fas fa-sort-amount-down",
        label: window.t("fas fa-sort-amount-down"),
      },
      {
        value: "fas fa-sort-amount-up",
        label: window.t("fas fa-sort-amount-up"),
      },
      {
        value: "fas fa-sort-down",
        label: window.t("fas fa-sort-down"),
      },
      {
        value: "fas fa-sort-numeric-down",
        label: window.t("fas fa-sort-numeric-down"),
      },
      {
        value: "fas fa-sort-numeric-up",
        label: window.t("fas fa-sort-numeric-up"),
      },
      {
        value: "fas fa-sort-up",
        label: window.t("fas fa-sort-up"),
      },
      {
        value: "fab fa-soundcloud",
        label: window.t("fab fa-soundcloud"),
      },
      {
        value: "fas fa-space-shuttle",
        label: window.t("fas fa-space-shuttle"),
      },
      {
        value: "fab fa-speakap",
        label: window.t("fab fa-speakap"),
      },
      {
        value: "fas fa-spinner",
        label: window.t("fas fa-spinner"),
      },
      {
        value: "fab fa-spotify",
        label: window.t("fab fa-spotify"),
      },
      {
        value: "fas fa-square",
        label: window.t("fas fa-square"),
      },
      {
        value: "far fa-square",
        label: window.t("far fa-square"),
      },
      {
        value: "fab fa-stack-exchange",
        label: window.t("fab fa-stack-exchange"),
      },
      {
        value: "fab fa-stack-overflow",
        label: window.t("fab fa-stack-overflow"),
      },
      {
        value: "fas fa-star",
        label: window.t("fas fa-star"),
      },
      {
        value: "far fa-star",
        label: window.t("far fa-star"),
      },
      {
        value: "fas fa-star-half",
        label: window.t("fas fa-star-half"),
      },
      {
        value: "far fa-star-half",
        label: window.t("far fa-star-half"),
      },
      {
        value: "fab fa-staylinked",
        label: window.t("fab fa-staylinked"),
      },
      {
        value: "fab fa-steam",
        label: window.t("fab fa-steam"),
      },
      {
        value: "fab fa-steam-square",
        label: window.t("fab fa-steam-square"),
      },
      {
        value: "fab fa-steam-symbol",
        label: window.t("fab fa-steam-symbol"),
      },
      {
        value: "fas fa-step-backward",
        label: window.t("fas fa-step-backward"),
      },
      {
        value: "fas fa-step-forward",
        label: window.t("fas fa-step-forward"),
      },
      {
        value: "fas fa-stethoscope",
        label: window.t("fas fa-stethoscope"),
      },
      {
        value: "fab fa-sticker-mule",
        label: window.t("fab fa-sticker-mule"),
      },
      {
        value: "fas fa-sticky-note",
        label: window.t("fas fa-sticky-note"),
      },
      {
        value: "far fa-sticky-note",
        label: window.t("far fa-sticky-note"),
      },
      {
        value: "fas fa-stop",
        label: window.t("fas fa-stop"),
      },
      {
        value: "fas fa-stop-circle",
        label: window.t("fas fa-stop-circle"),
      },
      {
        value: "far fa-stop-circle",
        label: window.t("far fa-stop-circle"),
      },
      {
        value: "fab fa-strava",
        label: window.t("fab fa-strava"),
      },
      {
        value: "fas fa-street-view",
        label: window.t("fas fa-street-view"),
      },
      {
        value: "fas fa-strikethrough",
        label: window.t("fas fa-strikethrough"),
      },
      {
        value: "fab fa-stripe",
        label: window.t("fab fa-stripe"),
      },
      {
        value: "fab fa-stripe-s",
        label: window.t("fab fa-stripe-s"),
      },
      {
        value: "fab fa-studiovinari",
        label: window.t("fab fa-studiovinari"),
      },
      {
        value: "fab fa-stumbleupon",
        label: window.t("fab fa-stumbleupon"),
      },
      {
        value: "fab fa-stumbleupon-circle",
        label: window.t("fab fa-stumbleupon-circle"),
      },
      {
        value: "fas fa-subscript",
        label: window.t("fas fa-subscript"),
      },
      {
        value: "fas fa-subway",
        label: window.t("fas fa-subway"),
      },
      {
        value: "fas fa-suitcase",
        label: window.t("fas fa-suitcase"),
      },
      {
        value: "fas fa-sun",
        label: window.t("fas fa-sun"),
      },
      {
        value: "far fa-sun",
        label: window.t("far fa-sun"),
      },
      {
        value: "fab fa-superpowers",
        label: window.t("fab fa-superpowers"),
      },
      {
        value: "fas fa-superscript",
        label: window.t("fas fa-superscript"),
      },
      {
        value: "fab fa-supple",
        label: window.t("fab fa-supple"),
      },
      {
        value: "fas fa-sync",
        label: window.t("fas fa-sync"),
      },
      {
        value: "fas fa-sync-alt",
        label: window.t("fas fa-sync-alt"),
      },
      {
        value: "fas fa-table",
        label: window.t("fas fa-table"),
      },
      {
        value: "fas fa-tablet",
        label: window.t("fas fa-tablet"),
      },
      {
        value: "fas fa-tablet-alt",
        label: window.t("fas fa-tablet-alt"),
      },
      {
        value: "fas fa-tachometer-alt",
        label: window.t("fas fa-tachometer-alt"),
      },
      {
        value: "fas fa-tag",
        label: window.t("fas fa-tag"),
      },
      {
        value: "fas fa-tags",
        label: window.t("fas fa-tags"),
      },
      {
        value: "fas fa-tasks",
        label: window.t("fas fa-tasks"),
      },
      {
        value: "fas fa-taxi",
        label: window.t("fas fa-taxi"),
      },
      {
        value: "fab fa-telegram",
        label: window.t("fab fa-telegram"),
      },
      {
        value: "fab fa-telegram-plane",
        label: window.t("fab fa-telegram-plane"),
      },
      {
        value: "fab fa-tencent-weibo",
        label: window.t("fab fa-tencent-weibo"),
      },
      {
        value: "fas fa-terminal",
        label: window.t("fas fa-terminal"),
      },
      {
        value: "fas fa-text-height",
        label: window.t("fas fa-text-height"),
      },
      {
        value: "fas fa-text-width",
        label: window.t("fas fa-text-width"),
      },
      {
        value: "fas fa-th",
        label: window.t("fas fa-th"),
      },
      {
        value: "fas fa-th-large",
        label: window.t("fas fa-th-large"),
      },
      {
        value: "fas fa-th-list",
        label: window.t("fas fa-th-list"),
      },
      {
        value: "fab fa-themeisle",
        label: window.t("fab fa-themeisle"),
      },
      {
        value: "fas fa-thermometer-empty",
        label: window.t("fas fa-thermometer-empty"),
      },
      {
        value: "fas fa-thermometer-full",
        label: window.t("fas fa-thermometer-full"),
      },
      {
        value: "fas fa-thermometer-half",
        label: window.t("fas fa-thermometer-half"),
      },
      {
        value: "fas fa-thermometer-quarter",
        label: window.t("fas fa-thermometer-quarter"),
      },
      {
        value: "fas fa-thermometer-three-quarters",
        label: window.t("fas fa-thermometer-three-quarters"),
      },
      {
        value: "fas fa-thumbs-down",
        label: window.t("fas fa-thumbs-down"),
      },
      {
        value: "far fa-thumbs-down",
        label: window.t("far fa-thumbs-down"),
      },
      {
        value: "fas fa-thumbs-up",
        label: window.t("fas fa-thumbs-up"),
      },
      {
        value: "far fa-thumbs-up",
        label: window.t("far fa-thumbs-up"),
      },
      {
        value: "fas fa-thumbtack",
        label: window.t("fas fa-thumbtack"),
      },
      {
        value: "fas fa-ticket-alt",
        label: window.t("fas fa-ticket-alt"),
      },
      {
        value: "fas fa-times",
        label: window.t("fas fa-times"),
      },
      {
        value: "fas fa-times-circle",
        label: window.t("fas fa-times-circle"),
      },
      {
        value: "far fa-times-circle",
        label: window.t("far fa-times-circle"),
      },
      {
        value: "fas fa-tint",
        label: window.t("fas fa-tint"),
      },
      {
        value: "fas fa-toggle-off",
        label: window.t("fas fa-toggle-off"),
      },
      {
        value: "fas fa-toggle-on",
        label: window.t("fas fa-toggle-on"),
      },
      {
        value: "fas fa-trademark",
        label: window.t("fas fa-trademark"),
      },
      {
        value: "fas fa-train",
        label: window.t("fas fa-train"),
      },
      {
        value: "fas fa-transgender",
        label: window.t("fas fa-transgender"),
      },
      {
        value: "fas fa-transgender-alt",
        label: window.t("fas fa-transgender-alt"),
      },
      {
        value: "fas fa-trash",
        label: window.t("fas fa-trash"),
      },
      {
        value: "fas fa-trash-alt",
        label: window.t("fas fa-trash-alt"),
      },
      {
        value: "far fa-trash-alt",
        label: window.t("far fa-trash-alt"),
      },
      {
        value: "fas fa-tree",
        label: window.t("fas fa-tree"),
      },
      {
        value: "fab fa-trello",
        label: window.t("fab fa-trello"),
      },
      {
        value: "fab fa-tripadvisor",
        label: window.t("fab fa-tripadvisor"),
      },
      {
        value: "fas fa-trophy",
        label: window.t("fas fa-trophy"),
      },
      {
        value: "fas fa-truck",
        label: window.t("fas fa-truck"),
      },
      {
        value: "fas fa-tty",
        label: window.t("fas fa-tty"),
      },
      {
        value: "fab fa-tumblr",
        label: window.t("fab fa-tumblr"),
      },
      {
        value: "fab fa-tumblr-square",
        label: window.t("fab fa-tumblr-square"),
      },
      {
        value: "fas fa-tv",
        label: window.t("fas fa-tv"),
      },
      {
        value: "fab fa-twitch",
        label: window.t("fab fa-twitch"),
      },
      {
        value: "fab fa-twitter",
        label: window.t("fab fa-twitter"),
      },
      {
        value: "fab fa-twitter-square",
        label: window.t("fab fa-twitter-square"),
      },
      {
        value: "fab fa-typo3",
        label: window.t("fab fa-typo3"),
      },
      {
        value: "fab fa-uber",
        label: window.t("fab fa-uber"),
      },
      {
        value: "fab fa-uikit",
        label: window.t("fab fa-uikit"),
      },
      {
        value: "fas fa-umbrella",
        label: window.t("fas fa-umbrella"),
      },
      {
        value: "fas fa-underline",
        label: window.t("fas fa-underline"),
      },
      {
        value: "fas fa-undo",
        label: window.t("fas fa-undo"),
      },
      {
        value: "fas fa-undo-alt",
        label: window.t("fas fa-undo-alt"),
      },
      {
        value: "fab fa-uniregistry",
        label: window.t("fab fa-uniregistry"),
      },
      {
        value: "fas fa-universal-access",
        label: window.t("fas fa-universal-access"),
      },
      {
        value: "fas fa-university",
        label: window.t("fas fa-university"),
      },
      {
        value: "fas fa-unlink",
        label: window.t("fas fa-unlink"),
      },
      {
        value: "fas fa-unlock",
        label: window.t("fas fa-unlock"),
      },
      {
        value: "fas fa-unlock-alt",
        label: window.t("fas fa-unlock-alt"),
      },
      {
        value: "fab fa-untappd",
        label: window.t("fab fa-untappd"),
      },
      {
        value: "fas fa-upload",
        label: window.t("fas fa-upload"),
      },
      {
        value: "fab fa-usb",
        label: window.t("fab fa-usb"),
      },
      {
        value: "fas fa-user",
        label: window.t("fas fa-user"),
      },
      {
        value: "far fa-user",
        label: window.t("far fa-user"),
      },
      {
        value: "fas fa-user-circle",
        label: window.t("fas fa-user-circle"),
      },
      {
        value: "far fa-user-circle",
        label: window.t("far fa-user-circle"),
      },
      {
        value: "fas fa-user-md",
        label: window.t("fas fa-user-md"),
      },
      {
        value: "fas fa-user-plus",
        label: window.t("fas fa-user-plus"),
      },
      {
        value: "fas fa-user-secret",
        label: window.t("fas fa-user-secret"),
      },
      {
        value: "fas fa-user-times",
        label: window.t("fas fa-user-times"),
      },
      {
        value: "fas fa-users",
        label: window.t("fas fa-users"),
      },
      {
        value: "fab fa-ussunnah",
        label: window.t("fab fa-ussunnah"),
      },
      {
        value: "fas fa-utensil-spoon",
        label: window.t("fas fa-utensil-spoon"),
      },
      {
        value: "fas fa-utensils",
        label: window.t("fas fa-utensils"),
      },
      {
        value: "fab fa-vaadin",
        label: window.t("fab fa-vaadin"),
      },
      {
        value: "fas fa-venus",
        label: window.t("fas fa-venus"),
      },
      {
        value: "fas fa-venus-double",
        label: window.t("fas fa-venus-double"),
      },
      {
        value: "fas fa-venus-mars",
        label: window.t("fas fa-venus-mars"),
      },
      {
        value: "fab fa-viacoin",
        label: window.t("fab fa-viacoin"),
      },
      {
        value: "fab fa-viadeo",
        label: window.t("fab fa-viadeo"),
      },
      {
        value: "fab fa-viadeo-square",
        label: window.t("fab fa-viadeo-square"),
      },
      {
        value: "fab fa-viber",
        label: window.t("fab fa-viber"),
      },
      {
        value: "fas fa-video",
        label: window.t("fas fa-video"),
      },
      {
        value: "fab fa-vimeo",
        label: window.t("fab fa-vimeo"),
      },
      {
        value: "fab fa-vimeo-square",
        label: window.t("fab fa-vimeo-square"),
      },
      {
        value: "fab fa-vimeo-v",
        label: window.t("fab fa-vimeo-v"),
      },
      {
        value: "fab fa-vine",
        label: window.t("fab fa-vine"),
      },
      {
        value: "fab fa-vk",
        label: window.t("fab fa-vk"),
      },
      {
        value: "fab fa-vnv",
        label: window.t("fab fa-vnv"),
      },
      {
        value: "fas fa-volume-down",
        label: window.t("fas fa-volume-down"),
      },
      {
        value: "fas fa-volume-off",
        label: window.t("fas fa-volume-off"),
      },
      {
        value: "fas fa-volume-up",
        label: window.t("fas fa-volume-up"),
      },
      {
        value: "fab fa-vuejs",
        label: window.t("fab fa-vuejs"),
      },
      {
        value: "fab fa-weibo",
        label: window.t("fab fa-weibo"),
      },
      {
        value: "fab fa-weixin",
        label: window.t("fab fa-weixin"),
      },
      {
        value: "fab fa-whatsapp",
        label: window.t("fab fa-whatsapp"),
      },
      {
        value: "fab fa-whatsapp-square",
        label: window.t("fab fa-whatsapp-square"),
      },
      {
        value: "fas fa-wheelchair",
        label: window.t("fas fa-wheelchair"),
      },
      {
        value: "fab fa-whmcs",
        label: window.t("fab fa-whmcs"),
      },
      {
        value: "fas fa-wifi",
        label: window.t("fas fa-wifi"),
      },
      {
        value: "fab fa-wikipedia-w",
        label: window.t("fab fa-wikipedia-w"),
      },
      {
        value: "fas fa-window-close",
        label: window.t("fas fa-window-close"),
      },
      {
        value: "far fa-window-close",
        label: window.t("far fa-window-close"),
      },
      {
        value: "fas fa-window-maximize",
        label: window.t("fas fa-window-maximize"),
      },
      {
        value: "far fa-window-maximize",
        label: window.t("far fa-window-maximize"),
      },
      {
        value: "fas fa-window-minimize",
        label: window.t("fas fa-window-minimize"),
      },
      {
        value: "fas fa-window-restore",
        label: window.t("fas fa-window-restore"),
      },
      {
        value: "far fa-window-restore",
        label: window.t("far fa-window-restore"),
      },
      {
        value: "fab fa-windows",
        label: window.t("fab fa-windows"),
      },
      {
        value: "fas fa-won-sign",
        label: window.t("fas fa-won-sign"),
      },
      {
        value: "fab fa-wordpress",
        label: window.t("fab fa-wordpress"),
      },
      {
        value: "fab fa-wordpress-simple",
        label: window.t("fab fa-wordpress-simple"),
      },
      {
        value: "fab fa-wpbeginner",
        label: window.t("fab fa-wpbeginner"),
      },
      {
        value: "fab fa-wpexplorer",
        label: window.t("fab fa-wpexplorer"),
      },
      {
        value: "fab fa-wpforms",
        label: window.t("fab fa-wpforms"),
      },
      {
        value: "fas fa-wrench",
        label: window.t("fas fa-wrench"),
      },
      {
        value: "fab fa-xbox",
        label: window.t("fab fa-xbox"),
      },
      {
        value: "fab fa-xing",
        label: window.t("fab fa-xing"),
      },
      {
        value: "fab fa-xing-square",
        label: window.t("fab fa-xing-square"),
      },
      {
        value: "fab fa-y-combinator",
        label: window.t("fab fa-y-combinator"),
      },
      {
        value: "fab fa-yahoo",
        label: window.t("fab fa-yahoo"),
      },
      {
        value: "fab fa-yandex",
        label: window.t("fab fa-yandex"),
      },
      {
        value: "fab fa-yandex-international",
        label: window.t("fab fa-yandex-international"),
      },
      {
        value: "fab fa-yelp",
        label: window.t("fab fa-yelp"),
      },
      {
        value: "fas fa-yen-sign",
        label: window.t("fas fa-yen-sign"),
      },
      {
        value: "fab fa-yoast",
        label: window.t("fab fa-yoast"),
      },
      {
        value: "fab fa-youtube",
        label: window.t("fab fa-youtube"),
      },
    ];
    this.categoryData = [];
    this.categoryCount = null;
    this.groupOptions = [];
  }

  componentDidMount = async () => {
    if (!Auth.getAdminFlag() && !window.canAdministerSubject) {
      try {
        setTimeout(() => {
          window.NotificationUtils.showInfo(
            window.t("createSubjectNotAllowed")
          );
        }, 2000);
        this.props.history.push("/");
      } catch (e) {}
    }
    this.setState({
      loading: true,
    });

    window.addEventListener("keypress", this.keyPressed);

    let accountDetails = Auth.getAccountDetails();
    this.setState({
      accountDetails,
    });
    await this.fetchCategories(accountDetails);
    let querySearchParams =
      this.props.history && this.props.history.location.search;
    let params = new URLSearchParams(querySearchParams);
    let subjectId = params.get("subjectId");
    await this.fetchGroups(accountDetails);
    if (subjectId) {
      this.setState({
        subjectId: subjectId,
      });
      try {
        let subjectData = await window.Arena.database.fetchSubjectWithId(
          subjectId
        );
        if (subjectData?.data?.canAdminister) {
          window.canAdministerSubject = true;
        } else {
          window.canAdministerSubject = false;
        }
        subjectData &&
          this.mapSubjectDataToState(subjectData.data, accountDetails);
      } catch (e) {
        console.error(e);
        this.props.history.push("/404");
        this.setState({
          loading: false,
        });
      }
    }
    this.setState({
      loading: false,
      accountDetails,
    });
  };

  fetchGroups = async (accountDetails) => {
    try {
      let offset = this.groupOptions?.length;

      let groupsData = await window.Arena.database.fetchGroupsRelatedToAccount(
        accountDetails.id,
        offset
      );
      groupsData = groupsData && this.createGroupData(groupsData?.items);
      groupsData = this.groupOptions.concat(groupsData);
      this.groupOptions = groupsData;
      this.setState({
        groupOptions: groupsData,
      });
      return groupsData;
    } catch (e) {
      console.error(e);
    }
  };

  createGroupData = (items) => {
    let groupData = [];
    items.map((item) => {
      let data = {
        value: `${item.id}`,
        label: `${item.name}`,
      };
      groupData.push(data);
    });
    return groupData;
  };

  keyPressed = (event) => {
    if (event.key === "Enter") {
      this.handleCreateAndEdit();
    }
  };

  componentWillUnmount() {
    window.removeEventListener("keypress", this.keyPressed);
  }

  makeAndSetCategoryOption = (data) => {
    let categoryOption = [];
    data.map((item, index) => {
      let data = {
        value: item,
        label: item.name,
      };
      categoryOption.push(data);
    });
    this.setState({
      categoryDropdownOption: categoryOption,
    });
    return categoryOption;
  };

  getRadioButtonValue = (type) => {
    switch (type) {
      case FORM_TYPE_MAP.discussion:
        return FORM_TYPE.DISCUSSION;
      case FORM_TYPE_MAP.allocation:
        return FORM_TYPE.ALLOCATION;
      case FORM_TYPE_MAP.choice:
        return FORM_TYPE.CHOICE;
      default:
        return;
    }
  };

  mapSubjectDataToState = (data, accountDetails) => {
    let selectedCategory = {
      value: data.category && data.category.id,
      label: data.category && data.category.name,
    };
    let radioValue = this.getRadioButtonValue(data.type);
    this.setState({
      account: accountDetails && accountDetails.id,
      name: data.name || "",
      description: (data.description && data.description) || "",
      question: (data.question && data.question) || "",
      category: (data.category && data.category.id) || "",
      startDate: moment(data.startDate),
      endDate: moment(data.endDate),
      private: data.private,
      intermediateReport: data.intermediateReport,
      status: data.status || "FINISHED",
      type: data.type || "FORUM",
      categoryDropdown: selectedCategory,
      radioValue,
      loading: false,
      conclusion:
        (data && data.conclusion?.length && data.conclusion) || CONCLUSION_DATA,
      cover: data && data.cover,
      files: data.files,
      conclusionFiles: data.conclusionFiles,
      tags: (data.tags && data.tags) || [],
      sourceAccount: data.account && data.account.id,
    });
    this.categoryId = data.category && data.category.id;
    if (data?.groups?.length > 0) {
      this.mapStateGroups(data.groups);
    }
    if (data.status === SUBJECT_STATUS.FINISHED) {
      this.setState({
        showMoreFields: true,
      });
    }
    if (data.choice) {
      this.setState({
        choice: data.choice,
      });
    }
    if (data.allocation) {
      this.setState({
        allocation: data.allocation,
      });
    }
  };

  mapStateGroups = (groups) => {
    let groupData = [];
    for (let group of groups) {
      let data = {
        value: group.id,
        label: group.name,
      };
      groupData.push(data);
    }
    this.setState({
      groups: groupData,
    });
  };

  fetchCategories = async (accountDetails) => {
    try {
      let offset = this.categoryData?.length;
      let response =
        accountDetails?.id &&
        (await window.Arena.database.fetchCategoriesRelatedToAccount(
          accountDetails.id,
          offset
        ));
      let data = this.categoryData.concat(response?.data?.items);
      let categoryOptions = data && this.makeAndSetCategoryOption(data);
      this.categoryData = data;
      this.categoryCount = response.data.count;
      return categoryOptions;
    } catch (e) {
      console.error(e);
    }
  };

  onClickDownloadReport = () => {
    this.setState({
      showDownloadConfirmModal: true,
    });
  };

  renderControlPanel = () => {
    if (isMobile) {
      return (
        <React.Fragment>
          <ControlPanel
            history={this.props.history}
            placement="top"
            subjectId={this.state.subjectId}
            onClickDownloadReport={
              this.state.subjectId && this.onClickDownloadReport
            }
          />
        </React.Fragment>
      );
    }
    if (isBrowser) {
      return (
        <React.Fragment>
          <ControlPanel
            history={this.props.history}
            placement="left"
            subjectId={this.state.subjectId}
            onClickDownloadReport={
              this.state.subjectId && this.onClickDownloadReport
            }
          />
        </React.Fragment>
      );
    }
  };

  onChangeDropdown = (selectedOption, type) => {
    this.setState({
      [type]: selectedOption,
    });
  };

  onChangeTextfields = (e, type) => {
    this.setState({
      [type]: e.target.value,
    });
  };

  onChangeRadioButton = (e) => {
    this.setState({
      radioValue: e.target.value,
      type: FORM_TYPE_MAP[e.target.value],
    });
  };

  onChangeDate = (date, type) => {
    this.setState({
      [type]: date,
    });
  };

  handleCheckboxChange = (e, key) => {
    if (key === INTERMEDIATE_REPORT) {
      this.setState({
        [key]: e.target.checked,
      });
    } else {
      let value = e.target.checked;
      let status = SUBJECT_STATUS.PUBLISHED;
      if (value) {
        status = SUBJECT_STATUS.FINISHED;
      }
      this.setState({
        status,
        showMoreFields: value,
      });
    }
  };

  onChangeDescription = (text) => {
    this.setState({
      description: text,
    });
  };

  renderTopContent = () => {
    if (!this.state.subjectId) {
      return (
        <React.Fragment>
          {this.renderBackButton()}
          <div className="editSubjectTopMainContainer">
            <Typography
              role="heading"
              aria-level="1"
              variant="h4"
              className="dullWhite"
              style={{ textAlign: "center" }}
              aria-label={window.t("newSubject")}
              tabIndex={0}
            >
              {window.t("newSubject")}
            </Typography>
          </div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        {this.renderBackButton()}
        <div className="editSubjectTopMainContainer">
          <Typography
            role="heading"
            aria-level="1"
            variant="h4"
            className="dullWhite"
            style={{ textAlign: "center" }}
            aria-label={this.state.name && this.state.name}
            tabIndex={0}
          >
            {this.state.name && this.state.name}
          </Typography>
        </div>
      </React.Fragment>
    );
  };

  onUploadComlete = (response, type) => {
    if (!this.state[type]) {
      let files = [];
      files.push(response.data);
      this.setState({
        [type]: files,
      });
    } else {
      let fileCopy = Object.assign([], this.state[type]);
      fileCopy.push(response.data);
      this.setState({
        [type]: fileCopy,
      });
    }
  };

  onUploadBackgroundComlete = (response) => {
    let uploadedURL = response.data && response.data.original;
    let cover = {
      original: uploadedURL && uploadedURL,
      sizes: {
        "720x1080": uploadedURL && uploadedURL,
      },
    };
    this.setState({
      cover: cover,
    });
  };

  handleCreateAndEdit = () => {
    if (this.state.subjectId) {
      this.editSubject();
    } else {
      this.createSubject();
    }
  };

  checkErrors() {
    if (!this.state.name) {
      window.NotificationUtils.showError(window.t("enterName"));
      return true;
    }
    if (!this.state.categoryDropdown) {
      window.NotificationUtils.showError(window.t("enterCategory"));
      return true;
    }
    if (!this.state.description) {
      window.NotificationUtils.showError(window.t("enterDescription"));
      return true;
    }
    if (!this.state.question) {
      window.NotificationUtils.showError(window.t("enterQuestion"));
      return true;
    }
    if (this.state.radioValue === FORM_TYPE.ALLOCATION) {
      let allocation = this.state.allocation;

      for (let item of this.state.allocation.options) {
        if (!item.name) {
          window.NotificationUtils.showError(window.t("enterAllocationField"));
          return true;
        }
      }
      if (!allocation.total) {
        window.NotificationUtils.showError(window.t("enterAllocationTotal"));
        return true;
      }
      if (!allocation.min) {
        window.NotificationUtils.showError(window.t("enterAllocationMinValue"));
        return true;
      }
      if (!allocation.max) {
        window.NotificationUtils.showError(window.t("enterAllocationMaxValue"));
        return true;
      }
      if (!allocation.step) {
        window.NotificationUtils.showError(
          window.t("enterAllocationStepValue")
        );
        return true;
      }
    }

    if (this.state.radioValue === FORM_TYPE.CHOICE) {
      let choice = this.state.choice;

      for (let item of this.state.choice.options) {
        if (!item.name || !item.icon) {
          window.NotificationUtils.showError(window.t("completeChoiceField"));
          return true;
        }
      }
      if (!choice.min) {
        window.NotificationUtils.showError(window.t("enterChoiceMinValue"));
        return true;
      }
      if (!choice.max) {
        window.NotificationUtils.showError(window.t("enterChoiceMaxValue"));
        return true;
      }
    }

    if (this.state.startDate > this.state.endDate) {
      window.NotificationUtils.showError(window.t("startDateValidation"));
      return true;
    }

    // for(let item of this.state.conclusion){
    //     if(!item.title || !item.icon || !item.text1 || !item.text2){
    //         window.NotificationUtils.showError(window.t("completeConclusion"));
    //         return true
    //     }
    // }
    return false;
  }

  checkAndUpdateSubjectStatus = () => {
    if (this.state.status === SUBJECT_STATUS.FINISHED) {
      return;
    }
    let isStartDateAhead = this.checkTheStartDate();
    if (isStartDateAhead) {
      this.setState({
        status: SUBJECT_STATUS.DRAFT,
      });
      return true;
    } else {
      this.setState({
        status: SUBJECT_STATUS.PUBLISHED,
      });
      return false;
    }
  };

  checkTheStartDate = () => {
    let startData = this.state.startDate;
    let today = new Date();
    if (startData.getTime() > today.getTime()) {
      return true;
    } else {
      return false;
    }
  };
  isFormConcluded = () => {
    const date = moment.utc(new Date());
    const endDate = moment.utc(this.state.endDate);
    if (date > endDate) {
      return true;
    } else return false;
  };
  getPageName = () => {
    if (this.state.radioValue === FORM_TYPE.DISCUSSION) {
      if (this.isFormConcluded()) {
        return "conclusion";
      } else {
        return "discuss-subject";
      }
    }
    if (this.state.radioValue === FORM_TYPE.ALLOCATION) {
      if (this.isFormConcluded()) {
        return "allocation-conclusion";
      } else {
        return "allocation";
      }
    }
    if (this.state.radioValue === FORM_TYPE.CHOICE) {
      if (this.isFormConcluded()) {
        return "multiple-choice-conclusion";
      } else {
        return "multi-subject";
      }
    }
  };

  createGroupsData = (groups) => {
    let groupsData = [];
    for (let group of groups) {
      let data = {
        id: group.value,
        name: group.label,
      };
      groupsData.push(data);
    }
    return groupsData;
  };

  createSubject = async () => {
    this.setState({
      checkErrors: true,
    });
    if (this.checkErrors()) {
      return;
    }
    let isDraft = this.checkAndUpdateSubjectStatus();
    window.NotificationUtils.showInfo(window.t("creatingSubject"));
    let dataCopy = Object.assign({}, this.state);
    if (isDraft) {
      dataCopy.status = SUBJECT_STATUS.DRAFT;
    }
    delete dataCopy.radioValue;
    delete dataCopy.loading;
    delete dataCopy.categoryDropdownOption;
    delete dataCopy.categoryData;
    delete dataCopy.categoryDropdown;
    delete dataCopy.checkErrors;
    delete dataCopy.checkErrors;
    delete dataCopy.usergroupDropdown;
    delete dataCopy.addLabelDropdown;
    delete dataCopy.accountDetails;
    delete dataCopy.showMoreFields;
    delete dataCopy.groupOptions;
    delete dataCopy.showDeleteModal;
    delete dataCopy.sourceAccount;

    dataCopy.groups = this.createGroupsData(dataCopy.groups);
    dataCopy.account = this.state.accountDetails.id;

    dataCopy.category =
      this.state.categoryDropdown && this.state.categoryDropdown.value.id;
    let pageName = this.getPageName();
    if (this.state.radioValue === FORM_TYPE.DISCUSSION) {
      dataCopy.allocation && delete dataCopy.allocation;
      dataCopy.choice && delete dataCopy.choice;
    }
    if (this.state.radioValue === FORM_TYPE.ALLOCATION) {
      dataCopy.choice && delete dataCopy.choice;
    }
    if (this.state.radioValue === FORM_TYPE.CHOICE) {
      dataCopy.allocation && delete dataCopy.allocation;
    }
    try {
      let response;
      response = await window.Arena.database.saveSubject(dataCopy);
      window.NotificationUtils.showSuccess(window.t("subjectCreatedSuccess"));
      this.props.history.replace(
        `/${this.state.accountDetails.slug}/${pageName}?subjectId=${response.data.id}`
      );
      this.setState({
        checkErrors: false,
      });
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError(window.t("somethingWentWrong"));
      this.setState({
        checkErrors: false,
      });
    }
  };

  editSubject = async () => {
    this.setState({
      checkErrors: true,
    });
    if (this.checkErrors()) {
      return;
    }
    window.NotificationUtils.showInfo(window.t("editingSubject"));
    let dataCopy = Object.assign({}, this.state);

    delete dataCopy.radioValue;
    delete dataCopy.loading;
    delete dataCopy.categoryDropdownOption;
    delete dataCopy.categoryData;
    delete dataCopy.categoryDropdown;
    delete dataCopy.checkErrors;
    delete dataCopy.subjectId;
    delete dataCopy.checkErrors;
    delete dataCopy.accountDetails;
    delete dataCopy.showMoreFields;
    delete dataCopy.groupOptions;
    delete dataCopy.showDeleteModal;
    delete dataCopy.sourceAccount;

    dataCopy.groups = this.createGroupsData(dataCopy.groups);
    dataCopy.allocation && delete dataCopy.allocation.unit;
    dataCopy.account = this.state.sourceAccount;

    let pageName = this.getPageName();
    if (this.state.radioValue === FORM_TYPE.DISCUSSION) {
      dataCopy.allocation && delete dataCopy.allocation;
      dataCopy.choice && delete dataCopy.choice;
    }
    if (this.state.radioValue === FORM_TYPE.ALLOCATION) {
      dataCopy.choice && delete dataCopy.choice;
    }
    if (this.state.radioValue === FORM_TYPE.CHOICE) {
      dataCopy.allocation && delete dataCopy.allocation;
    }
    try {
      let response;
      response = await window.Arena.database.editSubject(
        dataCopy,
        this.state.subjectId
      );
      window.NotificationUtils.showSuccess(window.t("subjectEditedSuccess"));
      this.setState({
        checkErrors: false,
      });
      this.props.history.replace(
        `/${this.state.accountDetails.slug}/${pageName}?subjectId=${response.data.id}`
      );
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError(window.t("somethingWentWrong"));
      this.setState({
        checkErrors: false,
      });
    }
  };

  deleteSubject = async () => {
    window.NotificationUtils.showInfo(window.t("deletingSubject"));

    try {
      let subjectId = this.state.subjectId && this.state.subjectId;
      await window.Arena.database.deleteSubject(subjectId);
      window.NotificationUtils.showSuccess(window.t("subjectDeletedSuccess"));
      this.props.history.replace(
        `/${this.state.accountDetails.slug}/category-discussion?categoryId=${this.categoryId}`
      );
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError(window.t("somethingWentWrong"));
    }
  };

  renderConstFieldsAccToFormType = () => {
    let formType = this.state.radioValue;

    switch (formType) {
      case FORM_TYPE.ALLOCATION:
        return this.renderConstantAllocationFields();
      case FORM_TYPE.CHOICE:
        return this.renderConstantMultipleChoiceFileds();
      case FORM_TYPE.DISCUSSION:
        return null;

      default:
        return null;
    }
  };

  isAllocationSelected = () => {
    if (this.state.radioValue === FORM_TYPE.ALLOCATION) {
      return true;
    } else {
      return false;
    }
  };

  renderConstantAllocationFields = () => {
    return (
      <React.Fragment>
        <div className="fullWidth">
          <Button
            onClick={() => this.addOption(FORM_TYPE.ALLOCATION)}
            id="addChoiceOption"
            tabIndex={0}
            aria-label={window.t("addOption")}
            fullWidth={true}
            className="reverse"
          >
            <Typography
              variant="body1"
              className="blueColor bold textAlignEnd "
            >
              {window.t("addOption")}
            </Typography>
          </Button>
          <div className="displayFlex justContentFlexEnd reverse allocationFieldsContainer">
            <div className="editSubjectTextContainer allocationConstantField">
              <Typography
                variant="body1"
                className="dullWhite bold textAlignEnd reverse"
              >
                {window.t("step")}
              </Typography>
              <div className="textfieldContainer">
                <ArenaInput
                  value={this.state.allocation && this.state.allocation.step}
                  onChange={(e) => {
                    this.onChangeChoiceAndAllocationTextfields(
                      e,
                      "step",
                      FORM_TYPE.ALLOCATION
                    );
                  }}
                  error={
                    this.state.checkErrors &&
                    this.isAllocationSelected() &&
                    this.errorInStep()
                  }
                  helperText={
                    this.state.checkErrors &&
                    this.errorInStep() &&
                    window.t("invalidStep")
                  }
                  ariaLabelText={"step value of allocation"}
                />
              </div>
            </div>
            <div
              className="editSubjectTextContainer allocationConstantField"
              style={{ width: "10%" }}
            >
              <Typography
                variant="body1"
                className="dullWhite bold textAlignEnd reverse"
              >
                {window.t("total")}
              </Typography>
              <div className="textfieldContainer">
                <ArenaInput
                  value={this.state.allocation && this.state.allocation.total}
                  onChange={(e) => {
                    this.onChangeChoiceAndAllocationTextfields(
                      e,
                      "total",
                      FORM_TYPE.ALLOCATION
                    );
                  }}
                  error={
                    this.state.checkErrors &&
                    this.isAllocationSelected() &&
                    this.errorInTotal()
                  }
                  helperText={
                    this.state.checkErrors &&
                    this.errorInTotal() &&
                    window.t("invalidTotal")
                  }
                  ariaLabelText={"total value of allocation"}
                />
              </div>
            </div>
            <div className="editSubjectTextContainer allocationConstantField">
              <Typography
                variant="body1"
                className="dullWhite bold textAlignEnd reverse"
              >
                {window.t("min")}
              </Typography>
              <div className="textfieldContainer">
                <ArenaInput
                  value={this.state.allocation && this.state.allocation.min}
                  onChange={(e) => {
                    this.onChangeChoiceAndAllocationTextfields(
                      e,
                      "min",
                      FORM_TYPE.ALLOCATION
                    );
                  }}
                  error={
                    this.state.checkErrors &&
                    this.isAllocationSelected() &&
                    this.errorInAllocationMin()
                  }
                  helperText={
                    this.state.checkErrors &&
                    this.errorInAllocationMin() &&
                    window.t("invalidAllocationMin")
                  }
                  ariaLabelText={"minimum value of allocation"}
                />
              </div>
            </div>
            <div className="editSubjectDropdownContainer allocationConstantField">
              <Typography
                variant="body1"
                className="dullWhite bold textAlignEnd reverse"
              >
                {window.t("max")}
              </Typography>
              <div className="textfieldContainer">
                <ArenaInput
                  value={this.state.allocation && this.state.allocation.max}
                  onChange={(e) => {
                    this.onChangeChoiceAndAllocationTextfields(
                      e,
                      "max",
                      FORM_TYPE.ALLOCATION
                    );
                  }}
                  error={
                    this.state.checkErrors &&
                    this.isAllocationSelected() &&
                    this.errorInAllocationMax()
                  }
                  helperText={
                    this.state.checkErrors &&
                    this.errorInAllocationMax() &&
                    window.t("invalidAllocationMax")
                  }
                  ariaLabelText={"maximum value of allocation"}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  isChoiceSelected = () => {
    if (this.state.radioValue === FORM_TYPE.CHOICE) {
      return true;
    } else {
      return false;
    }
  };

  renderConstantMultipleChoiceFileds = () => {
    return (
      <React.Fragment>
        <div className="fullWidth">
          <Button
            onClick={() => this.addOption(FORM_TYPE.CHOICE)}
            id="addChoiceOption"
            tabIndex={0}
            aria-label={window.t("addOption")}
            fullWidth={true}
            className="reverse"
          >
            <Typography variant="body1" className="blueColor bold textAlignEnd">
              {window.t("addOption")}
            </Typography>
          </Button>
          <div className="displayFlex justContentFlexEnd reverse allocationFieldsContainer">
            <div className="editSubjectTextContainer subjectFieldFormWidth">
              <Typography
                variant="body1"
                className="dullWhite bold textAlignEnd reverse"
              >
                {window.t("min")}
              </Typography>
              <div className="textfieldContainer">
                <ArenaInput
                  value={this.state.choice && this.state.choice.min}
                  onChange={(e) => {
                    this.onChangeChoiceAndAllocationTextfields(
                      e,
                      "min",
                      FORM_TYPE.CHOICE
                    );
                  }}
                  error={
                    this.state.checkErrors &&
                    this.isChoiceSelected() &&
                    this.errorInChoiceMin()
                  }
                  helperText={
                    this.state.checkErrors &&
                    this.errorInChoiceMin() &&
                    window.t("invalidChoiceMin")
                  }
                  ariaLabelText={"minimum value of multi choice subject"}
                />
              </div>
            </div>
            <div className="editSubjectDropdownContainer subjectFieldFormWidth">
              <Typography
                variant="body1"
                className="dullWhite bold textAlignEnd reverse"
              >
                {window.t("max")}
              </Typography>
              <div className="textfieldContainer">
                <ArenaInput
                  value={this.state.choice && this.state.choice.max}
                  onChange={(e) => {
                    this.onChangeChoiceAndAllocationTextfields(
                      e,
                      "max",
                      FORM_TYPE.CHOICE
                    );
                  }}
                  error={
                    this.state.checkErrors &&
                    this.isChoiceSelected() &&
                    this.errorInChoiceMax()
                  }
                  helperText={
                    this.state.checkErrors &&
                    this.errorInChoiceMax() &&
                    window.t("invalidChoiceMax")
                  }
                  ariaLabelText={"maximum value of multi choice subject"}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  addOption = (type) => {
    let dataCopy = Object.assign({}, this.state[type]);
    let newData = {
      name: "",
    };
    dataCopy.options.push(newData);
    this.setState({
      [type]: dataCopy,
    });
  };

  onChangeChoiceAndAllocationTextfields = (e, field, type) => {
    let dataCopy = Object.assign({}, this.state[type]);
    dataCopy[field] = +e.target.value;
    this.setState({
      [type]: dataCopy,
    });
  };

  renderFieldsAccToFormType = () => {
    let formType = this.state.radioValue;

    switch (formType) {
      case FORM_TYPE.ALLOCATION:
        return this.renderAllocationFields();
      case FORM_TYPE.CHOICE:
        return this.renderMultipleChoiceFileds();
      case FORM_TYPE.DISCUSSION:
        return null;

      default:
        return null;
    }
  };

  renderAllocationFields = () => {
    if (!this.state.allocation) {
      let allocation = {
        options: [
          {
            name: "",
          },
          {
            name: "",
          },
        ],
        total: "",
        min: "",
        max: "",
        step: "",
      };
      this.setState({
        allocation: allocation,
      });
    }

    return (
      this.state.allocation &&
      this.state.allocation.options.map((item, index) => {
        return (
          <div className="editSubjectTextAndDropDownContainer reverse">
            <div className="editSubjectTextContainer marginNone subjectFieldFormWidth ">
              <Typography
                variant="body1"
                className="dullWhite bold textAlignEnd reverse"
              >
                {window.t("title")}
              </Typography>
              <div className="textfieldContainer containerWithRemoveIcon">
                <ArenaInput
                  value={
                    this.state.allocation &&
                    this.state.allocation.options[index].name
                  }
                  onChange={(e) => {
                    this.onChangeOptionsTextField(
                      e,
                      index,
                      FORM_TYPE.ALLOCATION
                    );
                  }}
                  ariaLabelText={`title ${index + 1}`}
                />
                {index > 1
                  ? this.renderRemoveButton(index, FORM_TYPE.ALLOCATION)
                  : null}
              </div>
            </div>
          </div>
        );
      })
    );
  };

  renderMultipleChoiceFileds = () => {
    if (!this.state.choice) {
      let choice = {
        options: [
          {
            name: "",
          },
          {
            name: "",
          },
        ],
        min: 0,
        max: 0,
      };
      this.setState({
        choice: choice,
      });
    }

    return (
      this.state.choice &&
      this.state.choice.options.map((item, index) => {
        return (
          <div className="editSubjectTextAndDropDownContainer reverse">
            <div className="editSubjectTextContainer subjectFieldFormWidth">
              <Typography
                variant="body1"
                className="dullWhite bold textAlignEnd reverse"
              >
                {window.t("title")}
              </Typography>
              <div className="textfieldContainer containerWithRemoveIcon">
                <ArenaInput
                  value={
                    this.state.choice && this.state.choice.options[index].name
                  }
                  onChange={(e) => {
                    this.onChangeOptionsTextField(e, index, FORM_TYPE.CHOICE);
                  }}
                  ariaLabelText={`title ${index + 1}`}
                />
                {index > 1
                  ? this.renderRemoveButton(index, FORM_TYPE.CHOICE)
                  : null}
              </div>
            </div>
            <div className="editSubjectDropdownContainer subjectFieldFormWidth">
              <Typography
                variant="body1"
                className="dullWhite bold textAlignEnd reverse"
              >
                {window.t("icon")}
              </Typography>
              <div className="dropdownContainer multipleChoiceDropDown">
                <ArenaDropdown
                  components={{ Option: CustomOption }}
                  options={this.IMAGE_DROPDOWN}
                  selectedOption={this.getDropdownValue(
                    this.state.choice.options[index].icon
                  )}
                  onChange={(selectedOption) => {
                    this.onChangeIconDropdown(
                      selectedOption,
                      index,
                      FORM_TYPE.CHOICE
                    );
                  }}
                  placeholder={window.t("select")}
                />
              </div>
            </div>
          </div>
        );
      })
    );
  };
  handleAddChip = (chip) => {
    var labels = Object.assign([], this.state.tags);
    for (var label in labels) {
      if (label === chip) {
        window.NotificationUtils.showError("Name already exists!");
        return;
      }
    }
    labels.push(chip);
    this.setState({
      tags: labels,
    });
  };

  handleDeleteChip = (chip, i) => {
    var labels = Object.assign([], this.state.tags);
    var filtered = labels.filter(function (value, index) {
      return index !== i;
    });
    this.setState({
      tags: filtered,
    });
  };
  onChangeOptionsTextField = (e, index, type) => {
    let dataCopy = Object.assign({}, this.state[type]);
    dataCopy.options[index].name = e.target.value;
    this.setState({
      [type]: dataCopy,
    });
  };

  onChangeIconDropdown = (selectedOption, index, type) => {
    let dataCopy = Object.assign({}, this.state[type]);
    dataCopy.options[index].icon = selectedOption.value;
    this.setState({
      [type]: dataCopy,
    });
  };

  getDropdownValue = (icon) => {
    let iconOption = {
      value: icon,
      label: (
        <div>
          <Icon className={`${icon} dropDownIcon`}></Icon>
          <span>{icon}</span>{" "}
        </div>
      ),
    };
    return iconOption;
  };

  renderRemoveButton = (index, type) => {
    return (
      <React.Fragment>
        <div id="removeButtonContainer">
          <IconButton
            aria-label={`delete title ${index + 1}`}
            onClick={() => this.removeChoiceOption(index, type)}
          >
            <RemoveCircleOutlineIcon style={{ color: "#65D2FC" }} />
          </IconButton>
        </div>
      </React.Fragment>
    );
  };

  removeChoiceOption = (index, type) => {
    if (index > 0) {
      let dataCopy = Object.assign({}, this.state[type]);
      dataCopy.options.splice(index, 1);
      this.setState({
        [type]: dataCopy,
      });
    }
  };

  onClickDeleteAttachments = (file, files, key) => {
    let index = files.indexOf(file);
    if (index === -1) {
      return;
    }
    files.splice(index, 1);
    if (key === "conclusionFiles") {
      this.setState({
        conclusionFiles: files,
      });
    } else {
      this.setState({
        files,
      });
    }
  };

  renderImage = (file, files, key) => {
    return (
      <div id="imageUploaderContainer" className="reverse">
        <div className="dropdownContainer imageUploadContainer">
          <ArenaUploader fileURL={file.url} />
        </div>
        <Typography
          variant="body1"
          id="imageUploaderName"
          className="marginLeft8 dullWhite bold textAlignEnd"
        >
          {file.name}
        </Typography>
        <CommentActionButton
          aria-label={"Delete"}
          value={Delete}
          onClick={() => this.onClickDeleteAttachments(file, files, key)}
        />
      </div>
    );
  };

  renderUploadedImages = (files, key) => {
    if (!files || files.length < 1) {
      return;
    }
    return files.map((item) => {
      return this.renderImage(item, files, key);
    });
  };

  closeDownloadConfirmModal = () => {
    this.setState({
      showDownloadConfirmModal: false,
    });
  };
  renderDownloadConfirmModal = () => {
    return (
      <ArenaModal
        text={`${window.t("downloadReportText")} ${this.state.name} ?`}
        openArenaModal={this.state.showDownloadConfirmModal}
        handleArenaModalClose={this.closeDownloadConfirmModal}
        secondaryButtonText={window.t("cancel")}
        onClickSecondaryButton={this.closeDownloadConfirmModal}
        onClickPrimaryButton={this.getDownloadReport}
        headerTitle={window.t("downloadReport")}
      />
    );
  };

  getDownloadReport = async () => {
    let result = await window.Arena.database.downloadDiscussSubjectReport(
      this.state.subjectId
    );
    FileSaver.saveAs(result.data, `report-${this.state.subjectId}.xlsx`);
    this.setState({
      showDownloadConfirmModal: false,
    });
  };

  renderImageSizeText = (size) => {
    return (
      <div style={{ marginTop: "26px", padding: "0 52px" }}>
        <Typography variant="body1" className="dullWhite textAlignCenter">
          {window.t(size)}
        </Typography>
      </div>
    );
  };

  renderBottomContent = () => {
    let bottomContentHeading = this.state.subjectId
      ? window.t("editSubject")
      : window.t("createSubject");
    return (
      <React.Fragment>
        <div className="editSubjectBottomMainContainer">
          <div>
            <Typography
              role="heading"
              aria-level="2"
              variant="h5"
              className="redColor bold"
              style={{ textAlign: "center" }}
              aria-label={bottomContentHeading}
              tabIndex={0}
            >
              {bottomContentHeading}
            </Typography>
          </div>
          <div className="displayFlexEnd reverse">
            <Typography variant="body1" className="dullWhite">
              *{window.t("mandatoryField")}
            </Typography>
          </div>
          <div className="editSubjectTextAndDropDownContainer reverse">
            <div className="editSubjectTextContainer subjectFieldFormWidth">
              <Typography
                variant="body1"
                className="dullWhite bold textAlignEnd reverse"
              >
                *{window.t("theSubject")}
              </Typography>
              <div className="textfieldContainer">
                <ArenaInput
                  value={this.state.name}
                  onChange={(e) => {
                    this.onChangeTextfields(e, "name");
                  }}
                  error={this.state.checkErrors && this.errorInName()}
                  helperText={
                    this.state.checkErrors &&
                    this.errorInName() &&
                    "Invalid name"
                  }
                  ariaLabelText={window.t("subjectName")}
                />
              </div>
            </div>
            <div className="editSubjectDropdownContainer subjectFieldFormWidth">
              <Typography
                variant="body1"
                className="dullWhite bold textAlignEnd reverse"
              >
                *{window.t("category")}
              </Typography>
              <div className="dropdownContainer">
                <ArenaDropdown
                  options={
                    this.state.categoryDropdownOption &&
                    this.state.categoryDropdownOption
                  }
                  selectedOption={this.state.categoryDropdown}
                  onChange={(selectedOption) => {
                    this.onChangeDropdown(selectedOption, "categoryDropdown");
                  }}
                  placeholder={window.t("select")}
                  loadOptions={() =>
                    this.fetchCategories(this.state.accountDetails)
                  }
                  isAsync={true}
                />
              </div>
            </div>
          </div>
          <div className="displayFlexEnd reverse">
            <div className="richTextFieldContainer">
              <Typography
                variant="body1"
                style={{ marginBottom: "16px" }}
                className="dullWhite bold textAlignEnd reverse"
              >
                {window.t("description")}
              </Typography>
              <RichTextField
                onChange={this.onChangeDescription}
                content={this.state.description && this.state.description}
              />
            </div>
          </div>

          <div className="displayFlexEnd reverse">
            <div className="multilineTextFieldContainer">
              <Typography
                variant="body1"
                style={{ marginBottom: "16px" }}
                className="dullWhite bold textAlignEnd reverse"
              >
                {window.t("theQuestion")}
              </Typography>
              <ArenaInput
                multiline={true}
                rows={6}
                value={this.state.question}
                onChange={(e) => {
                  this.onChangeTextfields(e, "question");
                }}
                ariaLabelText={window.t("subjectQuestion")}
              />
            </div>
          </div>
          <div style={{ marginTop: "36px" }}>
            <Typography
              tabIndex={0}
              aria-label={window.t("type")}
              variant="body1"
              className="dullWhite bold textAlignEnd reverse"
            >
              *{window.t("type")}
            </Typography>
            <div
              className="dropdownContainer radioButtonContainer"
              id="radioButtonSubjects"
            >
              <RadioGroup
                aria-label={window.t("type")}
                value={this.state.radioValue}
                onChange={this.onChangeRadioButton}
              >
                <FormControlLabel
                  value={FORM_TYPE.ALLOCATION}
                  control={<Radio size="small" />}
                  label={window.t("allocation")}
                />
                <FormControlLabel
                  value={FORM_TYPE.CHOICE}
                  control={<Radio size="small" />}
                  label={window.t("multipleChoice")}
                />
                <FormControlLabel
                  value={FORM_TYPE.DISCUSSION}
                  control={<Radio size="small" />}
                  label={window.t("discussion")}
                />
              </RadioGroup>
            </div>
          </div>

          {this.renderFieldsAccToFormType()}

          <div className="editSubjectTextAndDropDownContainer reverse">
            {this.renderConstFieldsAccToFormType()}
          </div>
          <div className="editSubjectTextAndDropDownContainer justifyContentFlexStartRTL datePickerContainer">
            <div
              className="editSubjectTextContainer subjectFieldFormWidth"
              id="startDatePickerContainer"
            >
              <Typography
                variant="body1"
                className="dullWhite bold textAlignEnd reverse"
              >
                *{window.t("startDate")}
              </Typography>
              <div className="dropdownContainer">
                <ArenaDatePicker
                  id="startDropDownContainer"
                  value={this.state.startDate}
                  onChange={(date) => {
                    this.onChangeDate(date, "startDate");
                  }}
                  ariaLabelText={window.t("startDate")}
                />
              </div>
            </div>
            <div
              className="editSubjectDropdownContainer subjectFieldFormWidth"
              id="endDatePickerContainer"
            >
              <Typography
                variant="body1"
                className="dullWhite bold textAlignEnd reverse"
              >
                *{window.t("endDate")}
              </Typography>
              <div className="dropdownContainer">
                <ArenaDatePicker
                  id="endDropDownContainer"
                  value={this.state.endDate}
                  onChange={(date) => {
                    this.onChangeDate(date, "endDate");
                  }}
                  minDate={this.state.startDate}
                  ariaLabelText={window.t("endDate")}
                />
              </div>
            </div>
          </div>

          <div className="editSubjectTextAndDropDownContainer reverse">
            <div className="editSubjectTextContainer subjectFieldFormWidth">
              <Typography
                variant="body1"
                className="dullWhite bold textAlignEnd reverse"
              >
                {window.t("userGroup")}
              </Typography>
              <div id="userGroupDropDown" className="dropdownContainer">
                <ArenaMultiSelect
                  id="userGroupsDropDown"
                  options={this.state.groupOptions}
                  onChange={(selectedOption) => {
                    this.onChangeDropdown(selectedOption, "groups");
                  }}
                  placeholder={window.t("select")}
                  selectedOptions={this.state.groups}
                  loadOptions={() =>
                    this.fetchGroups(this.state.accountDetails)
                  }
                  isAsync={true}
                />
              </div>
            </div>
            <div className="editSubjectDropdownContainer subjectFieldFormWidth">
              <Typography
                variant="body1"
                className="dullWhite bold textAlignEnd reverse"
              >
                {window.t("addLabel")}
              </Typography>
              <div id="addLabelDropdown" className="dropdownContainer">
                <ChipInput
                  variant="outlined"
                  disableUnderline={true}
                  className="arenaInputField arenaChipInput"
                  id="addLabels"
                  placeholder={window.t("hitEnterToAdd")}
                  value={this.state.tags}
                  onAdd={(chip) => this.handleAddChip(chip)}
                  onDelete={(chip, index) => this.handleDeleteChip(chip, index)}
                ></ChipInput>
              </div>
            </div>
          </div>

          <div className="editSubjectTextAndDropDownContainer reverse">
            <div className="editSubjectTextContainer subjectFieldFormWidth">
              <Typography
                variant="body1"
                className="dullWhite bold textAlignEnd reverse"
              >
                {window.t("attachments")}
              </Typography>
              <div className="dropdownContainer">
                <ArenaUploader
                  isMultiple={true}
                  docUploader={true}
                  extensions={["pdf", "jpeg", "jpg", "png"]}
                  onUploadComplete={(response) => {
                    this.onUploadComlete(response, "files");
                  }}
                  ariaLabelText={window.t("attachments")}
                />
              </div>
              {this.renderImageSizeText("attachmentSize")}
              {this.renderUploadedImages(this.state.files)}
            </div>
            <div className="editSubjectDropdownContainer subjectFieldFormWidth">
              <Typography
                variant="body1"
                className="dullWhite bold textAlignEnd reverse"
              >
                {window.t("backgroundCover")}
              </Typography>
              <div className="dropdownContainer">
                <ArenaUploader
                  isMultiple={false}
                  fileURL={this.state.cover && this.state.cover.original}
                  extensions={["jpg", "jpeg", "png"]}
                  onUploadComplete={(response) => {
                    this.onUploadBackgroundComlete(response, "backgroundCover");
                  }}
                  ariaLabelText={window.t("backgroundCover")}
                />
              </div>
              {this.renderImageSizeText("backgroundCoverSize")}
            </div>
          </div>

          <div className="editSubjectCheckBoxContainer">
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.showMoreFields}
                  onChange={this.handleCheckboxChange}
                  name="checkedB"
                  color="primary"
                />
              }
              label={window.t("publishConclusion")}
            />
            {this.state.type !== FORM_TYPE_MAP.discussion && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.intermediateReport}
                    onChange={(e) =>
                      this.handleCheckboxChange(e, INTERMEDIATE_REPORT)
                    }
                    name="checkedIntermediateReport"
                    color="primary"
                  />
                }
                label={window.t("showSubTotalResult")}
              />
            )}
          </div>
          {this.state.showMoreFields && this.renderFieldsForPublicConclusion()}

          <div className="editSubjectButtonContainer reverse">
            {this.state.subjectId && (
              <div id="deleteButtonContainer">
                <ArenaButton
                  text={window.t("delete")}
                  onClick={this.onClickDelete}
                />
              </div>
            )}
            <SendButton
              extraClass="commentMainButton"
              text={window.t("publishSubject")}
              size={"small"}
              onClick={this.handleCreateAndEdit}
            />
          </div>
        </div>
        {this.renderDeleteModal()}
        {this.renderControlPanel()}
        {this.state.showDownloadConfirmModal &&
          this.renderDownloadConfirmModal()}
      </React.Fragment>
    );
  };

  onClickDelete = () => {
    this.setState({
      showDeleteModal: true,
    });
  };
  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
    });
  };

  renderDeleteModal = () => {
    return (
      <ArenaModal
        text={`${window.t("deleteText")} ${this.state.name} ?`}
        openArenaModal={this.state.showDeleteModal}
        handleArenaModalClose={this.closeDeleteModal}
        secondaryButtonText={window.t("cancel")}
        primaryButtonText={window.t("approve")}
        onClickSecondaryButton={this.closeDeleteModal}
        onClickPrimaryButton={this.deleteSubject}
        headerTitle={window.t("deleteSubject")}
      />
    );
  };

  addConclusion = () => {
    let conclusioCopy = Object.assign([], this.state.conclusion);
    let data = {
      title: "",
      icon: null,
      text1: "",
      text2: "",
    };
    conclusioCopy.push(data);
    this.setState({
      conclusion: conclusioCopy,
    });
  };

  onChangeConclusionTextfields = (e, index, type) => {
    let conclusioCopy = Object.assign([], this.state.conclusion);
    if (type === "text1" || type === "text2") {
      conclusioCopy[index][type] = e;
      this.setState({
        conclusion: conclusioCopy,
      });
    } else {
      conclusioCopy[index][type] = e.target.value;
      this.setState({
        conclusion: conclusioCopy,
      });
    }
  };

  onChangeConclusionDropdown = (selectedOption, index, type) => {
    let conclusioCopy = Object.assign([], this.state.conclusion);
    conclusioCopy[index].icon = selectedOption.value;

    this.setState({
      conclusion: conclusioCopy,
    });
  };

  renderAddConclusionButton = () => {
    return (
      <div onClick={this.addConclusion} id="addConclusionOption">
        <Typography
          variant="body1"
          className="blueColor bold textAlignEnd reverse"
        >
          {window.t("addConclusion")}
        </Typography>
      </div>
    );
  };

  removeConclusion = (index) => {
    let conclusioCopy = Object.assign([], this.state.conclusion);
    conclusioCopy.splice(index, 1);

    this.setState({
      conclusion: conclusioCopy,
    });
  };

  renderConclusionRemove = (index) => {
    return (
      <React.Fragment>
        <div>
          <IconButton onClick={() => this.removeConclusion(index)}>
            <RemoveCircleOutlineIcon style={{ color: "#65D2FC" }} />
          </IconButton>
        </div>
      </React.Fragment>
    );
  };

  renderFieldsForPublicConclusion = () => {
    return (
      this.state.conclusion &&
      this.state.conclusion.map((item, index) => {
        return (
          <React.Fragment>
            <div id="conclusionHeadingContainer">
              <Typography
                role="heading"
                aria-level="3"
                variant="h5"
                className="redColor bold textAlignEnd"
              >
                {window.t("conclusion")}
              </Typography>
              {index > 0 ? this.renderConclusionRemove(index) : null}
            </div>
            <div className="displayFlexEnd reverse">
              <div className="multilineTextFieldContainer richTextFieldContainer richTextConclusionContainer">
                <Typography
                  variant="body1"
                  style={{ marginBottom: "16px" }}
                  className="dullWhite bold textAlignEnd reverse"
                >
                  {window.t("description")}
                </Typography>
                <RichTextField
                  onChange={(e) =>
                    this.onChangeConclusionTextfields(e, index, "text1")
                  }
                  content={(item.text1 && item.text1) || ""}
                />
              </div>
            </div>

            <div className="editSubjectTextAndDropDownContainer reverse">
              <div className="editSubjectTextContainer subjectFieldFormWidth">
                <Typography
                  variant="body1"
                  className="dullWhite bold textAlignEnd reverse"
                >
                  {window.t("title")}
                </Typography>
                <div className="textfieldContainer">
                  <ArenaInput
                    value={item.title || ""}
                    onChange={(e) => {
                      this.onChangeConclusionTextfields(e, index, "title");
                    }}
                    ariaLabelText={window.t("title")}
                  />
                </div>
              </div>
              <div className="editSubjectDropdownContainer subjectFieldFormWidth">
                <Typography
                  variant="body1"
                  className="dullWhite bold textAlignEnd reverse"
                >
                  {window.t("icon")}
                </Typography>

                <div id="dropDown" className="dropdownContainer">
                  <ArenaDropdown
                    components={{ Option: CustomOption }}
                    options={this.IMAGE_DROPDOWN}
                    selectedOption={this.getDropdownValue(
                      this.state.conclusion[index].icon
                    )}
                    onChange={(selectedOption) => {
                      this.onChangeConclusionDropdown(
                        selectedOption,
                        index,
                        "icon"
                      );
                    }}
                    placeholder={window.t("select")}
                  />
                </div>
              </div>
            </div>

            <div className="displayFlexEnd reverse">
              <div className="multilineTextFieldContainer richTextFieldContainer">
                <Typography
                  variant="body1"
                  style={{ marginBottom: "16px" }}
                  className="dullWhite bold textAlignEnd reverse"
                >
                  {window.t("content")}
                </Typography>
                <RichTextField
                  onChange={(e) => {
                    this.onChangeConclusionTextfields(e, index, "text2");
                  }}
                  content={(item.text2 && item.text2) || ""}
                />
              </div>
            </div>

            <div className="displayFlexEnd reverse">
              <div className="multilineTextFieldContainer">
                <Typography
                  variant="body1"
                  style={{ marginBottom: "16px" }}
                  className="dullWhite bold textAlignEnd reverse"
                >
                  {window.t("attachedFile")}
                </Typography>
                <ArenaUploader
                  isMultiple={true}
                  docUploader={true}
                  extensions={["pdf", "jpeg", "jpg", "png"]}
                  onUploadComplete={(response) => {
                    this.onUploadComlete(response, "conclusionFiles");
                  }}
                  ariaLabelText={window.t("attachedFile")}
                />
                {this.renderImageSizeText("attachmentSize")}

                {this.renderUploadedImages(
                  this.state.conclusionFiles,
                  "conclusionFiles"
                )}
              </div>
            </div>
            {this.renderAddConclusionButton()}
          </React.Fragment>
        );
      })
    );
  };

  errorInName = () => {
    return !this.state.name;
  };

  errorInStep = () => {
    return !this.state.allocation.step;
  };

  errorInTotal = () => {
    return !this.state.allocation.total;
  };

  errorInAllocationMin = () => {
    return !this.state.allocation.min;
  };

  errorInAllocationMax = () => {
    return !this.state.allocation.max;
  };

  errorInChoiceMin = () => {
    return !this.state.choice.min;
  };

  errorInChoiceMax = () => {
    return !this.state.choice.max;
  };
}
