import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ArenaButton from "../arenaButton/arenaButton";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import SendButton from "../sendButton/sendButton";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

class ArenaModal extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Dialog
          className="socialShareModal"
          onClose={this.props.handleArenaModalClose}
          open={this.props.openArenaModal}
        >
          <DialogTitle>
            <Typography variant="h5" className="redColor bold textAlignCenter">
              {this.props.headerTitle}
            </Typography>
          </DialogTitle>
          <IconButton
            className="changePasswordCloseIcon"
            onClick={this.props.handleArenaModalClose}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <div id="arenaModalContentContainer">
              <Typography className="whiteColor textAlignCenter" variant="h6">
                {this.props.text}
              </Typography>
            </div>
          </DialogContent>
          <DialogActions id="arenaModalActionContainer">
            <div className="marginRight16">
              <ArenaButton
                text={this.props.secondaryButtonText}
                onClick={this.props.onClickSecondaryButton}
                size={"small"}
              />
            </div>
            <SendButton
              text={this.props.primaryButtonText}
              onClick={this.props.onClickPrimaryButton}
              size={"small"}
            />
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default ArenaModal;
