import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";

export default class ArenaSocialButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderGoogleButton = () => {
    return (
      <React.Fragment>
        <Button
          className="socialButtonBorderRadius whiteColor googleColor"
          size={this.props.size}
          variant="contained"
          startIcon={<Icon className="fab fa-google"></Icon>}
          onClick={this.props.onClick}
          {...this.props}
        ></Button>
      </React.Fragment>
    );
  };

  renderFacebookButton = () => {
    return (
      <React.Fragment>
        <Button
          className="socialButtonBorderRadius whiteColor facebookColor"
          size={this.props.size}
          variant="contained"
          startIcon={<Icon className="fab fa-facebook-f"></Icon>}
          onClick={this.props.onClick}
          {...this.props}
        ></Button>
      </React.Fragment>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.props.buttonType === "facebook"
          ? this.renderFacebookButton()
          : null}
        {this.props.buttonType === "google" ? this.renderGoogleButton() : null}
      </React.Fragment>
    );
  }
}
