import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import ArenaPasswordField from "../arenaPasswordField/passwordField";
import Typography from "@material-ui/core/Typography";
import SendButton from "../sendButton/sendButton";
import CloseIcon from "@material-ui/icons/Close";
import Auth from "../../common/auth";

export default class ArenaChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: "",
      newPassword: "",
    };
  }

  componentDidMount = () => {
    this.fetchUserAndSaveDetails();
    window.addEventListener("keypress", this.keyPressed);
  };

  keyPressed = (event) => {
    if (event.key === "Enter") {
      this.changePassword();
    }
  };

  componentWillUnmount() {
    window.removeEventListener("keypress", this.keyPressed);
  }
  fetchUserAndSaveDetails = () => {
    let userDetails = Auth.getUserDetails();
    this.setState({
      userDetails: userDetails,
    });
    return userDetails;
  };

  errorInPassword = () => {
    let passwordChecks = new RegExp(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$"
    );
    return !this.state.password || !passwordChecks.test(this.state.password);
  };

  errorInNewPassword = () => {
    let passwordChecks = new RegExp(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$"
    );
    return (
      !this.state.newPassword || !passwordChecks.test(this.state.newPassword)
    );
  };

  onChangeCurrentPasswordField = (event) => {
    this.setState({
      currentPassword: event.target.value,
    });
  };

  onChangeNewPasswordField = (event) => {
    this.setState({
      newPassword: event.target.value,
    });
  };

  changePassword = async () => {
    let data = {
      currentPassword: this.state.currentPassword,
      newPassword: this.state.newPassword,
    };
    let userId = this.state.userDetails && this.state.userDetails.id;
    try {
      await window.Arena.database.changeUserPassword(data, userId);
      window.NotificationUtils.showSuccess(
        window.t("passwordChangedSuccessfully")
      );
      this.props.onClose();
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError(window.t("somethingWentWrong"));
    }
  };

  render() {
    return (
      <React.Fragment>
        <Dialog
          className="changePasswordModal"
          onClose={this.props.handleClose}
          open={this.props.open}
          aria-labelledby="changePasswordModal"
        >
          <DialogTitle
            id="changePasswordModal"
            className="redColor bold textAlignCenter"
          >
            {window.t("changePassword")}
          </DialogTitle>
          <IconButton
            aria-label={`${window.t("close")} ${window.t("changePassword")}`}
            className="changePasswordCloseIcon"
            onClick={this.props.onClose}
          >
            <CloseIcon />
          </IconButton>
          <div id="updateModalDialogContent">
            <div className="loginPageEmailLink reverse">
              <Typography
                role="heading"
                aria-level="3"
                variant="body1"
                className="dullWhite"
              >
                {window.t("currentPassword")}
              </Typography>
            </div>
            <div className="changePasswordInputFieldContainer">
              <div style={{ width: "90%" }}>
                <ArenaPasswordField
                  placeholder={window.t("password")}
                  noEye={false}
                  error={this.state.checkErrors && this.errorInPassword()}
                  label={
                    this.state.checkErrors &&
                    this.errorInPassword() &&
                    window.t("invalidPassword")
                  }
                  helperText={
                    this.state.checkErrors &&
                    this.errorInPassword() &&
                    window.t("invalidPassword")
                  }
                  onChange={this.onChangeCurrentPasswordField}
                  value={this.state.currentPassword}
                  autoFocus={true}
                />
              </div>
            </div>

            <div className="loginPageEmailLink reverse">
              <Typography
                role="heading"
                aria-level="3"
                variant="body1"
                className="dullWhite"
              >
                {window.t("newPassword")}
              </Typography>
            </div>
            <div className="changePasswordInputFieldContainer">
              <div style={{ width: "90%" }}>
                <ArenaPasswordField
                  placeholder={window.t("password")}
                  noEye={false}
                  error={this.state.checkErrors && this.errorInPassword()}
                  label={
                    this.state.checkErrors &&
                    this.errorInPassword() &&
                    window.t("invalidPassword")
                  }
                  helperText={
                    this.state.checkErrors &&
                    this.errorInPassword() &&
                    window.t("invalidPassword")
                  }
                  onChange={this.onChangeNewPasswordField}
                  value={this.state.newPassword}
                />
              </div>
            </div>
            <div id="changePasswordSendButtonContainer">
              <SendButton size={"small"} onClick={this.changePassword} />
            </div>
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}
