import Dialog from "@material-ui/core/Dialog";
import React, { Component } from "react";
import { USER_ROLE } from "../../../constants";
import { ReactComponent as Bell } from "../../../icons/Bell.svg";
import { ReactComponent as Login } from "../../../icons/Login.svg";
import { ReactComponent as RegUser } from "../../../icons/RegUser.svg";
import { ReactComponent as Search } from "../../../icons/Search.svg";
import { ReactComponent as Settings } from "../../../icons/Settings.svg";
import { ReactComponent as SignUp } from "../../../icons/SignUp.svg";
import { ReactComponent as Statistics } from "../../../icons/Statistics.svg";
import Auth from "../../common/auth";
import AdminCreateEditPanel from "../adminCreateEditPanel/adminCreateEditPanel";
import ChangePassword from "../arenaChangePassword/arenaChangePassword";
import ControlPanelOption from "../controlPanelOption/controlPanelOption";
import MessagesControlPanel from "../messagesControlPanel/messagesControlPanel";
import SearchComponent from "../searchComponent/searchComponent";
import StatisticsData from "../statsicsData/statisticsData";
import UpdateDetailsModal from "../updateModal/updateModal";
import UserControlPanel from "../userControlPanel/userControlPanel";

export default class ControlPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changePassword: false,
      showOption: false,
      showMore: false,
      showUpdateModal: false,
      signup: false,
      login: false,
      userDetails: null,
      expanded: true,
      statsData: null,
    };
  }

  componentDidMount() {
    if (Auth.getAdminFlag()) {
      this.setState({
        showOption: true,
      });
    }
    let accountDetails = Auth.getAccountDetails();
    let userDetails = Auth.getUserDetails();
    if (
      this.props.history.location.pathname === `/${accountDetails.slug}/login`
    ) {
      this.setState({
        login: true,
      });
    }
    if (
      this.props.history.location.pathname === `/${accountDetails.slug}/signup`
    ) {
      this.setState({
        signup: true,
      });
    }
    this.setState({
      userDetails,
      accountDetails,
    });
  }

  showChangePassword = () => {
    this.setState({
      changePassword: true,
    });
  };

  closeChangePassword = () => {
    this.setState({
      changePassword: false,
    });
  };

  getComponent = () => {
    return (
      <MessagesControlPanel
        subjectId={this.props.subjectId}
        history={this.props.history}
        t={window.t}
      />
    );
  };

  renderSearchComponent = () => {
    return (
      <SearchComponent
        history={this.props.history}
        categoryId={this.props.categoryId}
        searchedString={this.props.searchedString}
      />
    );
  };

  onClickUpdateDetails = () => {
    this.setState({
      showUpdateModal: true,
    });
  };

  closeUpdateModal = () => {
    this.setState({
      showUpdateModal: false,
    });
  };

  redirectToLogin = () => {
    if (!this.state.accountDetails?.slug) {
      window.NotificationUtils.showInfo(window.t("selectSlugFirst"));
      this.props.history.push("/");
      return;
    }
    this.setState(
      {
        login: true,
        signup: false,
      },
      () => this.props.history.push(`/${this.state.accountDetails.slug}/login`)
    );
  };

  redirectToSignup = () => {
    if (!this.state.accountDetails?.slug) {
      window.NotificationUtils.showInfo(window.t("selectSlugFirst"));
      this.props.history.push("/");
      return;
    }
    this.setState(
      {
        signup: true,
        login: false,
      },
      () => this.props.history.push(`/${this.state.accountDetails.slug}/signup`)
    );
  };

  renderAuthControlPanel = () => {
    return (
      <React.Fragment>
        <div>
          <div className="controlPanelMainContainer primaryBackground">
            <ControlPanelOption
              name="user"
              placement={this.props.placement}
              icon={SignUp}
              onClickIconButton={this.redirectToSignup}
              color={this.state.signup ? "svgBlueColor" : "svgWhiteColor"}
              toolTipTitle={window.t("signUp")}
            />

            <ControlPanelOption
              name="key"
              placement={this.props.placement}
              icon={Login}
              onClickIconButton={this.redirectToLogin}
              color={this.state.login ? "svgBlueColor" : "svgWhiteColor"}
              toolTipTitle={window.t("login")}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderUpdateModal = () => {
    return (
      <Dialog
        aria-labelledby="updateModal"
        aria-modal="true"
        className="socialShareModal"
        onClose={this.closeUpdateModa}
        open={this.state.showUpdateModal}
      >
        <UpdateDetailsModal
          onClickChangePassword={this.showChangePassword}
          closeUpdateModal={this.closeUpdateModal}
          open={this.state.showUpdateModal}
          history={this.props.history}
        />
      </Dialog>
    );
  };

  render() {
    if (this.props.showAuthControlPanel) {
      return this.renderAuthControlPanel();
    }
    let isGuest = Boolean(
      this.state.userDetails && this.state.userDetails.role === USER_ROLE.GUEST
    );
    return (
      <React.Fragment>
        <div>
          <div className="controlPanelMainContainer primaryBackground">
            <ControlPanelOption
              color="svgWhiteColor"
              name="user"
              placement={this.props.placement}
              component={
                <div id="userControlPanelComponentContainer">
                  <UserControlPanel
                    showUpdateModal={this.onClickUpdateDetails}
                    history={this.props.history}
                    t={window.t}
                  />
                </div>
              }
              icon={RegUser}
              toolTipTitle={window.t("userSettings")}
            />
            {(this.state.showOption ||
              window.canAdministerCategory ||
              window.canAdministerSubject) && (
              <ControlPanelOption
                name="setting"
                color="svgWhiteColor"
                // noPopover={true}
                placement={this.props.placement}
                component={
                  <div id="userControlPanelComponentContainer">
                    <AdminCreateEditPanel
                      t={window.t}
                      subjectText={this.props.subjectText}
                      categoryText={this.props.categoryText}
                      history={this.props.history}
                      openEditMode={
                        this.props.openEditMode && this.props.openEditMode
                      }
                    />
                  </div>
                }
                icon={Settings}
                toolTipTitle={window.t("createEditSubjectAndCategory")}
              />
            )}

            {this.state.showOption && this.props.onClickDownloadReport && (
              <ControlPanelOption
                color="svgWhiteColor"
                name="statistics"
                placement={this.props.placement}
                component={
                  <div id="userControlPanelComponentContainer">
                    {" "}
                    {
                      <StatisticsData
                        subjectId={this.props.subjectId}
                        onClickDownloadReport={this.props.onClickDownloadReport}
                      />
                    }
                  </div>
                }
                icon={Statistics}
                toolTipTitle={window.t("subjectData")}
              />
            )}
            {this.props.showSearch && (
              <ControlPanelOption
                color={
                  this.props.searchedString ? "svgBlueColor" : "svgWhiteColor"
                }
                name="search"
                placement={this.props.placement}
                component={
                  <div id="searchComponentContainer">
                    {this.renderSearchComponent()}
                  </div>
                }
                icon={Search}
                extraClass="search"
                toolTipTitle={window.t("search")}
              />
            )}
            {!isGuest && (
              <ControlPanelOption
                color="svgWhiteColor"
                name="alert"
                placement={this.props.placement}
                component={this.getComponent()}
                icon={Bell}
                showPopover={this.props.showMessagePopover}
                extraClass="messages"
                toolTipTitle={window.t("myMessages")}
              />
            )}
          </div>
          {this.renderUpdateModal()}
          {this.state.changePassword && (
            <ChangePassword
              t={window.t}
              open={true}
              onClose={this.closeChangePassword}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}
