import Typography from "@material-ui/core/Typography";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highcharts3d from "highcharts/highcharts-3d";
import React from "react";
import { isBrowser, isMobile } from "react-device-detect";
import { USER_ROLE, WEBSITE_URL } from "../../../constants";
import Auth from "../../common/auth";
import CommonStructure from "../../common/mainStructure/main";
import AllocationLegend from "../allocationLegend/allocationLegend";
import IconAndText from "../categoryMsgAndShare/shareAndMsg";
import ClientNameLarge from "../commentClientNamelarge/commentClientNameLarge";
import ControlPanel from "../controlPanel/controlPanel";
import HeadWithImg from "../headingWithImage/headingWithImage";
import ArenaModal from "../arenaModal/arenaModal";
import FileSaver from "file-saver";
import ArenaPopover from "../arenaPopover/arenaPopover";
import Link from "@material-ui/core/Link";
import { ReactComponent as Share } from "../../../icons/Share.svg";
import { ReactComponent as AttchedFile } from "../../../icons/AttchedFile.svg";
import { ReactComponent as Message } from "../../../icons/Message.svg";
import { HIGHCHART_COLORS } from "../allocationPage/allocationPage";
highcharts3d(Highcharts);

export const OPTIONS = {
  chart: {
    type: "pie",
    options3d: {
      enabled: true,
      alpha: 45,
    },
    backgroundColor: "transparent",
  },
  credits: false,
  title: {
    text: "",
  },
  plotOptions: {
    pie: {
      innerSize: 100,
      depth: 45,
      dataLabels: {
        className: "highChartDataLabels",
        // distance: -60,
        align: "center",
        x: -0,
        y: -0,
        format: "{point.percentage:.1f} %",

        // formatter: function() {
        //     if (this.y > 0) {
        //       return  Highcharts.numberFormat(this.point.percentage, 1) + ' %'
        //     }
        //   }
      },
    },
  },
  tooltip: {
    enabled: false,
    formatter: function () {
      return Math.round(this.percentage) + "%";
    },
  },
  accessibility: {
    point: {
      valueSuffix: "%",
    },
  },
  series: [
    {
      name: "Delivered amount",
      data: [
        ["Stat 1", 15, "#62e1bd"],
        ["Stat 2", 15, "#57bc35"],
        ["Stat 3", 30, "#f3842c"],
        ["Stat 4", 40, "#007bf8"],
        ["Unallocated", 40, "#ffffff"],
      ],
    },
  ],
};

export default class AllocationConclusion extends CommonStructure {
  constructor(props) {
    super(props);
    this.state = {
      OPTIONS: OPTIONS,
      reportData: null,
      backgroundSticky: false,
      questionSticky: false,
      unansweredChoices: false,
    };
  }

  componentDidMount = async () => {
    this.setState({
      loading: true,
    });
    this.fetchAndSaveUserDetails();
    this.fetchAccountDetails();
    let querySearchParams = this.props.history.location.search;
    let params = new URLSearchParams(querySearchParams);
    let subjectId = params.get("subjectId");

    if (!subjectId) {
      this.props.history.replace(`/404`);
    } else {
      this.setState({
        subjectId: subjectId,
      });
      try {
        let subjectData = await window.Arena.database.fetchSubjectWithId(
          subjectId
        );
        if (subjectData?.data?.canAdminister) {
          window.canAdministerSubject = true;
        } else {
          window.canAdministerSubject = false;
        }
        let image =
          subjectData &&
          subjectData.data &&
          subjectData.data.cover &&
          subjectData.data.cover.original;
        this.backgroundImg = image || "/assets/img/header.png";
        if (subjectData && subjectData.data) {
          await this.getAndDownloadReport(subjectId);
        }
        let conclusionData = subjectData.data && subjectData.data.conclusion;
        this.setState({
          subjectData: subjectData.data,
          conclusionData: conclusionData,
          loading: false,
        });
      } catch (e) {
        console.error(e);
        window.NotificationUtils.showError(window.t("somethingWentWrong"));
        this.props.history.replace(`/404`);
      }
    }
  };

  fetchAccountDetails = () => {
    let accountDetails = Auth.getAccountDetails();
    this.setState({
      accountDetails: accountDetails,
    });
    return accountDetails;
  };

  fetchAndSaveUserDetails = () => {
    let userDetails = Auth.getUserDetails();
    this.setState({
      userDetails: userDetails,
    });
    return userDetails;
  };

  getAndDownloadReport = async (subjectId) => {
    try {
      let report = await window.Arena.database.fetchSubjectReport(subjectId);
      let options = JSON.parse(JSON.stringify(this.state.OPTIONS));
      let reportData = report.data;
      let optionsData = [];
      let colors = [];
      let count = 0;
      for (let [index, singleReport] of reportData.entries()) {
        // let randomColor = tinyColor.random().toHexString();
        let randomColor = HIGHCHART_COLORS[index];

        this.setState({
          [`color_${singleReport.name}`]: randomColor,
        });
        colors.push(randomColor);
        let singleOption = [
          `${singleReport.name}`,
          singleReport.value,
          `${randomColor}`,
        ];
        optionsData.push(singleOption);
        if (singleReport.value === 0) {
          count++;
        }
      }
      if (count === reportData.length) {
        let unallocatedOption = ["Unanswered", 100, "#fff"];
        optionsData.push(unallocatedOption);
        colors.push("#fff");
        this.setState({
          unansweredChoices: true,
        });
      }
      options.series[0].data = optionsData;
      Highcharts.setOptions({
        colors: colors,
      });
      this.setState({
        reportData,
        OPTIONS: options,
      });
      // await window.Arena.database.downloadSubjectReport(subjectId)
    } catch (e) {
      console.error(e);
    }
  };

  renderControlPanel = () => {
    if (isMobile) {
      return (
        <React.Fragment>
          <ControlPanel
            openEditMode={this.openEditModeSubject}
            history={this.props.history}
            placement="top"
            subjectText={window.t("editSubject")}
            t={this.props.t}
            onClickDownloadReport={this.onClickDownloadReport}
            subjectId={this.state.subjectId}
            showSearch={true}
            categoryId={this.state.subjectData?.category?.id}
          />
        </React.Fragment>
      );
    }
    if (isBrowser) {
      return (
        <React.Fragment>
          <ControlPanel
            openEditMode={this.openEditModeSubject}
            history={this.props.history}
            placement="left"
            subjectText={window.t("editSubject")}
            t={this.props.t}
            onClickDownloadReport={this.onClickDownloadReport}
            subjectId={this.state.subjectId}
            showSearch={true}
            categoryId={this.state.subjectData?.category?.id}
          />
        </React.Fragment>
      );
    }
  };

  openEditModeSubject = () => {
    if (!Auth.getAdminFlag() && !window.canAdministerSubject) {
      window.NotificationUtils.showInfo(window.t("editSubjectNotAllowed"));
      return;
    }
    let subjectId = this.state.subjectData && this.state.subjectData.id;
    this.props.history.push(`edit-subject?subjectId=${subjectId}`);
  };

  onClickShareButton = () => {
    let url = `${WEBSITE_URL}${this.state.accountDetails.slug}/allocation-conclusion?subjectId=${this.state.subjectData.id}`;
    window.ShareModal.showShareModal(url);
  };

  renderAllocationLegends = () => {
    return (
      this.state.subjectData &&
      this.state.subjectData.allocation.options.map((item, index) => {
        return (
          <div className="allocationPageLegendContainer marginAuto">
            <AllocationLegend
              text={`${item.name}`}
              color={this.state[`color_${item.name}`]}
            />
          </div>
        );
      })
    );
  };

  renderUnansweredAllocationLegends = () => {
    return (
      <div
        id="unansweredAllocationLegend"
        className="allocationPageLegendContainer marginAuto "
      >
        <AllocationLegend text={`Unanswered`} color={"#fff"} />
      </div>
    );
  };

  onClickAvatar = () => {
    let accountDetails = Auth.getAccountDetails();
    this.goBack(
      `/${accountDetails.slug}/category-discussion?categoryId=${this.state.subjectData.category.id}`
    );
  };

  sendMessage = () => {
    let subjectId = this.state.subjectData.id && this.state.subjectData.id;
    this.props.history.push(`send-message?subjectId=${subjectId}`);
  };

  getCategoryImageUrl = () => {
    let categoryData =
      this.state.subjectData && this.state.subjectData.category;
    if (categoryData && categoryData.icon && categoryData.icon.sizes) {
      return categoryData.icon.sizes["240x240"];
    }
    return "";
  };

  renderPopoverContent = () => {
    let attachmentFiles =
      this.state.subjectData && this.state.subjectData.files;
    if (attachmentFiles && attachmentFiles.length < 1) {
      return (
        <Typography className="whiteColor">
          {window.t("noAttachedFiles")}
        </Typography>
      );
    }
    return (
      attachmentFiles &&
      attachmentFiles.map((file) => {
        return (
          <Link
            className="displayFlex marginBottom8 blueColor justContentFlexEnd"
            href={file.url}
            target="_blank"
          >
            {file.name}
          </Link>
        );
      })
    );
  };

  onClickAttachmentsButton = () => {
    this.setState({
      showAttachmentPopover: true,
    });
  };

  onClickIconButton = () => {
    let showPopover = false;
    if (this.state.showAttachmentPopover) {
      showPopover = false;
    } else {
      showPopover = true;
    }
    this.setState({
      showAttachmentPopover: showPopover,
    });
  };

  renderTopContent = () => {
    let attachmentFiles =
      this.state.subjectData && this.state.subjectData.files;
    let role = Auth.getUserRole();

    return (
      <React.Fragment>
        <div className="discussSubjectTopMainContainer">
          <div className="discussSubjectPageTopContent">
            <ClientNameLarge
              imgURL={this.getCategoryImageUrl()}
              name={this.state.subjectData && this.state.subjectData.name}
              isLarge={true}
              onClickAvatar={this.onClickAvatar}
              ariaLabel={window.t("backToCategory")}
            />
          </div>
          <div id="headerInfoContainer" className="reverse">
            <div className="discussSubjecHeaderInfo reverse">
              {attachmentFiles && attachmentFiles.length > 0 && (
                <IconAndText
                  id={"attachments"}
                  text={window.t("attachments")}
                  value={AttchedFile}
                  onClick={this.onClickAttachmentsButton}
                />
              )}
              {role === USER_ROLE.USER && (
                <IconAndText
                  text={"Message"}
                  value={Message}
                  onClick={this.sendMessage}
                />
              )}
              <IconAndText
                text={"Comments"}
                istext={true}
                value={
                  (this.state.subjectData &&
                    this.state.subjectData.activityCount) ||
                  0
                }
              />
              <IconAndText
                text={"Share"}
                value={Share}
                onClick={this.onClickShareButton}
              />
            </div>
          </div>
        </div>
        {attachmentFiles && attachmentFiles.length > 0 && (
          <ArenaPopover
            className="arenaPopover attachmentPopover"
            toggle={this.onClickIconButton}
            history={this.props.history}
            placement={"bottom"}
            isOpen={this.state.showAttachmentPopover}
            target={"#attachments"}
          >
            <div>{this.renderPopoverContent()}</div>
          </ArenaPopover>
        )}
      </React.Fragment>
    );
  };

  closeDownloadConfirmModal = () => {
    this.setState({
      showDownloadConfirmModal: false,
    });
  };
  renderDownloadConfirmModal = () => {
    return (
      <ArenaModal
        text={`${window.t("downloadReportText")} ${
          this.state.subjectData.name
        } ?`}
        openArenaModal={this.state.showDownloadConfirmModal}
        handleArenaModalClose={this.closeDownloadConfirmModal}
        secondaryButtonText={window.t("cancel")}
        onClickSecondaryButton={this.closeDownloadConfirmModal}
        onClickPrimaryButton={this.getDownloadReport}
        headerTitle={window.t("downloadReport")}
      />
    );
  };

  getDownloadReport = async () => {
    let result = await window.Arena.database.downloadDiscussSubjectReport(
      this.state.subjectId
    );
    FileSaver.saveAs(result.data, `report-${this.state.subjectId}.xlsx`);
    this.setState({
      showDownloadConfirmModal: false,
    });
  };

  onClickDownloadReport = () => {
    this.setState({
      showDownloadConfirmModal: true,
    });
  };

  renderBottomContent = () => {
    return this.renderAllocationConclusion();
  };

  renderAllocationConclusion = () => {
    return (
      <React.Fragment>
        <div id="concusionPageBottomMainContainer">
          <div className={`conclusionPageTopContent`}>
            <div
              id="conclusionPageHeaderHeading"
              className="marginBottom12 reverse"
            >
              <Typography
                role="heading"
                aria-level="1"
                variant="h6"
                className="redColor bold text textAlignEnd reverse"
              >
                {this.props.t("subjectKind")}
              </Typography>
            </div>
            <HeadWithImg
              imgURL="/assets/img/question.png"
              text={this.state.subjectData && this.state.subjectData.question}
            />
          </div>
          <div className="conclusionPageCommentContainer commentConclusionFlexContainer zIndex1Mobile">
            <div>
              <Typography
                role="heading"
                aria-level="2"
                variant="h6"
                className="redColor bold textAlignEnd conclusionTitleExtra reverse"
              >
                {this.props.t("votingResult")}
              </Typography>
            </div>
            <div id="allocationPageChartMainContainer" className="reverse">
              <div id="allocationConclusionLegendContainer">
                <div className="marginAuto">
                  {this.renderAllocationLegends()}
                  {this.state.unansweredChoices &&
                    this.renderUnansweredAllocationLegends()}
                </div>
              </div>
              <div id="allocationPageChartContainer">
                {/* <Pie3D config={CONFIG} data={DATA} /> */}
                <HighchartsReact
                  highcharts={Highcharts}
                  options={this.state.OPTIONS}
                />
              </div>
            </div>
          </div>
        </div>
        {this.renderControlPanel()}
        {this.state.showDownloadConfirmModal &&
          this.renderDownloadConfirmModal()}
      </React.Fragment>
    );
  };
}
