import React from "react";

export default class Header extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div id="headerContainer">
          <img
            src="/assets/img/logoArena.png"
            alt="arenaLogo"
            className="logoHeader"
          />
        </div>
      </React.Fragment>
    );
  }
}
