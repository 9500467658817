import React, { Component } from "react";
import { loadCSS } from "fg-loadcss";
import Typography from "@material-ui/core/Typography";

export default class CommentNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
  }

  render() {
    return (
      <React.Fragment>
        <div
          className={`commentNumberContainer`}
          style={{ background: `${this.props.backgroundColor}` }}
        >
          <div className="commentNumberSubContainer">
            <Typography className="blueColor lineHeightNone bold" variant="h5">
              {this.props.value}
            </Typography>
            <Typography
              style={{ color: "#ECE9F1", marginTop: 4 }}
              className="whiteColor "
              variant="body1"
            >
              {this.props.text}
            </Typography>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
