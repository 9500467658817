import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import MessageListItem from "../messageListItem/messageListItem";
import Link from "@material-ui/core/Link";
import Auth from "../../common/auth";
import Spinner from "@material-ui/core/CircularProgress";

export default class MessagesControlPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      loading: true,
      loadMore: false,
    };
  }

  componentDidMount = async () => {
    try {
      let messages = await window.chats.fetchChats();
      this.setState({
        data: messages,
        loading: false,
      });
    } catch (e) {
      console.error(e);
    }
  };

  renderMessages = () => {
    if (this.state.loading) {
      return (
        <div id="messageSpinner">
          <Spinner />
        </div>
      );
    }

    if (!this.state.data || (this.state.data && this.state.data.length < 1)) {
      return (
        <Typography
          id="noMessageFoundPlaceHolder"
          className="textAlignCenter dullWhite"
          variant="body1"
        >
          {this.props.t("noMessageFound")}
        </Typography>
      );
    }
    let isAdmin = Auth.getAdminFlag();
    if (isAdmin) {
      return this.renderMessagesForAdmin();
    } else {
      return this.renderMessagesForUser();
    }
  };

  renderMessagesForUser = () => {
    return (
      this.state.data &&
      this.state.data.length > 0 && (
        <MessageListItem
          data={this.state.data[0]}
          history={this.props.history}
        />
      )
    );
  };

  renderMessagesForAdmin = () => {
    return (
      this.state.data &&
      this.state.data.length > 0 &&
      this.state.data.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <MessageListItem data={item} history={this.props.history} />
          </React.Fragment>
        );
      })
    );
  };

  onclickLoadMore = () => {
    let loadMore = this.state.loadMore;
    this.setState({
      loadMore: !loadMore,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div
          tabIndex={0}
          style={{ background: `${this.props.backgroundColor}` }}
          id="messagesContainer"
        >
          <Typography
            variant="h6"
            className="redColor bold textAlignEnd reverse"
          >
            {this.props.t("myMessages")}
          </Typography>
          <div
            id={`${
              this.state.loadMore
                ? "messageListContainerInitial"
                : "messageListContainer"
            }`}
          >
            {this.renderMessages()}
          </div>
          {this.state.data && this.state.data.length > 0 && (
            <div id="messagesControlPanelLoadMore">
              <Link
                id="messagesLinkTag"
                href="#"
                onClick={this.onclickLoadMore}
              >
                {this.state.loadMore
                  ? this.props.t("lessMessages")
                  : this.props.t("moreMessages")}
              </Link>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
