import { openWindow } from "./openWindow";

const createCallbackWindow = ({ url, onClose, onMessage, name }) => {
  if (!url) return;

  let callbackReceived = false;
  let popup;
  let checkInterval;

  const messageHandler = (e) => {
    if (!popup) return;
    if (typeof onMessage === "function") {
      onMessage(e, () => {
        callbackReceived = true;
        window.removeEventListener("message", messageHandler);
        clearInterval(checkInterval);

        popup.close();
      });
    }
  };

  popup = openWindow(url, name);

  checkInterval = setInterval(() => {
    if (popup && popup.closed && !callbackReceived) {
      window.removeEventListener("message", messageHandler);
      clearInterval(checkInterval);

      if (typeof onClose === "function") {
        onClose();
      }
    }
  }, 500);

  window.addEventListener("message", messageHandler);
};

const getFacebookOAuthCode = () =>
  new Promise((resolve, reject) => {
    const url = `https://www.facebook.com/v6.0/dialog/oauth?client_id=${process.env.REACT_APP_FACEBOOK_APP_ID}&scope=email&auth_type=rerequest&redirect_uri=${process.env.REACT_APP_FACEBOOK_REDIRECT_URL}`;

    createCallbackWindow({
      name: "Facebook",
      url,
      onMessage: (e, done) => {
        //if (e.origin !== document.location.origin) return;
        if (e.data.provider !== "facebook") return;

        const { result } = e.data;
        if (result && result.code) {
          resolve({
            code: result.code,
            callbackUrl: process.env.REACT_APP_FACEBOOK_REDIRECT_URL,
          });
        } else {
          const err = new Error();
          if (result.error) {
            err.original = {
              error: result.error,
              code: result.error_code,
              description: result.error_description,
              reason: result.error_reason,
            };
          }
          reject(err);
        }

        done();
      },
      onClose: () => reject(),
    });
  });

const getGoogleOAuthCode = () =>
  new Promise((resolve, reject) => {
    const url = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile&redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT_URL}&response_type=code`;
    createCallbackWindow({
      name: "Google",
      url,
      onMessage: (e, done) => {
        //if (e.origin !== document.location.origin) return;
        if (e.data.provider !== "google") return;

        const { result } = e.data;
        if (result && result.code) {
          resolve({
            code: result.code,
            callbackUrl: process.env.REACT_APP_GOOGLE_REDIRECT_URL,
          });
        } else {
          const err = new Error();
          if (result.error) {
            err.original = {
              error: result.error,
            };
          }
          reject(err);
        }

        done();
      },
      onClose: () => reject(),
    });
  });

export default {
  loginFacebook: () => {
    return new Promise((resolve, reject) =>
      getFacebookOAuthCode()
        .then(({ code, callbackUrl }) => {
          return window.Arena.database.loginWithFacebook({
            code,
            redirect_uri: callbackUrl,
          });
        })
        .then(resolve)
        .catch(reject)
    );
  },
  loginGoogle: () => {
    return new Promise((resolve, reject) =>
      getGoogleOAuthCode()
        .then(async ({ code, callbackUrl }) => {
          return await window.Arena.database.loginWithGoogle({
            code,
            redirect_uri: callbackUrl,
          });
        })
        .then(resolve)
        .catch(reject)
    );
  },
};
