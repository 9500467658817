import { Button } from "@material-ui/core";
import SvgIcon from "@material-ui/core/SvgIcon";
import Typography from "@material-ui/core/Typography";
import { loadCSS } from "fg-loadcss";
import React, { Component } from "react";

export default class ControlPanelButton extends Component {
  componentDidMount() {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
  }

  render() {
    return (
      <React.Fragment>
        <Button
          disableRipple={true}
          tabIndex={0}
          role="button"
          aria-label={this.props.text}
          className="popoverButtonContainer"
          onClick={this.props.onClick}
          style={{ background: `${this.props.backgroundColor}` }}
        >
          <span tabIndex={-1} aria-hidden="true" className="popoverIconButton">
            <SvgIcon
              className="svgBlueColor"
              style={{ color: "#65D2FC", fontSize: "58px" }}
              component={this.props.value}
              viewBox="0 0 512 512"
            />
          </span>
          <Typography
            tabIndex="-1"
            aria-hidden="true"
            className="whiteColor bold lineHeightOne textAlignCenter"
            variant="body1"
          >
            {this.props.text}
          </Typography>
        </Button>
      </React.Fragment>
    );
  }
}
