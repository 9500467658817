import React, { Component } from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as Filter } from "../../../icons/Filter.svg";
import Button from "@material-ui/core/Button";

export default class HeadingWithImg extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div id="commentFilterMainContainer" className="reverse">
          <div id="commentFilterTextContainer">
            <Button
              id="commentFilterText"
              onClick={this.props.onClickPopularity}
              className={`noPadding textTransformInitial ${
                this.props.underlinePopularity ? "underlineText" : ""
              }`}
              tabIndex={0}
              aria-labelledBy={window.t("titleComment")}
            >
              {window.t("sortPopularity")}
            </Button>
            <Button
              variant="body1"
              className={`dullWhite textAlignEnd noPadding textTransformInitial ${
                this.props.underlineDate ? "underlineText" : ""
              }`}
              onClick={this.props.onclickDate}
              tabIndex={0}
              aria-labelledBy={window.t("titleComment")}
            >
              {window.t("sortDate")}
            </Button>
          </div>
          <div>
            <SvgIcon
              className="svgWhiteColor"
              style={{ fontSize: "38px", color: "#ECE9F1" }}
              component={Filter}
              viewBox="0 0 512 512"
            />
            {/* <Icon className="fas fa-filter" style={{fontSize: "30px", color: "#ECE9F1"}} ></Icon> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
