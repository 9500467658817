import React from "react";
import CommonStructure from "../../common/mainStructure/main";
import ClientNameLarge from "../commentClientNamelarge/commentClientNameLarge";
import Typography from "@material-ui/core/Typography";
import { FORM_TYPE_MAP, SUBJECT_STATUS } from "../../../constants";
import moment from "moment";
import { isBrowser, isMobile } from "react-device-detect";
import ControlPanel from "../controlPanel/controlPanel";
import SearchDisplayCard from "../searchDisplayCard/searchDisplayCard";

export default class SearchPage extends CommonStructure {
  constructor(props) {
    super(props);
    this.state = {
      categoryData: null,
      searchedString: "",
      loading: true,
    };
  }
  async componentDidMount() {
    let querySearchParams = this.props.history.location.search;
    let params = new URLSearchParams(querySearchParams);
    let categoryId = params.get("categoryId");
    let search = params.get("search");
    if (!categoryId || !search) {
      window.NotificationUtils.showError(window.t("somethingWentWrong"));
      return this.props.history.goBack();
    }
    try {
      let category = await this.fetchCategory(categoryId);
      await this.fetchSearchedSubjects(category, search);
      this.setState({
        searchedString: search,
        loading: false,
      });
    } catch (e) {
      console.error(e);
    }
  }

  componentWillReceiveProps = (props) => {};

  fetchCategory = async (categoryId) => {
    try {
      let categoryResponse = await window.Arena.database.fetchCategoryWithId(
        categoryId
      );
      if (categoryResponse?.data?.canAdminister) {
        window.canAdministerCategory = true;
      } else {
        window.canAdministerCategory = false;
      }
      let categoryData = categoryResponse.data;
      this.backgroundImg =
        categoryData && categoryData.cover && categoryData.cover.original;
      this.setState({
        categoryData,
      });
      return categoryData;
    } catch (e) {
      console.error(e);
    }
  };

  fetchSearchedSubjects = async (category, search) => {
    try {
      let subjectResponse = await window.Arena.database.fetchSubjectsRelatedToAccount(
        category?.account?.id,
        {
          category: category?.id,
          search: search,
        }
      );
      let subjectData = subjectResponse.data;
      this.setState({
        subjectData,
      });
    } catch (e) {
      console.error(e);
    }
  };

  renderTopContent = () => {
    return (
      <React.Fragment>
        <div id="categoryDiscussionTopContainer">
          <div className="categoryDiscussionPageTopContent">
            <ClientNameLarge
              imgURL={
                (this.state.categoryData &&
                  this.state.categoryData.icon &&
                  this.state.categoryData.icon.original) ||
                "/assets/img/client.png"
              }
              name={this.state.categoryData && this.state.categoryData.name}
              isLarge={true}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  getFinishedSubject = () => {
    let subjects = Object.assign([], this.state.subjectData.items);
    let date = moment.utc(new Date());
    let filteredSubjects = subjects.filter((item) => {
      let endDate = moment.utc(item.endDate);
      return item.status === SUBJECT_STATUS.FINISHED || date > endDate;
    });
    return filteredSubjects;
  };

  renderConcludedSubjectCards = () => {
    let finishedSubject = this.getFinishedSubject();
    return finishedSubject?.map((item, index) => {
      this.getFinishedCardsCategoryName(item.type);
      return (
        <React.Fragment key={index}>
          <div className="successPageCardContainer">
            <SearchDisplayCard
              t={this.props.t}
              data={item}
              onClick={() => this.onClickFinishedSubjectCard(item)}
            />
          </div>
        </React.Fragment>
      );
    });
  };

  getPublishedSubject = () => {
    let subjects = Object.assign([], this.state.subjectData.items);
    let date = moment.utc(new Date());
    let filteredSubjects = subjects.filter((item) => {
      let endDate = moment.utc(item.endDate);
      return item.status === SUBJECT_STATUS.PUBLISHED && date < endDate;
    });
    return filteredSubjects;
  };

  getCategoryName = (type) => {
    if (type === FORM_TYPE_MAP.discussion) {
      return "discuss-subject";
    }
    if (type === FORM_TYPE_MAP.allocation) {
      return "allocation";
    }
    if (type === FORM_TYPE_MAP.choice) {
      return "multi-subject";
    }
  };

  getFinishedCardsCategoryName = (type) => {
    if (type === FORM_TYPE_MAP.discussion) {
      return "conclusion";
    }
    if (type === FORM_TYPE_MAP.allocation) {
      return "allocation-conclusion";
    }
    if (type === FORM_TYPE_MAP.choice) {
      return "multiple-choice-conclusion";
    }
  };

  renderPublishedSubjects = () => {
    let publishedSubject = this.getPublishedSubject();
    return publishedSubject?.map((item, index) => {
      this.getCategoryName(item.type);
      return (
        <React.Fragment key={index}>
          <div className="successPageCardContainer">
            <SearchDisplayCard
              t={this.props.t}
              data={item}
              onClick={() => this.onClickSubjectCard(item)}
            />
          </div>
        </React.Fragment>
      );
    });
  };

  onClickSubjectCard = (data) => {
    if (data.choice) {
      return this.props.history.push(`multi-subject?subjectId=${data.id}`);
    }
    if (data.allocation) {
      return this.props.history.push(`allocation?subjectId=${data.id}`);
    }
    this.props.history.push(`discuss-subject?subjectId=${data.id}`);
  };

  onClickFinishedSubjectCard = (data) => {
    if (data.choice) {
      return this.props.history.push(
        `multiple-choice-conclusion?subjectId=${data.id}`
      );
    }
    if (data.allocation) {
      return this.props.history.push(
        `allocation-conclusion?subjectId=${data.id}`
      );
    }
    this.props.history.push(`conclusion?subjectId=${data.id}`);
  };

  renderPublishedSubjectCards = () => {
    return (
      <React.Fragment>
        <div className="displayFlex justifyContentCenter">
          <Typography
            role="heading"
            aria-level="1"
            tabIndex="0"
            aria-label={this.props.t("inProcess")}
            variant="h6"
            className="whiteColor bold textAlignEnd categoryHeadingTypoExtra reverse"
          >
            {this.props.t("inProcess")}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginTop: "26px",
          }}
        >
          <div className="categoryDiscussionCardContainer reverse searchPageCardContainer">
            {this.state.subjectData && this.renderPublishedSubjects()}
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderConcludedSubjects = () => {
    return (
      <React.Fragment>
        <div className="displayFlex justifyContentCenter">
          <Typography
            role="heading"
            aria-level="1"
            tabIndex="0"
            aria-label={this.props.t("inProcess")}
            variant="h6"
            className="whiteColor bold textAlignEnd categoryHeadingTypoExtra reverse"
          >
            {this.props.t("conclusionsPublished")}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginTop: "26px",
          }}
        >
          <div className="categoryDiscussionCardContainer reverse searchPageCardContainer">
            {this.state.subjectData && this.renderConcludedSubjectCards()}
          </div>
        </div>
      </React.Fragment>
    );
  };

  getFutureSubject = () => {
    let subjects = Object.assign(
      [],
      this.state.subjectData && this.state.subjectData.items
    );
    let date = moment.utc(new Date());
    let filteredSubjects = subjects.filter((item) => {
      let startDate = moment.utc(item.startDate);
      return startDate > date;
    });
    return filteredSubjects;
  };

  renderFutureSubjectCards = () => {
    let futureSubjects = this.getFutureSubject();
    return (
      futureSubjects &&
      futureSubjects.map((item, index) => {
        this.getFinishedCardsCategoryName(item.type);
        return (
          <React.Fragment key={index}>
            <div className="successPageCardContainer">
              <SearchDisplayCard
                t={this.props.t}
                data={item}
                onClick={() => this.onClickSubjectCard(item)}
              />
            </div>
          </React.Fragment>
        );
      })
    );
  };

  renderFutureSubjects = () => {
    return (
      <React.Fragment>
        <div className="displayFlex justifyContentCenter">
          <Typography
            role="heading"
            aria-level="1"
            tabIndex="0"
            aria-label={this.props.t("inProcess")}
            variant="h6"
            className="whiteColor bold textAlignEnd categoryHeadingTypoExtra reverse"
          >
            {this.props.t("inPreparation")}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginTop: "26px",
          }}
        >
          <div className="categoryDiscussionCardContainer reverse searchPageCardContainer">
            {this.state.subjectData && this.renderFutureSubjectCards()}
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderControlPanel = () => {
    if (isMobile) {
      return (
        <React.Fragment>
          <ControlPanel
            categoryText={window.t("editCategory")}
            openEditMode={this.openEditModeCategory}
            history={this.props.history}
            placement="top"
            t={this.props.t}
            showSearch={true}
            categoryId={this.state.categoryData?.id}
            searchedString={this.state.searchedString}
          />
        </React.Fragment>
      );
    }
    if (isBrowser) {
      return (
        <React.Fragment>
          <ControlPanel
            categoryText={window.t("editCategory")}
            openEditMode={this.openEditModeCategory}
            history={this.props.history}
            placement="left"
            t={this.props.t}
            showSearch={true}
            categoryId={this.state.categoryData?.id}
            searchedString={this.state.searchedString}
          />
        </React.Fragment>
      );
    }
  };
  renderBottomContent = () => {
    let stateSubjectData = Object.assign({}, this.state.subjectData);

    let futureSubjects = stateSubjectData?.items && this.getFutureSubject();
    let publishedSubjects =
      stateSubjectData?.items && this.getPublishedSubject();
    let finishedSubjects = stateSubjectData?.items && this.getFinishedSubject();
    let resultsPresent =
      (publishedSubjects && publishedSubjects.length > 0) ||
      (finishedSubjects && finishedSubjects.length > 0) ||
      (futureSubjects && futureSubjects.length > 0);
    return (
      <React.Fragment>
        <div className="searchPageMainContainer zIndex marginBottom24">
          <div className="displayFlex justifyContentCenter searchStringContainer">
            <Typography
              role="heading"
              aria-level="1"
              tabIndex="0"
              aria-label={window.t("searchedString")}
              variant="h6"
              className="whiteColor bold textAlignEnd categoryHeadingTypoExtra reverse"
            >
              {window.t("searchedString")}
            </Typography>
            <Typography className="whiteColor bold textAlignEnd categoryHeadingTypoExtra reverse marginLeft8 marginRight8 displayFlex alignItemsCenter">
              :
            </Typography>
            <Typography
              role="heading"
              aria-level="1"
              tabIndex="0"
              aria-label={this.state.searchedString}
              variant="h6"
              className="whiteColor bold textAlignEnd categoryHeadingTypoExtra reverse"
            >
              {this.state.searchedString}
            </Typography>
            {!resultsPresent && (
              <Typography
                role="heading"
                aria-level="1"
                tabIndex="0"
                aria-label={this.state.searchedString}
                variant="h6"
                className="whiteColor bold textAlignEnd categoryHeadingTypoExtra reverse marginText"
              >
                - {window.t("hasNoResults")}
              </Typography>
            )}
          </div>
          {publishedSubjects &&
            publishedSubjects.length > 0 &&
            this.renderPublishedSubjectCards()}
          {finishedSubjects &&
            finishedSubjects.length > 0 &&
            this.renderConcludedSubjects()}
          {futureSubjects &&
            futureSubjects.length > 0 &&
            this.renderFutureSubjects()}
        </div>
        {this.renderControlPanel()}
      </React.Fragment>
    );
  };
}
