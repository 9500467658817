import React from "react";
import CommonStructure from "../../common/mainStructure/main";
import Typography from "@material-ui/core/Typography";
import HeadWithImg from "../headingWithImage/headingWithImage";
import ReadMore from "../readMoreComponent/readMoreComponent";
import ControlPanel from "../controlPanel/controlPanel";
import { isBrowser, isMobile } from "react-device-detect";
import UserPill from "../userAndIcon/userPill";
import SendButton from "../sendButton/sendButton";
import AllocationLegend from "../allocationLegend/allocationLegend";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highcharts3d from "highcharts/highcharts-3d";
import ArenaSlider from "../arenaSlider/arenaSlider";
import { USER_ROLE, WEBSITE_URL } from "../../../constants";
import Auth from "../../common/auth";
import ClientNameLarge from "../commentClientNamelarge/commentClientNameLarge";
import IconAndText from "../categoryMsgAndShare/shareAndMsg";
import ArenaModal from "../arenaModal/arenaModal";
import FileSaver from "file-saver";
import ArenaPopover from "../arenaPopover/arenaPopover";
import Link from "@material-ui/core/Link";
import { ReactComponent as Share } from "../../../icons/Share.svg";
import { ReactComponent as AttchedFile } from "../../../icons/AttchedFile.svg";
import { ReactComponent as Message } from "../../../icons/Message.svg";
import moment from "moment";
import renderHTML from "react-render-html";
import AllocationConclusion from "../allocationConclusion/allocationConclusion";
highcharts3d(Highcharts);

const OPTIONS = {
  chart: {
    type: "pie",
    options3d: {
      enabled: true,
      alpha: 45,
    },
    backgroundColor: "transparent",
  },
  credits: false,
  title: {
    text: "",
  },
  plotOptions: {
    pie: {
      innerSize: 100,
      depth: 45,
      dataLabels: {
        className: "highChartDataLabels",
        // distance: -60,
        align: "center",
        x: -0,
        y: -0,
        format: "{point.percentage:.1f} %",

        // formatter: function() {
        //     if (this.y > 0) {
        //       return  Highcharts.numberFormat(this.point.percentage, 1) + ' %'
        //     }
        //   }
      },
    },
  },
  tooltip: {
    enabled: false,
    formatter: function () {
      return Math.round(this.percentage) + "%";
    },
  },
  accessibility: {
    point: {
      valueSuffix: "%",
    },
  },
  series: [
    {
      showInLegend: false,
      name: "Delivered amount",
      data: [
        ["Stat 1", 15, "#62e1bd"],
        ["Stat 2", 15, "#57bc35"],
        ["Stat 3", 30, "#f3842c"],
        ["Stat 4", 40, "#007bf8"],
        ["Unallocated", 40, "#ffffff"],
      ],
    },
  ],
};

export const HIGHCHART_COLORS = [
  "#4bd258",
  "#65d2fc",
  "#fea030",
  "#2283FF",
  "#65179c",
  "#ac4b1c",
  "#ff414d",
  "#006a71",
  "#7ea04d",
  "#d2e603",
];

export default class AllocationPage extends AllocationConclusion {
  constructor(props) {
    super(props);
    this.state = {
      OPTIONS: OPTIONS,
      backgroundSticky: false,
      questionSticky: false,
    };
  }

  componentDidMount = async () => {
    this.setState({
      loading: true,
    });
    this.fetchAccountDetails();
    this.fetchAndSaveUserDetails();
    let querySearchParams = this.props.history.location.search;
    let params = new URLSearchParams(querySearchParams);
    let subjectId = params.get("subjectId");

    if (subjectId) {
      this.setState({
        subjectId: subjectId,
      });
      try {
        let subjectData = await window.Arena.database.fetchSubjectWithId(
          subjectId
        );
        if (subjectData?.data?.canAdminister) {
          window.canAdministerSubject = true;
        } else {
          window.canAdministerSubject = false;
        }
        let image =
          subjectData &&
          subjectData.data &&
          subjectData.data.cover &&
          subjectData.data.cover.original;
        this.backgroundImg = image || "/assets/img/header.png";
        this.setState({
          subjectData: subjectData.data,
          min: subjectData.data && subjectData.data.allocation.min,
          max: subjectData.data && subjectData.data.allocation.max,
          step: subjectData.data && subjectData.data.allocation.step,
          total: subjectData.data && subjectData.data.allocation.total,
          maxValue:
            subjectData.data && subjectData.data.allocation.options.length,
        });
        if (
          subjectData &&
          subjectData.data &&
          subjectData.data.isVoted === true
        ) {
          this.getAndDownloadReport(subjectId);
        } else {
          this.mapSliders(
            subjectData.data,
            subjectData.data && subjectData.data.allocation.min
          );
        }
      } catch (e) {
        console.error(e);
        this.setState({
          loading: false,
        });
        window.NotificationUtils.showError(window.t("somethingWentWrong"));
        this.props.history.replace(`/404`);
      }
    } else {
      this.props.history.replace(`/404`);
    }
  };

  fetchAccountDetails = () => {
    let accountDetails = Auth.getAccountDetails();
    this.setState({
      accountDetails: accountDetails,
    });
    return accountDetails;
  };

  fetchAndSaveUserDetails = () => {
    let userDetails = Auth.getUserDetails();
    this.setState({
      userDetails: userDetails,
    });
    return userDetails;
  };

  getAndDownloadReport = async (subjectId) => {
    try {
      let report = await window.Arena.database.fetchSubjectReport(subjectId);
      let options = JSON.parse(JSON.stringify(this.state.OPTIONS));
      let reportData = report.data;
      let optionsData = [];
      let colors = [];
      for (let [index, singleReport] of reportData.entries()) {
        // let randomColor = tinyColor.random().toHexString();
        let randomColor = HIGHCHART_COLORS[index];
        this.setState({
          [`color_${singleReport.name}`]: randomColor,
        });
        colors.push(randomColor);
        let singleOption = [
          `${singleReport.name}`,
          singleReport.value,
          `${randomColor}`,
        ];
        optionsData.push(singleOption);
      }
      options.series[0].data = optionsData;
      Highcharts.setOptions({
        colors: colors,
      });
      this.setState({
        reportData,
        OPTIONS: options,
        loading: false,
      });
      // await window.Arena.database.downloadSubjectReport(subjectId)
    } catch (e) {
      console.error(e);
    }
  };

  fetchAndSaveUserDetails = () => {
    let userDetails = Auth.getUserDetails();
    this.setState({
      userDetails: userDetails,
    });
    return userDetails;
  };

  mapSliders = (data, min) => {
    let colours = [];
    data.allocation.options.map((item, index) => {
      // let randomColor = tinyColor.random().toHexString();
      let randomColor = HIGHCHART_COLORS[index];
      colours.push(randomColor);
      this.setState({
        [item.name]: min,
        [`color_${item.name}`]: randomColor,
      });
    });
    colours.push("#ffffff");
    Highcharts.setOptions({
      colors: colours,
    });

    this.updateChart();
  };

  onChangeSlider = (value, type) => {
    let dataValue = Number.parseFloat(value).toPrecision(3);
    let totalAllocation = this.getTotalAllocated();
    let latestTotalAllocation = this.calculateNewAllocation(
      dataValue,
      type,
      totalAllocation
    );
    if (latestTotalAllocation > this.state.total) {
      return;
    } else {
      this.setState(
        {
          [type]: dataValue,
        },
        this.updateChart
      );
    }
  };

  calculateNewAllocation = (value, type, previousTotal) => {
    let latestAllocation;
    let typeValue = this.state[type];
    if (value >= typeValue) {
      let diff = value - typeValue;
      latestAllocation = previousTotal + diff;
    }
    if (value < typeValue) {
      let diff = typeValue - value;
      latestAllocation = previousTotal - diff;
    }
    return latestAllocation;
  };

  getTotalAllocated = () => {
    let total = 0;
    this.state.subjectData &&
      this.state.subjectData.allocation.options.map((item, index) => {
        total = total + Number(this.state[item.name]);
      });
    return total;
  };

  updateChart = () => {
    let unallocated = ["UnAllocated", this.getUnallocatedValue(), "#ffffff"];
    if (this.state.total - unallocated === 0) {
      return;
    }
    let data = [];

    this.state.subjectData &&
      this.state.subjectData.allocation.options.map((item, index) => {
        let option = [
          `${item.name}`,
          this.mapValuePercent(this.state[item.name]),
          this.state[`color_${item.name}`],
        ];
        data.push(option);
      });
    data.push(unallocated);
    let stateOptions = JSON.parse(JSON.stringify(this.state.OPTIONS));
    stateOptions.series[0].data = data;

    this.setState({
      OPTIONS: stateOptions,
      loading: false,
    });
  };

  mapValuePercent = (value) => {
    let maxValue = this.state.total && this.state.total;
    let pieValue = value / maxValue;
    pieValue = pieValue * 100;
    return pieValue;
  };

  getUnallocatedValue = () => {
    let allocatedValue = 0;
    let maxValue = this.state.total && this.state.total;
    this.state.subjectData &&
      this.state.subjectData.allocation.options.map((item, index) => {
        allocatedValue = allocatedValue + Number(this.state[item.name]);
      });
    let unAllocatedValue = maxValue - allocatedValue;
    unAllocatedValue = unAllocatedValue / maxValue;
    unAllocatedValue = unAllocatedValue * 100;
    return unAllocatedValue;
  };

  checkErrors = () => {
    let unallocatedValue = this.getUnallocatedValue();
    if (unallocatedValue > 0) {
      return true;
    }
    return false;
  };

  sendAllocation = async () => {
    let isError = this.checkErrors();
    if (isError) {
      return window.NotificationUtils.showError(
        window.t("cannotSaveUnallocatedValues")
      );
    }
    window.NotificationUtils.showInfo(window.t("savingYourAllocations"));
    let allocation = [];
    let allocationCopy = Object.assign(
      [],
      this.state.subjectData.allocation.options
    );
    allocationCopy.map((item, index) => {
      let data = {
        option: item.id,
        value: Number(this.state[item.name]),
      };
      allocation.push(data);
    });
    let allocationData = {};
    allocationData.allocation = allocation;
    try {
      await window.Arena.database.voteOnSubject(
        allocationData,
        this.state.subjectId
      );
      window.NotificationUtils.showSuccess(
        window.t("allocationSavedSuccessfully")
      );
      window.location.reload();
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError(window.t("somethingWentWrong"));
    }
  };

  renderControlPanel = () => {
    if (isMobile) {
      return (
        <React.Fragment>
          <ControlPanel
            openEditMode={this.openEditModeSubject}
            history={this.props.history}
            placement="top"
            subjectText={window.t("editSubject")}
            t={this.props.t}
            onClickDownloadReport={this.onClickDownloadReport}
            subjectId={this.state.subjectId}
            showSearch={true}
            categoryId={this.state.subjectData?.category?.id}
          />
        </React.Fragment>
      );
    }
    if (isBrowser) {
      return (
        <React.Fragment>
          <ControlPanel
            openEditMode={this.openEditModeSubject}
            history={this.props.history}
            placement="left"
            subjectText={window.t("editSubject")}
            t={this.props.t}
            onClickDownloadReport={this.onClickDownloadReport}
            subjectId={this.state.subjectId}
            showSearch={true}
            categoryId={this.state.subjectData?.category?.id}
          />
        </React.Fragment>
      );
    }
  };

  openEditModeSubject = () => {
    if (!Auth.getAdminFlag() && !window.canAdministerSubject) {
      window.NotificationUtils.showInfo(window.t("editSubjectNotAllowed"));
      return;
    }
    let subjectId = this.state.subjectData && this.state.subjectData.id;
    this.props.history.push(`edit-subject?subjectId=${subjectId}`);
  };

  onClickAvatar = () => {
    let accountDetails = Auth.getAccountDetails();
    this.goBack(
      `/${accountDetails.slug}/category-discussion?categoryId=${this.state.subjectData.category.id}`
    );
  };
  sendMessage = () => {
    let subjectId = this.state.subjectData.id && this.state.subjectData.id;
    this.props.history.push(`send-message?subjectId=${subjectId}`);
  };
  onClickShareButton = () => {
    let url = `${WEBSITE_URL}${this.state.accountDetails.slug}/allocation?subjectId=${this.state.subjectData.id}`;
    window.ShareModal.showShareModal(url);
  };

  getCategoryImageUrl = () => {
    let categoryData =
      this.state.subjectData && this.state.subjectData.category;
    if (categoryData && categoryData.icon && categoryData.icon.sizes) {
      return categoryData.icon.sizes["240x240"];
    }
    return "";
  };

  renderPopoverContent = () => {
    let attachmentFiles =
      this.state.subjectData && this.state.subjectData.files;
    if (attachmentFiles && attachmentFiles.length < 1) {
      return (
        <Typography className="whiteColor">
          {window.t("noAttachedFiles")}
        </Typography>
      );
    }
    return (
      attachmentFiles &&
      attachmentFiles.map((file) => {
        return (
          <Link
            className="displayFlex marginBottom8 blueColor justContentFlexEnd"
            href={file.url}
            target="_blank"
          >
            {file.name}
          </Link>
        );
      })
    );
  };

  onClickAttachmentsButton = () => {
    this.setState({
      showAttachmentPopover: true,
    });
  };

  onClickIconButton = () => {
    let showPopover = false;
    if (this.state.showAttachmentPopover) {
      showPopover = false;
    } else {
      showPopover = true;
    }
    this.setState({
      showAttachmentPopover: showPopover,
    });
  };

  renderTopContent = () => {
    let attachmentFiles =
      this.state.subjectData && this.state.subjectData.files;
    let role = Auth.getUserRole();

    return (
      <React.Fragment>
        <div className="discussSubjectTopMainContainer">
          <div className="discussSubjectPageTopContent">
            <ClientNameLarge
              imgURL={this.getCategoryImageUrl()}
              name={this.state.subjectData && this.state.subjectData.name}
              isLarge={true}
              onClickAvatar={this.onClickAvatar}
              ariaLabel={window.t("backToCategory")}
            />
          </div>
          <div id="headerInfoContainer" className="reverse">
            <div className="discussSubjecHeaderInfo reverse">
              {attachmentFiles && attachmentFiles.length > 0 && (
                <IconAndText
                  id={"attachments"}
                  text={window.t("attachments")}
                  value={AttchedFile}
                  onClick={this.onClickAttachmentsButton}
                />
              )}
              {role === USER_ROLE.USER && (
                <IconAndText
                  text={window.t("message")}
                  value={Message}
                  onClick={this.sendMessage}
                />
              )}
              <IconAndText
                text={window.t("comments")}
                istext={true}
                value={
                  (this.state.subjectData &&
                    this.state.subjectData.activityCount) ||
                  0
                }
              />
              <IconAndText
                text={window.t("share")}
                value={Share}
                onClick={this.onClickShareButton}
              />
            </div>
          </div>
          {attachmentFiles && attachmentFiles.length > 0 && (
            <ArenaPopover
              className="arenaPopover attachmentPopover"
              toggle={this.onClickIconButton}
              history={this.props.history}
              placement={"bottom"}
              isOpen={this.state.showAttachmentPopover}
              target={"#attachments"}
            >
              <div>{this.renderPopoverContent()}</div>
            </ArenaPopover>
          )}
        </div>
      </React.Fragment>
    );
  };

  renderAllocationLegends = () => {
    return (
      this.state.subjectData &&
      this.state.subjectData.allocation.options.map((item, index) => {
        return (
          <div className="allocationPageLegendContainer">
            <AllocationLegend
              text={`${item.name}`}
              color={this.state[`color_${item.name}`]}
            />
          </div>
        );
      })
    );
  };

  getUserName = () => {
    let role = this.state.userDetails && this.state.userDetails.role;
    switch (role) {
      case USER_ROLE.SYSTEM_ADMIN:
      case USER_ROLE.USER:
        return this.state.userDetails && this.state.userDetails.name;
      case USER_ROLE.GUEST:
        return window.t("guest");
      default:
        return window.t("guest");
    }
  };

  renderPageChartDetailContainer = () => {
    let isVoted = this.state.subjectData && this.state.subjectData.isVoted;
    return (
      <React.Fragment>
        <div id="chartDetailContainer">
          <div style={{ marginBottom: "24px" }}>
            <UserPill user={this.getUserName()} />
            {!isVoted && (
              <Typography
                variant="body1"
                className="dullWhite textAlignEnd"
                style={{ marginTop: "32px" }}
                tabIndex={0}
                aria-label={window.t("allocationSubjectNote")}
              >
                {window.t("allocationSubjectNote")}
              </Typography>
            )}
          </div>
          {isVoted && this.renderThankyouNote()}
          {this.renderAllocationLegends()}
          <div className="allocationPageLegendContainer">
            <AllocationLegend
              text={window.t("unallocated")}
              color={"#ffffff"}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderSlider = () => {
    return (
      this.state.subjectData &&
      this.state.subjectData.allocation.options.map((item, index) => {
        return (
          <div className="allocationPageSliderContainer">
            <ArenaSlider
              step={this.state.step}
              min={this.state.min}
              max={this.state.max}
              color={this.state[`color_${item.name}`]}
              value={this.state[item.name]}
              onChange={(value) => {
                this.onChangeSlider(value, item.name);
              }}
              name={item.name}
            />
          </div>
        );
      })
    );
  };

  renderThankyouNote = () => {
    return (
      <Typography
        tabIndex={0}
        aria-label={window.t("thankyouForVote")}
        variant="h5"
        className="greenColor bold textAlignCenter"
      >
        {window.t("thankyouForVote")}
      </Typography>
    );
  };

  closeDownloadConfirmModal = () => {
    this.setState({
      showDownloadConfirmModal: false,
    });
  };
  renderDownloadConfirmModal = () => {
    return (
      <ArenaModal
        text={`${window.t("downloadReportText")} ${
          this.state.subjectData.name
        } ?`}
        openArenaModal={this.state.showDownloadConfirmModal}
        handleArenaModalClose={this.closeDownloadConfirmModal}
        secondaryButtonText={window.t("cancel")}
        onClickSecondaryButton={this.closeDownloadConfirmModal}
        onClickPrimaryButton={this.getDownloadReport}
        headerTitle={window.t("downloadReport")}
      />
    );
  };

  getDownloadReport = async () => {
    let result = await window.Arena.database.downloadDiscussSubjectReport(
      this.state.subjectId
    );
    FileSaver.saveAs(result.data, `report-${this.state.subjectId}.xlsx`);
    this.setState({
      showDownloadConfirmModal: false,
    });
  };

  onClickDownloadReport = () => {
    this.setState({
      showDownloadConfirmModal: true,
    });
  };

  renderBottomContent = () => {
    let isVoted = this.state.subjectData && this.state.subjectData.isVoted;
    let date = moment.utc(new Date());

    let startDate = moment.utc(
      this.state.subjectData && this.state.subjectData.startDate
    );
    if (this.state.subjectData?.intermediateReport && isVoted) {
      return this.renderAllocationConclusion();
    }
    if (startDate > date) {
      return this.renderEmptyPlaceholder();
    }
    return (
      <React.Fragment>
        <div id="concusionPageBottomMainContainer">
          <div className={`conclusionPageTopContent`}>
            <div
              className="marginBottom12 reverse"
              id="conclusionPageHeaderHeading"
            >
              <Typography
                role="heading"
                aria-level="1"
                variant="h6"
                className="redColor bold textAlignEnd"
              >
                {window.t("subjectKind")}
              </Typography>
            </div>
            <HeadWithImg
              imgURL="/assets/img/question.png"
              text={this.state.subjectData && this.state.subjectData.question}
            />
          </div>
          <div className="allocationPageContainer marginTop-16">
            <div id="allocationPageReadMoreContainer">
              <ReadMore
                t={this.props.t}
                text={
                  this.state.subjectData &&
                  renderHTML(this.state.subjectData.description)
                }
              />
            </div>

            <div id="allocationPageChartMainContainer" className="reverse">
              <div id="allocationPageChatDetailContainer">
                {this.renderPageChartDetailContainer()}
              </div>
              <div id="allocationPageChartContainer">
                {/* <Pie3D config={CONFIG} data={DATA} /> */}
                <HighchartsReact
                  highcharts={Highcharts}
                  options={this.state.OPTIONS}
                />

                {!isVoted && this.renderSlider()}
              </div>
            </div>
            {!isVoted && (
              <div id="allocationSendButton">
                <SendButton size={"small"} onClick={this.sendAllocation} />
              </div>
            )}
          </div>
        </div>
        {this.renderControlPanel()}
        {this.state.showDownloadConfirmModal &&
          this.renderDownloadConfirmModal()}
      </React.Fragment>
    );
  };
}
