import React from "react";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ArenaButton from "../arenaButton/arenaButton";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import ArenaInputField from "../arenaInputField/arenaInput";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Auth from "../../common/auth";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
class UpdateDetailsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: null,
      email: null,
      checkErrors: false,
      publicNotice: false,
    };
  }

  componentDidMount() {
    let userDetails = Auth.getUserDetails();
    window.addEventListener("keypress", this.keyPressed);

    this.setState({
      fullName: userDetails.name,
      email: userDetails.email,
      publicNotice: userDetails.allowEmails,
      userDetails,
    });
  }

  keyPressed = (event) => {
    if (event.key === "Enter") {
      this.onClickSaveButton();
    }
  };

  componentWillUnmount() {
    window.removeEventListener("keypress", this.keyPressed);
  }

  errorInFullName = () => {
    return !this.state.fullName;
  };

  errorInEmail = () => {
    return !this.state.email;
  };

  handleCheckboxChange = (e) => {
    let value = e.target.checked;
    this.setState({
      publicNotice: value,
    });
  };

  onClickSaveButton = async () => {
    let data = {
      name: this.state.fullName,
      email: this.state.email,
      role: this.state.userDetails.role,
      allowEmails: this.state.publicNotice,
    };

    try {
      let response = await window.Arena.database.updateUserDetail(
        data,
        this.state.userDetails.id
      );

      window.NotificationUtils.showSuccess(
        window.t("detailsUpdatedSuccessfully")
      );
      Auth.storeUserDetails(response.data);
      this.props.closeUpdateModal();
      this.props.history.go(0);
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError(window.t("somethingWentWrong"));
    }
  };

  onChangeName = (e) => {
    this.setState({
      fullName: e.target.value,
    });
  };

  render() {
    return (
      <React.Fragment>
        {/* <Dialog
          aria-labelledby="updateModal"
          aria-modal="true"
          className="socialShareModal"
          onClose={this.props.closeUpdateModal}
          open={this.props.open}
        > */}
        <DialogTitle className="redColor bold textAlignCenter" id="updateModal">
          {window.t("editProfile")}
        </DialogTitle>
        <IconButton
          aria-label={`${window.t("close")} ${window.t("editProfile")}`}
          className="changePasswordCloseIcon"
          onClick={this.props.closeUpdateModal}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent id="updateModalDialogContent">
          <div className="textAlignEnd reverse">
            <div>
              <Typography
                role="heading"
                aria-level="3"
                variant="body1"
                className="dullWhite"
              >
                {window.t("fullName")}
              </Typography>
            </div>
            <div className="updateModalTextField">
              <ArenaInputField
                className="updateModalInput"
                placeholder={window.t("fullName")}
                onChange={this.onChangeName}
                value={this.state.fullName}
                error={this.state.checkErrors && this.errorInFullName()}
                autoFocus={true}
                ariaLabelText={"full name"}
              />
            </div>
            <div>
              <Typography
                role="heading"
                aria-level="3"
                variant="body1"
                className="dullWhite"
              >
                {window.t("email")}
              </Typography>
            </div>
            <div className="updateModalTextField">
              <ArenaInputField
                className="updateModalInput disabledField"
                placeholder={window.t("email")}
                onChange={this.onChangeEmail}
                value={this.state.email}
                error={this.state.checkErrors && this.errorInEmail()}
                disabled
              />
            </div>
            <div className="editSubjectCheckBoxContainer">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.publicNotice}
                    onClick={this.handleCheckboxChange}
                    name="checkedB"
                    color="primary"
                    value={this.state.publicNotice}
                    inputProps={{
                      "aria-label": `${window.t("publicParticipationNotice")} ${
                        this.state.publicNotice
                      }`,
                    }}
                  />
                }
                label={window.t("publicParticipationNotice")}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions
          id="updateModalActionContainer"
          className="flexDirectionRowReverse"
        >
          <ArenaButton
            id="changePasswordUpdateModalButton"
            extraClass="arenaModalActionButton marginRight30 changePasswordButton reverse"
            text={window.t("changePassword")}
            onClick={this.props.onClickChangePassword}
            startIcon={<ArrowBackIosIcon />}
            size={"small"}
          />

          <ArenaButton
            id="updateModalSaveButton"
            extraClass="arenaModalActionButton"
            text={window.t("save")}
            onClick={this.onClickSaveButton}
            size={"small"}
          />
        </DialogActions>
        {/* </Dialog> */}
      </React.Fragment>
    );
  }
}

export default UpdateDetailsModal;
