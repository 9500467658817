import React from "react";
import ArenaInputField from "../arenaInputField/arenaInput";
import SendButton from "../sendButton/sendButton";
import Auth from "../../common/auth";
import { Typography } from "@material-ui/core";

export default class SearchComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: props.searchedString || null,
    };
  }

  onChangeSearchField = (e) => {
    this.setState({
      searchText: e.target.value,
    });
  };

  onClickSearchButton = () => {
    if (!this.state.searchText) {
      return;
    }
    let accountDetails = Auth.getAccountDetails();
    if (this.props.searchedString) {
      this.props.history.replace(
        `/${accountDetails.slug}/search?search=${this.state.searchText}&categoryId=${this.props.categoryId}`
      );
    } else {
      this.props.history.push(
        `/${accountDetails.slug}/search?search=${this.state.searchText}&categoryId=${this.props.categoryId}`
      );
    }
    this.props.history.go(0);
  };

  render() {
    return (
      <React.Fragment>
        <Typography
          variant="h6"
          className="redColor bold textAlignCenter marginBottom8 reverse"
        >
          {window.t("search")}
        </Typography>
        <ArenaInputField
          placeholder={window.t("search")}
          onChange={this.onChangeSearchField}
          value={this.state.searchText}
        />
        <div id="searchComponentSendButton">
          <SendButton
            text={window.t("findMe")}
            size={"small"}
            onClick={this.onClickSearchButton}
          />
        </div>
      </React.Fragment>
    );
  }
}
