import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { isBrowser, isMobile } from "react-device-detect";
import { isEmail } from "validator";
import { USER_ROLE } from "../../../constants";
import SocialLogin from "../../../utils/socialAuth";
import Auth from "../../common/auth";
import CommonStructure from "../../common/mainStructure/main";
import ArenaInputField from "../../ui/arenaInputField/arenaInput";
import PasswordField from "../../ui/arenaPasswordField/passwordField";
import SocialButton from "../../ui/arenaSocialButton/arenaSocialButton";
import ControlPanel from "../../ui/controlPanel/controlPanel";
import SendButton from "../../ui/sendButton/sendButton";
const jwtDecode = require("jwt-decode");

export default class Signup extends CommonStructure {
  constructor(props) {
    super(props);
    this.state = {
      fullName: "",
      email: "",
      password: "",
      checkErrors: false,
      accountDetails: null,
    };
    this.backgroundImg = "/assets/img/header.png";
    this.onChange_fullName = this.onChange.bind(this, "fullName");
    this.onChange_email = this.onChange.bind(this, "email");
    this.onChange_password = this.onChange.bind(this, "password");
  }

  componentDidMount() {
    let accountDetails = Auth.getAccountDetails();
    window.addEventListener("keypress", this.keyPressed);

    // if(!accountDetails){
    //     setTimeout(()=>{
    //     window.NotificationUtils.showError("Account Slug Details not found")

    //     }, 2000)
    //     return
    // }
    this.setState({
      accountDetails,
    });
  }

  keyPressed = (event) => {
    if (event.key === "Enter") {
      this.signup();
    }
  };

  componentWillUnmount() {
    window.removeEventListener("keypress", this.keyPressed);
  }
  onChange(key, event, value) {
    let update = {};
    update[key] = event.target.value || (value ? value.newValue : "");
    this.setState(update);
  }

  renderTopContent = () => {
    return (
      <div className="signupLoginTopContent">
        <img src="/assets/img/logoArena.png" alt="arenaLogo" className="logo" />
      </div>
    );
  };

  errorInEmail = () => {
    return !this.state.email || !isEmail(this.state.email);
  };

  errorInName = () => {
    return !this.state.fullName || this.state.fullName.length < 4;
  };

  errorInPassword = () => {
    let passwordChecks = new RegExp("^(?=.*?[A-Z])(?=.*?[0-9]).{6,}$");
    return !this.state.password || !passwordChecks.test(this.state.password);
  };

  checkErrors() {
    let passwordChecks = new RegExp("^(?=.*?[A-Z])(?=.*?[0-9]).{6,}$");
    if (!this.state.fullName) {
      window.NotificationUtils.showError(window.t("enterName"));
      return true;
    }
    if (this.state.fullName.length < 4) {
      window.NotificationUtils.showError(window.t("enterNameLength"));
      return true;
    }
    if (!this.state.email || !isEmail(this.state.email)) {
      window.NotificationUtils.showError(window.t("invalidEmail"));
      return true;
    }
    if (!this.state.password || !passwordChecks.test(this.state.password)) {
      window.NotificationUtils.showError(window.t("invalidPasswordLogin"));
      return true;
    }
    return false;
  }

  checkUserAndRedirect = async (response) => {
    if (response && response.refreshToken) {
      Auth.storeUserData(response);
      let userDetails = await this.getUserData();
      Auth.storeUserDetails(userDetails);
      let accountDetails = Auth.getAccountDetails();

      if (!accountDetails) {
        window.NotificationUtils.showError(window.t("slugDetailNotFound"));
        return;
      }
      let isAdmin = await this.checkForUserValidation(
        userDetails,
        accountDetails
      );
      Auth.storeAdminFlag(isAdmin);
      if (isAdmin) {
        window.role = USER_ROLE.SYSTEM_ADMIN;
        window.NotificationUtils.showSuccess(window.t("welcomeToArena"));
        this.props.history.push(`/${accountDetails.slug}`);
      } else {
        window.role = USER_ROLE.USER;
        window.NotificationUtils.showSuccess(window.t("welcomeToArena"));
        this.props.history.push(`/${accountDetails.slug}`);
      }
      if (window.role !== USER_ROLE.GUEST) {
        window.chats.fetchChats = async () => {
          let chats = await this.fetchChats();
          return chats;
        };
      }
    }
  };

  getRecaptchaToken = async () => {
    const token = await this.props.googleReCaptchaProps.executeRecaptcha(
      "signup"
    );
    return token;
  };
  signup = async () => {
    this.setState({
      checkErrors: true,
    });
    if (this.checkErrors()) {
      return;
    }
    window.NotificationUtils.showInfo(window.t("initiatingSignup"));
    let user = {
      name: this.state.fullName,
      email: this.state.email,
      password: this.state.password,
      account: this.state.accountDetails.id, //This id should be of parent client
    };
    try {
      let token = await this.getRecaptchaToken();
      if (!token) {
        throw new Error();
      }
      user.recaptchaToken = token;

      let response = await window.Arena.database.signup(user);
      await this.checkUserAndRedirect(response);

      this.setState({
        checkErrors: false,
      });
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError(e.response.data.message);
      this.setState({
        checkErrors: false,
      });
    }
  };

  fetchChats = async () => {
    let response = await window.Arena.database.fetchChats();
    if (
      response &&
      response.data &&
      response.data.items &&
      response.data.items.length > 0
    ) {
      return response.data.items;
    } else {
      return null;
    }
  };

  checkForUserValidation = async (userDetails, accountDetails) => {
    let response = await window.Arena.database.fetchAccountDetailsForAdmin(
      accountDetails.slug
    );
    let details = response && response.data.items[0];
    if (details.canAdminister) {
      return true;
    }
    return false;
  };

  getUserData = async () => {
    let userData = this.getUserId();
    if (!userData) {
      this.setState({
        loading: false,
      });
      return;
    }
    try {
      let response = await window.Arena.database.fetchUserWithId(
        userData.userId
      );
      return response.data;
    } catch (e) {
      console.error(e);
      this.setState({
        loading: false,
      });
    }
  };

  getUserId = () => {
    let accessToken = Auth.getToken();
    if (!accessToken) {
      return null;
    }
    let userData = jwtDecode(accessToken);
    return userData;
  };

  redirectToLogin = () => {
    this.props.history.push("login");
  };

  onClickFacebook = () => {
    SocialLogin.loginFacebook()
      .then(async (result) => {
        if (result && result.refreshToken) {
          await this.checkUserAndRedirect(result);
        }
      })
      .catch((e) => {
        console.error(e);
        e && window.NotificationUtils.showError(e.message);
      });
  };

  onClickGoogle = () => {
    SocialLogin.loginGoogle()
      .then(async (result) => {
        if (result && result.refreshToken) {
          await this.checkUserAndRedirect(result);
        }
      })
      .catch((e) => {
        console.error(e);
        e && window.NotificationUtils.showError(e.message);
      });
  };

  renderControlPanel = () => {
    if (isMobile) {
      return (
        <React.Fragment>
          <ControlPanel
            history={this.props.history}
            placement="top"
            showAuthControlPanel={true}
          />
        </React.Fragment>
      );
    }
    if (isBrowser) {
      return (
        <React.Fragment>
          <ControlPanel
            history={this.props.history}
            placement="left"
            showAuthControlPanel={true}
          />
        </React.Fragment>
      );
    }
  };

  handleVerify = (token) => {
    this.setState({
      recaptchaToken: token,
    });
  };

  renderBottomContent = () => {
    return (
      <div className="loginSignupOverlap">
        <div className="flexCenter loginToSystemText">
          <Typography variant="h4" className="whiteColor bold zIndex">
            {window.t("signupToSystem")}
          </Typography>
        </div>
        <div
          className="flexCenter signupSocialNetworkText"
          style={{ marginBottom: "26px" }}
        >
          <Typography variant="body1" className="whiteColor">
            {window.t("usingSocialNetwork")}
          </Typography>
        </div>

        <div className="displayFlex reverse justifyContentCenter">
          <div className="marginRight16">
            <SocialButton
              onClick={this.onClickFacebook}
              size={"medium"}
              buttonType="facebook"
            />
          </div>
          <div className="marginLeft16">
            <SocialButton
              onClick={this.onClickGoogle}
              size={"medium"}
              buttonType="google"
            />
          </div>
        </div>
        <div className="diplayFlex loginDividerContainer">
          <Divider
            aria-hidden="true"
            style={{
              backgroundColor: "white",
              width: "40%",
              height: "1px",
              opacity: "0.5",
              marginRight: "8px",
            }}
          />
          <Typography variant="body1" className="dullWhite">
            {window.t("or")}
          </Typography>
          <Divider
            aria-hidden="true"
            style={{
              backgroundColor: "white",
              width: "40%",
              height: "1px",
              opacity: "0.5",
              marginLeft: "8px",
            }}
          />
        </div>
        <div className="flexCenter loginInfoTextMargin">
          <Typography variant="body1" className="dullWhite">
            {window.t("fillYourDetails")}
          </Typography>
        </div>
        <div className="loginPageEmailLink reverse">
          <Typography variant="body1" className="dullWhite">
            {window.t("fullName")}
          </Typography>
        </div>
        <div className="loginInputFieldContainer">
          <div className="fieldWidth">
            <ArenaInputField
              placeholder={window.t("fullName")}
              onChange={this.onChange_fullName}
              value={this.state.fullName}
              helperText={
                this.state.checkErrors &&
                this.errorInName() &&
                window.t("invalidName")
              }
              error={this.state.checkErrors && this.errorInName()}
            />
          </div>
        </div>

        <div className="loginPageEmailLink reverse">
          <Typography variant="body1" className="dullWhite">
            {window.t("email")}
          </Typography>
        </div>
        <div className="loginInputFieldContainer">
          <div className="fieldWidth">
            <ArenaInputField
              placeholder={window.t("email")}
              onChange={this.onChange_email}
              helperText={
                this.state.checkErrors &&
                this.errorInEmail() &&
                window.t("invalidEmail")
              }
              error={this.state.checkErrors && this.errorInEmail()}
              value={this.state.email}
            />
          </div>
        </div>

        <div className="loginPageEmailLink reverse">
          <Typography variant="body1" className="dullWhite">
            {window.t("password")}
          </Typography>
        </div>
        <div className="loginInputFieldContainer">
          <div className="fieldWidth">
            <PasswordField
              placeholder={window.t("password")}
              onChange={this.onChange_password}
              error={this.state.checkErrors && this.errorInPassword()}
              helperText={
                this.state.checkErrors &&
                this.errorInPassword() &&
                window.t("invalidPassword")
              }
              value={this.state.password}
            />
          </div>
        </div>
        <div id="loginSendButtonContainer">
          <SendButton
            size={"small"}
            onClick={this.signup}
            text={window.t("letsStart")}
          />
        </div>
        {this.renderControlPanel()}
      </div>
    );
  };
}
