import React, { Component } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";

export default class CommentClientName extends Component {
  render() {
    return (
      <React.Fragment>
        <ListItem
          style={{ background: `${this.props.backgroundColor}` }}
          className="commentNameContainer reverse"
        >
          <ListItemAvatar>
            <Avatar
              alt="Aemy Sharp"
              src={this.props.iconURL || "/assets/img/client.png"}
            />
          </ListItemAvatar>
          <ListItemText
            tabIndex={0}
            aria-label={this.props.name}
            className="commentNameText reverse"
            primary={this.props.name}
          />
        </ListItem>
      </React.Fragment>
    );
  }
}
