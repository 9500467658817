import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import SendButton from "../sendButton/sendButton";

export default class SearchDisplayCard extends Component {
  renderActionButton = () => {
    return (
      <div className="activeDiscussionActionContainer">
        <div
          className="discussCardButton"
          style={{
            marginTop: `${this.props.isActiveDiscussion && "16px"}`,
            display: "flex",
            justifyContent: "end",
          }}
        >
          <SendButton size={"small"} text={window.t("toSubject")} />
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="categoryDiscussCardContainer"
          onClick={this.props.onClick}
          style={{
            background: `${this.props.backgroundColor}`,
            cursor: "pointer",
          }}
        >
          <div className="discussCardImgContainer">
            <img
              src={
                (this.props.data &&
                  this.props.data.cover &&
                  this.props.data.cover.original) ||
                "/assets/img/header.png"
              }
              alt="meeting"
              style={{
                width: "100%",
                height: "146px",
                borderRadius: "4px",
              }}
            />
          </div>
          <div className="discussCardContent ">
            <Typography
              role="heading"
              aria-level="2"
              tabIndex="0"
              variant="h6"
              className="whiteColor bold textAlignEnd lineHeightOne reverse searchCardTitle"
            >
              {this.props.data && this.props.data.name}
            </Typography>
            {this.renderActionButton()}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
