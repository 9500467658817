import React, { Component } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
export default class ArenaDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null, //TODO I
    };
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    this.props.onChange(selectedOption);
  };

  render() {
    if (this.props.isAsync) {
      return (
        <React.Fragment>
          <AsyncSelect
            {...this.props}
            //  menuIsOpen={true}
            cacheOptions
            defaultOptions
            value={this.props.selectedOption}
            loadOptions={this.props.loadOptions}
            onChange={this.handleChange}
            className="arena-dropdown-container flexDirectionRow"
            classNamePrefix="arena-dropdown"
            error={true}
            aria-describedby="dropDown"
            aria-label="Arena Dropdown"
          />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Select
          {...this.props}
          value={this.props.selectedOption}
          onChange={this.handleChange}
          // menuIsOpen={true}
          options={this.props.options}
          className="arena-dropdown-container flexDirectionRow"
          classNamePrefix="arena-dropdown"
          error={true}
          aria-describedby="dropDown"
          aria-label="Arena Dropdown"
        />
      </React.Fragment>
    );
  }
}
