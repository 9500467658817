export function openWindow(
  url,
  name,
  target = "popup",
  features,
  position = "center"
) {
  // innerWidth
  // innerHeight
  // screenX
  // screenY

  const featuresObj = {
    // Firefox only outerWidth outerHeight aren't supported
    width: 640,
    height: 480,
    innerWidth: null,
    innerHeight: null,
    left: null,
    top: null,
    screenX: null,
    screenY: null,
    menubar: 0,
    toolbar: 1,
    location: 0,
    status: 1,
    resizable: 1,
    scrollbars: 1,
    noopener: 0,
    noreferrer: 0,
    ...features,
  };

  if (position && position === "center") {
    const left =
      (window.screen.width - (featuresObj.width || featuresObj.innerWidth)) / 2;
    const top =
      (window.screen.height - (featuresObj.height || featuresObj.innerHeight)) /
      2;

    featuresObj.left = left;
    featuresObj.top = top;

    featuresObj.screenX = left;
    featuresObj.screenY = top;
  }

  const convertFeatureValue = (value) => {
    if (value === false || value === "no") return 0;
    if (value === true || value === "yes") return 1;
    return value;
  };

  const featuresStr = Object.keys(featuresObj)
    .filter((key) => featuresObj[key] !== null)
    .map((key) => `${key}=${convertFeatureValue(featuresObj[key])}`)
    .join(",");
  const popup = window.open(url, name, featuresStr);
  return popup;
}
