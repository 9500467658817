import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import RichTextHtmlExport from "../richTextHtmlExport/richTextHtmlExport";
import draftToHtml from "draftjs-to-html";

export default class RichTextField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorState: null,
      contentState: null,
      htmlForm: false,
    };
  }
  async componentDidMount() {
    const htmlToDraft = await import("html-to-draftjs");
    const blocksFromHtml = htmlToDraft.default(this.props.content);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const editorState = EditorState.createWithContent(contentState);
    this.setState({
      editorState,
      contentState,
    });
  }
  showHtmlTextarea = () => {
    this.setState({
      htmlForm: !this.state.htmlForm,
    });
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
    let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    this.props.onChange && this.props.onChange(html);
  };
  render() {
    const { editorState } = this.state;
    return (
      <React.Fragment>
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={this.onEditorStateChange}
          toolbarCustomButtons={[
            <RichTextHtmlExport onClick={this.showHtmlTextarea} />,
          ]}
          toolbar={{
            options: [
              "list",
              "textAlign",
              "image",
              "link",
              "inline",
              "history",
            ],
            textAlign: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ["left", "center", "right", "justify"],
              left: {
                icon: "/assets/img/left-align.png",
                className: undefined,
              },
              center: {
                icon: "/assets/img/center-align.png ",
                className: undefined,
              },
              right: {
                icon: "/assets/img/right-align.png",
                className: undefined,
              },
              justify: {
                icon: "/assets/img/justification.png",
                className: undefined,
              },
            },
            list: {
              inDropdown: false,
              className: "borderRight",
              component: undefined,
              dropdownClassName: undefined,
              options: ["unordered", "ordered"],
              unordered: {
                icon: "/assets/img/unordered.png",
                className: undefined,
              },
              ordered: {
                icon: "/assets/img/ordered.png",
                className: undefined,
              },
            },
            link: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              popupClassName: undefined,
              dropdownClassName: undefined,
              showOpenOptionOnHover: true,
              defaultTargetOption: "_self",
              options: ["link", "unlink"],
              link: { icon: "/assets/img/link.png", className: undefined },
              unlink: { icon: "/assets/img/unlink.png", className: undefined },
              linkCallback: undefined,
            },
            image: {
              icon: "/assets/img/gallery.png",
              className: undefined,
              component: undefined,
              popupClassName: undefined,
              urlEnabled: true,
              uploadEnabled: true,
              alignmentEnabled: true,
              uploadCallback: undefined,
              previewImage: false,
              inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
              alt: { present: false, mandatory: false },
              defaultSize: {
                height: "auto",
                width: "auto",
              },
            },
            history: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ["undo", "redo"],
              undo: { icon: "/assets/img/undo.png", className: undefined },
              redo: { icon: "/assets/img/redo.png", className: undefined },
            },
            inline: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: [
                "bold",
                "italic",
                "underline",
                "strikethrough",
                "superscript",
                "subscript",
              ],
              bold: { icon: "/assets/img/bold.png", className: undefined },
              italic: { icon: "/assets/img/italic.png", className: undefined },
              underline: {
                icon: "/assets/img/underline.png",
                className: undefined,
              },
              strikethrough: {
                icon: "/assets/img/strikethrough.png",
                className: undefined,
              },
              superscript: {
                icon: "/assets/img/superscript.png",
                className: undefined,
              },
              subscript: {
                icon: "/assets/img/subscript.png",
                className: undefined,
              },
            },
          }}
          ariaLabel="Arena Editor"
          ariaDescribedBy="richTextFieldContainer"
        />
        {this.state.htmlForm && (
          <textarea
            id="richTextHtmlField"
            disabled
            value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
          />
        )}
      </React.Fragment>
    );
  }
}
