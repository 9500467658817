import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import FormatTextdirectionRToLIcon from "@material-ui/icons/FormatTextdirectionRToL";

export default class RichTextHtmlExport extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <IconButton onClick={this.props.onClick}>
          <FormatTextdirectionRToLIcon style={{ color: "white" }} />
        </IconButton>
      </React.Fragment>
    );
  }
}
