import React from "react";
import Typography from "@material-ui/core/Typography";
import CommonStructure from "../../common/mainStructure/main";
import { isBrowser, isMobile } from "react-device-detect";
import ControlPanel from "../../ui/controlPanel/controlPanel";
import Footer from "../../ui/footer/footer";
import { USER_ROLE } from "../../../constants";
import Link from "@material-ui/core/Link";

export default class Terms extends CommonStructure {
  renderControlPanel = () => {
    let role = window.role || USER_ROLE.GUEST;
    let isGuest = Boolean(role === USER_ROLE.GUEST);
    if (isMobile) {
      return (
        <React.Fragment>
          <ControlPanel
            history={this.props.history}
            placement="top"
            showAuthControlPanel={isGuest ? true : false}
          />
        </React.Fragment>
      );
    }
    if (isBrowser) {
      return (
        <React.Fragment>
          <ControlPanel
            history={this.props.history}
            placement="left"
            showAuthControlPanel={isGuest ? true : false}
          />
        </React.Fragment>
      );
    }
  };

  render = () => {
    return (
      <React.Fragment>
        <div id="termsContainer">
          <Typography className="dullWhite marginBottom48" variant="h4">
            תנאי השימוש באתר
          </Typography>
          <div className="displayFlex marginBottom24 marginTop24">
            <Typography className="dullWhite textAlignStart" variant="body2">
              1.
            </Typography>
            <Typography
              className="dullWhite textAlignStart marginRight8"
              variant="body2"
            >
              מטרת אתר האינטרנט arena-inql.co.il (להלן – "האתר") הינה מערכת
              ניהול תהליכי שיתוף ציבור בתהליכי קבלת החלטות אשר יוזמים גופים,
              בנושאים שונים המופיעים באתר.
            </Typography>
          </div>
          <div className="displayFlex marginBottom24">
            <Typography className="dullWhite textAlignStart" variant="body2">
              2.
            </Typography>
            <Typography
              className="dullWhite textAlignStart marginRight8"
              variant="body2"
            >
              ידוע למשתתף בתהליך שיתוף הציבור באתר (להלן -"המשתתף") כי הפעלת
              האתר והפלטפורמה הטכנולוגית לשיתוף הציבור מבוצעת על ידי חברת inQL
              בע"מ (להלן - "החברה") בעבור גורמים שונים ( להלן-"המשתף")
            </Typography>
          </div>
          <div className="displayFlex marginBottom24">
            <Typography className="dullWhite textAlignStart" variant="body2">
              3.
            </Typography>
            <Typography
              className="dullWhite textAlignStart marginRight8"
              variant="body2"
            >
              באתר עצמו בכלל, בתכנה / מערכת שפיתחה החברה, לרבות שמות וסימני מסחר
              של המערכת ו/או של החברה, קודים, קודי מחשב, טכנולוגיה ומידע
              טכנולוגי הכרוך בהפעלת האתר, יישומים, עיצוב וגרפיקה של האתר (אך לא
              של התכנים שיועלו באתר), הינם ויהיו בבעלותה הבלעדית של החברה.
            </Typography>
          </div>
          <div className="displayFlex marginBottom24">
            <Typography className="dullWhite textAlignStart" variant="body2">
              4.
            </Typography>
            <Typography
              className="dullWhite textAlignStart marginRight8"
              variant="body2"
            >
              באתר וכתיבה בו, מהווים הסכמת המשתתף לקחת חלק בתהליך שיתוף ציבור
              אשר יוזם המשתף.
            </Typography>
          </div>
          <div className="displayFlex marginBottom24">
            <Typography className="dullWhite textAlignStart" variant="body2">
              5.
            </Typography>
            <Typography
              className="dullWhite textAlignStart marginRight8"
              variant="body2"
            >
              ידוע למשתתף כי הנתונים והתכנים אשר ייאספו בתהליך שיתוף הציבור
              ישמשו את המשתף בתהליכי קבלות החלטות הנוגעות לסוגיות מגוונות.
            </Typography>
          </div>
          <div className="displayFlex marginBottom24">
            <Typography className="dullWhite textAlignStart" variant="body2">
              6.
            </Typography>
            <Typography
              className="dullWhite textAlignStart marginRight8"
              variant="body2"
            >
              כחלק מתהליך עיבוד המידע אשר ייאסף באתר יבצע המשתף תהליך "ניתוח
              תוכן" בו דברי הגולשים מקובצים למסקנות עיקריות. לפיכך ידוע כי תכנים
              שנכתבו על ידי המשתתף יכולים לעבור תהליך של עיבוד ושינוי בלי שתימסר
              על-כך הודעה מראש.
            </Typography>
          </div>
          <div className="displayFlex marginBottom24">
            <Typography className="dullWhite textAlignStart" variant="body2">
              7.
            </Typography>
            <Typography
              className="dullWhite textAlignStart marginRight8"
              variant="body2"
            >
              קטינים ובני נוער בני 18 ומטה אינם רשאים להירשם לאתר ללא הסכמת הורה
              או אפוטרופוס. אישור ההורה או האפוטרופוס יבוצע לאחר שהצהיר שקרא
              הבין והסכים לתנאי השימוש באתר, ולאחר שאישר כי יפקח על כל פעילות
              הקטין באתר.
            </Typography>
          </div>
          <div className="displayFlex marginBottom24">
            <Typography className="dullWhite textAlignStart" variant="body2">
              8.
            </Typography>
            <Typography
              className="dullWhite textAlignStart marginRight8"
              variant="body2"
            >
              בהרשמה כנציג תאגיד/בית עסק מצהיר המשתתף כי יש בסמכותו לייצג את
              התאגיד/בית העסק בתהליכי ההתייעצות השונים, וכי ידוע לתאגיד/בית העסק
              כי הפעולות והתגובות השונות תפורסמנה בשמו ומטעמו.
            </Typography>
          </div>
          <div className="displayFlex marginBottom24">
            <Typography className="dullWhite textAlignStart" variant="body2">
              9.
            </Typography>
            <Typography
              className="dullWhite textAlignStart marginRight8"
              variant="body2"
            >
              ידוע למשתתף כי האתר הינו פתוח לשימוש הציבור וכי ייתכן שיתועד
              בדרכים שונות, וירטואליות או אחרות.
            </Typography>
          </div>
          <div className="displayFlex marginBottom24">
            <Typography className="dullWhite textAlignStart" variant="body2">
              10.
            </Typography>
            <Typography
              className="dullWhite textAlignStart marginRight8"
              variant="body2"
            >
              משתתף אשר הזין כתובת דוא"ל או כל פרט מזהה אחר מביע בכך הסכמתו לקבל
              מהמשתף מידע הנוגע לתהליך ההתייעצות הנוכחי או לתהליכי התייעצות
              אחרים.
            </Typography>
          </div>
          <div className="displayFlex marginBottom24">
            <Typography className="dullWhite textAlignStart" variant="body2">
              11.
            </Typography>
            <Typography
              className="dullWhite textAlignStart marginRight8"
              variant="body2"
            >
              ידוע למשתתף כי המשתף רשאי לפרסם את התכנים אשר ייאספו בתהליך
              השיתוף, במלואם ו/או בחלקם, ובדרכים שונות, לרבות בפרסומי המשתף
              ובאתרי אינטרנט אחרים אותם הוא מפעיל.
            </Typography>
          </div>
          <div className="displayFlex marginBottom24">
            <Typography className="dullWhite textAlignStart" variant="body2">
              12.
            </Typography>
            <Typography
              className="dullWhite textAlignStart marginRight8"
              variant="body2"
            >
              משתתף המעלה תוכן באתר נותן הסכמתו בהרשאה בלתי חוזרת ובלתי מותנית
              לשימוש בלתי מוגבל ו/או מסוייג לרבות בעל אופי שיווקי הכולל העתקה
              ו/או שינוי ו/או עריכה ו/או תרגום ו/או עיבוד בכל דרך ומועד כפי
              שימצא המשתף לנכון וזאת ללא צורך בקבלת הסכמה ו/או במתן הודעה, וזאת
              ללא כל תמורה.
            </Typography>
          </div>
          <div className="displayFlex marginBottom24">
            <Typography className="dullWhite textAlignStart" variant="body2">
              13.
            </Typography>
            <Typography
              className="dullWhite textAlignStart marginRight8"
              variant="body2"
            >
              בכל מקרה של שימוש בתוכן אשר הועלה באתר לא יהא חייב המשתף בציון שם
              המשתתף אשר העלה את התוכן או במתן אזכור כלשהו.
            </Typography>
          </div>
          <div className="displayFlex marginBottom24">
            <Typography className="dullWhite textAlignStart" variant="body2">
              14.
            </Typography>
            <Typography
              className="dullWhite textAlignStart marginRight8"
              variant="body2"
            >
              כניסה לאתר מהווה הסכמה והתחייבות מצד משתתף להימנע מלהעלות ו/או
              לפרסם, באתר כל פרסום ו/או תוכן שאינם חוקיים ו/או שאינם נאותים ו/או
              שאינם עולים בקנה אחד עם תכלית האתר לרבות, אך מבלי לגרוע מכלליות
              האמור, תוכן שקרי, תוכן מטעה, תוכן גזעני, תוכן בוטה מבחינה מינית,
              פורנוגרפיה, תוכן מפלה ו/או מעודד אפליה, תוכן פוגע על בסיס מגדרי,
              תוכן החושף פרטים אישיים לרבות חשיפת פרטי קטינים, קללות, נאצות,
              גידופים, לשון הרע, דיבה, תוכן הפוגע ברכושו ו/או בקניינו של אחר,
              תוכן המהווה פגיעה בזכויות יוצרים ו/או בקנין רוחני ו/או בסימני מסחר
              ו/או מדגמים ו/או פטנטים ו/או תוכן אשר פרסומו נאסר על פי צו שיפוטי
              ו/או הוראת רשות מוסמכת ו/או תוכן פרסומי ו/או תוכן שיווקי.
            </Typography>
          </div>
          <div className="displayFlex marginBottom24">
            <Typography className="dullWhite textAlignStart" variant="body2">
              15.
            </Typography>
            <Typography
              className="dullWhite textAlignStart marginRight8"
              variant="body2"
            >
              מובהר בזאת כי האחריות הבלעדית לתכנים חלה על כותב ומפרסם התכנים,
              לרבות ביחס לנכונות, דיוק, אמיתות וחוקיות התכנים, והשימוש בתכנים
              הינו באחריותו הבלעדית של המשתמש בהם.
            </Typography>
          </div>
          <div className="displayFlex marginBottom24">
            <Typography className="dullWhite textAlignStart" variant="body2">
              16.
            </Typography>
            <Typography
              className="dullWhite textAlignStart marginRight8"
              variant="body2"
            >
              המשתף ו/או מי מטעמו וכן החברה לא יישאו באחריות כלשהי לכל נזק ישיר
              ו/או עקיף, ככל שייגרם, למשתמש בתכנים ו/או לנזק הנובע מהתכנים.
            </Typography>
          </div>
          <div className="displayFlex marginBottom24">
            <Typography className="dullWhite textAlignStart" variant="body2">
              17.
            </Typography>
            <Typography
              className="dullWhite textAlignStart marginRight8"
              variant="body2"
            >
              מובהר כי המשתף ו/או החברה רשאית להסיר ו/או למחוק ו/או לערוך ו/או
              בין בצורה מלאה ובין באופן חלקי, כל מידע ו/או תוכן המועלים לאתר ללא
              צורך במתן הודעה מוקדמת ו/או בקבלת הסכמה כלשהי. יובהר כי אין בהסרת
              ו/או מחיקת ו/או עריכת תכנים על ידי החברה או מי מטעמה, כדי לגרוע
              מאחריות מפרסם תוכן ואין בכך כדי להטיל על המשתף ו/או החברה אחריות.
            </Typography>
          </div>
          <div className="displayFlex marginBottom24">
            <Typography className="dullWhite textAlignStart" variant="body2">
              18.
            </Typography>
            <Typography
              className="dullWhite textAlignStart marginRight8"
              variant="body2"
            >
              של הפרת תנאי השימוש באתר יהא המשתף או החברה רשאים למנוע ממשתתף את
              השימוש באתר, לבטל רישומו של משתתף ואף לחשוף רשומות מידע הקשורות
              להפרה זו, וכל זאת על פי שיקול דעתם הבלעדי של החברה ו/או המשתף וללא
              כל הודעה מוקדמת ו/או נימוק ו/או הסבר כלשהו.
            </Typography>
          </div>
          <div className="displayFlex marginBottom24">
            <Typography className="dullWhite textAlignStart" variant="body2">
              19.
            </Typography>
            <Typography
              className="dullWhite textAlignStart marginRight8"
              variant="body2"
            >
              תהא רשאית לשנות ו/או לתקן ו/או להוסיף ו/או לגרוע מעת לעת הוראות
              תנאי השימוש באתר זה.
            </Typography>
          </div>
          <div className="displayFlex marginBottom24">
            <Typography className="dullWhite textAlignStart" variant="body2">
              20.
            </Typography>
            <Typography
              className="dullWhite textAlignStart marginRight8"
              variant="body2"
            >
              כל האמור בהוראות תנאי השימוש באתר כתוב בלשון זכר לשם הנוחות בלבד
              ומתייחס לנשים וגברים כאחד.
            </Typography>
          </div>
          <div className="displayFlex marginBottom24">
            <Typography className="dullWhite textAlignStart" variant="body2">
              21.
            </Typography>
            <Typography
              className="dullWhite textAlignStart marginRight8"
              variant="body2"
            >
              סמכות שיפוט – על הוראות תנאי השימוש באתר יחולו דיני מדינת ישראל.
              כל סכסוך ו/או מחלוקת הנובעים מהשימוש באתר יובאו לערכאה השיפוטית
              המוסכמת במחוז תל-אביב יפו בלבד.
            </Typography>
          </div>
          <div className="displayFlex marginBottom24">
            <Typography className="dullWhite textAlignStart" variant="body2">
              22.
            </Typography>
            <Typography
              className="dullWhite textAlignStart marginRight8"
              variant="body2"
            >
              ליצירת קשר ביחס לתנאי השימוש באתר ניתן לפנות אל החברה באמצעות
              כתובת דוא"ל:{" "}
              <Link href={"mailto:office@inql.co.il"}>office@inql.co.il</Link>
            </Typography>
          </div>
        </div>
        {this.renderControlPanel()}
        <Footer {...this.props} />
      </React.Fragment>
    );
  };
}
