import { STORAGE_KEYS, USER_ROLE } from "../../constants";

function storeUserData(userToken) {
  try {
    localStorage.setItem(STORAGE_KEYS.USER_TOKEN, JSON.stringify(userToken));
  } catch (e) {
    throw e;
  }
}

function storeUserDetails(userDetails) {
  try {
    localStorage.setItem(
      STORAGE_KEYS.USER_DETAILS,
      JSON.stringify(userDetails)
    );
  } catch (e) {
    throw e;
  }
}

function storeMessageData(messageData) {
  try {
    localStorage.setItem(
      STORAGE_KEYS.MESSAGE_DATA,
      JSON.stringify(messageData)
    );
  } catch (e) {
    throw e;
  }
}

function removeMessageData() {
  try {
    localStorage.removeItem(STORAGE_KEYS.MESSAGE_DATA);
  } catch (e) {
    throw e;
  }
}

function getMessageData() {
  try {
    let message;
    let messageData = localStorage.getItem(STORAGE_KEYS.MESSAGE_DATA);
    let messageString = messageData;
    message = JSON.parse(messageString);
    if (!message) {
      return false;
    }

    return message;
  } catch (e) {
    throw e;
  }
}

function updateUserData(user) {
  try {
    localStorage.setItem(STORAGE_KEYS.USER, JSON.stringify(user));
  } catch (e) {
    throw e;
  }
}

function getUserData() {
  try {
    let user = localStorage.getItem(STORAGE_KEYS.USER);
    if (user) {
      return JSON.parse(user);
    }
    return false;
  } catch (e) {
    throw e;
  }
}

function isLoggedIn() {
  try {
    let userToken = localStorage.getItem(STORAGE_KEYS.USER_TOKEN);

    if (!userToken) {
      return false;
    }
    return JSON.parse(userToken);
  } catch (e) {
    throw e;
  }
}

function clearUserDataFromLocalStroage() {
  try {
    localStorage.clear();
  } catch (e) {
    throw e;
  }
}

function getToken() {
  try {
    let token;
    let tokenData = localStorage.getItem(STORAGE_KEYS.USER_TOKEN);
    let tokenString = tokenData;
    if (!tokenString) {
      return false;
    }
    token = JSON.parse(tokenString).accessToken;
    if (!token) {
      // logout();
      clearUserDataFromLocalStroage();
      return false;
    }

    return token;
  } catch (e) {
    throw e;
  }
}

function getRefreshToken() {
  try {
    let token;
    let tokenData = localStorage.getItem(STORAGE_KEYS.USER_TOKEN);
    let tokenString = tokenData;
    token = JSON.parse(tokenString).refreshToken;
    if (!token) {
      // logout();
      // clearUserDataFromLocalStroage();
      return false;
    }
    return token;
  } catch (e) {
    throw e;
  }
}

function getUserDetails() {
  try {
    let details;
    let detailData = localStorage.getItem(STORAGE_KEYS.USER_DETAILS);
    let detailString = detailData;
    details = JSON.parse(detailString);
    if (!details) {
      // logout();
      // clearUserDataFromLocalStroage();
      return false;
    }

    return details;
  } catch (e) {
    throw e;
  }
}

function storeAccountDetails(data) {
  try {
    localStorage.setItem(STORAGE_KEYS.ACCOUNT_DETAILS, JSON.stringify(data));
  } catch (e) {
    throw e;
  }
}

function getAccountDetails() {
  try {
    let user = localStorage.getItem(STORAGE_KEYS.ACCOUNT_DETAILS);

    if (user === "undefined") {
      return false;
    }
    let userDetails = JSON.parse(user);
    if (userDetails && userDetails.slug) {
      return userDetails;
    }
    return false;
  } catch (e) {
    throw e;
  }
}

function storeAdminFlag(data) {
  try {
    localStorage.setItem(STORAGE_KEYS.IS_ADMIN, data);
  } catch (e) {
    throw e;
  }
}

function getAdminFlag() {
  try {
    let isAdmin = localStorage.getItem(STORAGE_KEYS.IS_ADMIN);
    if (isAdmin === "true") {
      return true;
    }
    return false;
  } catch (e) {
    throw e;
  }
}

function getUserRole() {
  try {
    let isAdmin = localStorage.getItem(STORAGE_KEYS.IS_ADMIN);
    let userDetailsString = localStorage.getItem(STORAGE_KEYS.USER_DETAILS);
    let userDetails = JSON.parse(userDetailsString);
    if (isAdmin === "true") {
      return USER_ROLE.SYSTEM_ADMIN;
    } else if (userDetails && userDetails.role === USER_ROLE.GUEST) {
      return USER_ROLE.GUEST;
    } else {
      return USER_ROLE.USER;
    }
  } catch (e) {
    throw e;
  }
}

const Auth = {
  storeUserData,
  clearUserDataFromLocalStroage,
  getUserData,
  isLoggedIn,
  updateUserData,
  getToken,
  getRefreshToken,
  storeUserDetails,
  getUserDetails,
  storeMessageData,
  getMessageData,
  removeMessageData,
  storeAccountDetails,
  getAccountDetails,
  storeAdminFlag,
  getAdminFlag,
  getUserRole,
};

export default Auth;
