import React, { Component } from "react";
import { loadCSS } from "fg-loadcss";
import Typography from "@material-ui/core/Typography";
import SvgIcon from "@material-ui/core/SvgIcon";

export default class Success extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
  }

  render() {
    return (
      <React.Fragment>
        <div
          className="successContainer"
          aria-role="alert"
          aria-live="imperative"
        >
          <SvgIcon
            className="svgGreenColor"
            style={{ color: "#4BD258", fontSize: "96px" }}
            component={this.props.icon}
            viewBox="0 0 512 512"
          />
          <Typography className="greenColor" variant="h6">
            {this.props.text}
          </Typography>
        </div>
      </React.Fragment>
    );
  }
}
