import React, { Component } from "react";

export default class FacebookCallback extends Component {
  componentDidMount() {
    if (
      !this.props.history ||
      !this.props.history.location ||
      !this.props.history.location.search
    ) {
      return;
    }
    let params = new URLSearchParams(this.props.history.location.search);
    let code = params.get("code");
    window.opener.postMessage({
      provider: "facebook",
      result: { code },
    });
  }
  render() {
    return <div></div>;
  }
}
