import React, { Component } from "react";
import { loadCSS } from "fg-loadcss";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import renderHTML from "react-render-html";

export default class ConclusionContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
  }

  render() {
    return (
      <React.Fragment>
        <div style={{ background: `${this.props.backgroundColor}` }}>
          <Typography
            variant="body1"
            className="dullWhite textAlignEnd reverse directionRTL fontSize18"
          >
            {this.props.data?.text1 && renderHTML(this.props.data.text1)}
          </Typography>
          <Typography
            variant="h6"
            className={`whiteColor textAlignEnd marginTopBottom bold reverse directionRTL`}
          >
            {this.props.data && this.props.data.title}
          </Typography>
          <div className="conclusionContentImg">
            <Icon
              className={`${this.props.data && this.props.data.icon} redColor`}
              style={{
                fontSize: "76px",
                width: "100%",
                textAlign: "center",
              }}
            ></Icon>
          </div>
          <Typography
            variant="body1"
            className="dullWhite textAlignEnd marginTopBottom reverse directionRTL fontSize18"
          >
            {this.props.data?.text2 && renderHTML(this.props.data.text2)}
          </Typography>
        </div>
      </React.Fragment>
    );
  }
}
