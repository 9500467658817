import React, { Component } from "react";
import ControlPanelButton from "../controlPanelButton/controlPanelButton";
import Auth from "../../common/auth";
import { USER_ROLE, STORAGE_KEYS } from "../../../constants";
import { ReactComponent as UpdateDetails } from "../../../icons/UpdateDetails.svg";
import { ReactComponent as Message } from "../../../icons/Message.svg";
import { ReactComponent as Login } from "../../../icons/Login.svg";

export default class UserControlPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changePassword: false,
      userDetails: null,
      accountDetails: null,
    };
  }

  componentDidMount = () => {
    this.fetchUserDetails();
    this.fetchAccountDetails();
  };

  fetchUserDetails = () => {
    let userDetails = Auth.getUserDetails();
    this.setState({
      userDetails: userDetails,
    });
  };

  fetchAccountDetails = () => {
    let accountDetails = Auth.getAccountDetails();
    this.setState({
      accountDetails: accountDetails,
    });
  };
  logout = async () => {
    window.NotificationUtils.showInfo(window.t("initiatingLogout"));
    try {
      await window.Arena.database.logout();
      localStorage.removeItem(STORAGE_KEYS.USER_TOKEN);
      localStorage.removeItem(STORAGE_KEYS.USER_DETAILS);
      localStorage.removeItem(STORAGE_KEYS.IS_ADMIN);

      //REVERT THIS
      //this.props.history.replace(`/${this.state.accountDetails.slug}`)
      this.props.history.push(`/`);

      window.location.reload();
      window.NotificationUtils.showSuccess(window.t("logoutSuccessful"));
      return;
    } catch (e) {
      console.error(e);
    }
  };

  openMyCommentsPage = () => {
    this.props.history.push(`/${this.state.accountDetails.slug}/my-comments`);
  };

  showUpdateModal = () => {
    this.props.showUpdateModal();
  };

  signUp = async () => {
    if (this.state.accountDetails) {
      return this.props.history.push(
        `/${this.state.accountDetails.slug}/signup`
      );
    }
    return;
  };

  render() {
    let role = window.role;
    if (!role) {
      role = USER_ROLE.GUEST;
    }
    return (
      <React.Fragment>
        <div
          tabIndex={0}
          id="userControlPanelContainer"
          style={{ background: `${this.props.backgroundColor}` }}
          className="reverse"
        >
          <ControlPanelButton
            history={this.props.history}
            onClick={role === USER_ROLE.GUEST ? this.signUp : this.logout}
            text={
              role === USER_ROLE.GUEST ? window.t("signUp") : window.t("logout")
            }
            value={Login}
          />
          <ControlPanelButton
            text={window.t("comments")}
            onClick={this.openMyCommentsPage}
            value={Message}
          />
          {!(role === USER_ROLE.GUEST) && (
            <ControlPanelButton
              text={window.t("updateDetails")}
              value={UpdateDetails}
              onClick={this.showUpdateModal}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}
