import React from "react";
import CommonStructure from "../../common/mainStructure/main";
import Typography from "@material-ui/core/Typography";
import ArenaInputField from "../../ui/arenaInputField/arenaInput";
import SendButton from "../../ui/sendButton/sendButton";
import { isEmail } from "validator";
import Auth from "../../common/auth";

export default class ResetPassword extends CommonStructure {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      checkErrors: false,
    };
    this.backgroundImg = "/assets/img/header.png";
  }

  componentDidMount() {
    window.addEventListener("keypress", this.keyPressed);
  }

  keyPressed = (event) => {
    if (event.key === "Enter") {
      this.onClickForgotPassword();
    }
  };

  componentWillUnmount() {
    window.removeEventListener("keypress", this.keyPressed);
  }

  errorInEmail = () => {
    return !this.state.email || !isEmail(this.state.email);
  };

  checkErrors() {
    if (!this.state.email || !isEmail(this.state.email)) {
      return true;
    }
    return false;
  }

  onClickForgotPassword = async () => {
    let accountDetails = Auth.getAccountDetails();
    this.setState({
      checkErrors: true,
    });
    if (this.checkErrors()) {
      return;
    }
    window.NotificationUtils.showInfo(window.t("initiatingResetPassword"));
    let data = {
      email: this.state.email,
      returnUrl: `${process.env.REACT_APP_RESET_PASSWORD_RETURN_URL}/${accountDetails.slug}/reset-password`,
    };
    try {
      let response = await window.Arena.database.lostPassword(data);
      if (response && response.data && response.data.success) {
        window.NotificationUtils.showSuccess(window.t("resetPasswordLinkSent"));
      }
      this.setState({
        checkErrors: false,
      });
      this.props.history.goBack();
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError(window.t("somethingWentWrong"));
      this.setState({
        checkErrors: false,
      });
    }
  };

  onChangeEmailField = (event) => {
    this.setState({
      email: event.target.value,
    });
  };

  renderTopContent = () => {
    return (
      <div className="signupLoginTopContent">
        <img src="/assets/img/logoArena.png" alt="arenaLogo" className="logo" />
      </div>
    );
  };

  renderBottomContent = () => {
    return (
      <div className="resetPasswordOverlap">
        <div className="flexCenter loginToSystemText">
          <Typography variant="h4" className="whiteColor bold zIndex">
            {window.t("resetPassword")}
          </Typography>
        </div>
        <div
          className="flexCenter signupSocialNetworkText"
          style={{ marginBottom: "26px" }}
        >
          <Typography variant="body1" className="whiteColor">
            {window.t("insertEmailSignedWith")}
          </Typography>
        </div>

        <div id="resetPasswordPageEmailLink" className="reverse">
          <Typography variant="body1" className="dullWhite">
            {window.t("email")}
          </Typography>
        </div>
        <div className="loginInputFieldContainer">
          <div style={{ width: "100%" }}>
            <ArenaInputField
              placeholder={window.t("email")}
              onChange={this.onChangeEmailField}
              error={this.state.checkErrors && this.errorInEmail()}
              helperText={
                this.state.checkErrors &&
                this.errorInEmail() &&
                window.t("invalidEmail")
              }
              value={this.state.email}
            />
          </div>
        </div>
        <div id="loginSendButtonContainer">
          <SendButton
            text={window.t("sendResetPasswordMail")}
            size={"small"}
            onClick={this.onClickForgotPassword}
            id={"resetPasswordButton"}
          />
        </div>
      </div>
    );
  };
}
