import React, { Component } from "react";
import { loadCSS } from "fg-loadcss";
import IconButton from "@material-ui/core/IconButton";
import ArenaPopover from "../arenaPopover/arenaPopover";
import SvgIcon from "@material-ui/core/SvgIcon";
import Tooltip from "@material-ui/core/Tooltip";
import { isMobile } from "react-device-detect";
export default class ControlPanelOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopover: Boolean(props.showPopover),
    };
    this.dialogRef = React.createRef();
  }

  componentDidMount() {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
  }

  componentWillReceiveProps = (props) => {
    if (props.showPopover) {
      this.setState({
        showPopover: true,
      });
    }
  };

  onClickIconButton = () => {
    if (this.props.onClickIconButton) {
      this.props.onClickIconButton();
      return;
    }
    if (this.props.noPopover) {
      this.props.openEditMode && this.props.openEditMode();
    } else {
      this.setState({
        showPopover: !this.state.showPopover,
      });
      if (!this.state.showPopover) {
        // Now showing popover
        setTimeout(() => {
          this.dialogRef.current && this.dialogRef.current.focus();
        }, 500);
      }
    }
  };

  render() {
    let random = Math.floor(Math.random() * 10) + 1;
    return (
      <div className="controlPanelOption">
        <Tooltip
          className="controlPanelTooltip"
          title={this.props.toolTipTitle || ""}
          placement={isMobile ? "top" : "left"}
        >
          <IconButton
            id={this.props.name}
            aria-label={this.props.name}
            size="medium"
            onClick={this.onClickIconButton}
          >
            <SvgIcon
              id="controlPanelIcon"
              component={this.props.icon}
              className={`${this.props.color}`}
              viewBox="0 0 512 512"
            />
          </IconButton>
        </Tooltip>
        {this.props.component && (
          <ArenaPopover
            className={`arenaPopover ${this.props.extraClass}`}
            toggle={this.onClickIconButton}
            history={this.props.history}
            placement={this.props.placement}
            isOpen={this.state.showPopover}
            target={this.props.name}
          >
            <div
              ref={this.dialogRef}
              aria-labelledby={`control-panel-dialog-label-${random}`}
              role="dialog"
              tabIndex="0"
            >
              <div
                id={`control-panel-dialog-label-${random}`}
                className="sr-only"
              >
                {window.t("controlPanel")}
              </div>
              {this.props.component}
            </div>
          </ArenaPopover>
        )}
      </div>
    );
  }
}
