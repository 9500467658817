import React, { Component } from "react";
import { PopoverHeader, PopoverBody, UncontrolledPopover } from "reactstrap";

export default class ArenaPopover extends Component {
  render() {
    return (
      <React.Fragment>
        <UncontrolledPopover
          className={`arenaPopover ${this.props.target}`}
          {...this.props}
          trigger="legacy"
          placement={this.props.placement}
          isOpen={this.props.isOpen}
          target={this.props.target}
        >
          {this.props.header && (
            <PopoverHeader>{this.props.header}</PopoverHeader>
          )}
          <PopoverBody history={this.props.history}>
            {this.props.children}
          </PopoverBody>
        </UncontrolledPopover>
      </React.Fragment>
    );
  }
}
