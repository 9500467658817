import React, { Component } from "react";
import { loadCSS } from "fg-loadcss";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";

export default class MultiSelectionSubject extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
  }

  onClickOption = () => {
    let selectedOption = this.props.data;
    this.props.onClickOption && this.props.onClickOption(selectedOption);
  };

  getAriaLabel = () => {
    if (this.props.selected) {
      return `unselect ${this.props.data.name}`;
    }
    return `Select ${this.props.data.name}`;
  };

  render() {
    return (
      <React.Fragment>
        <Button
          tabIndex={0}
          aria-label={this.getAriaLabel()}
          className="multiSelectSub"
          onClick={this.onClickOption}
        >
          <Icon className={`multiSubjectIcon ${this.props.data.icon}`}></Icon>
          <Typography variant="body1" className="dullWhite textAlignCenter">
            {this.props.data.name}
          </Typography>
        </Button>
      </React.Fragment>
    );
  }
}

// style={{background:`${this.props.backgroundColor}`}}
