import React, { Component } from "react";
import ControlPanel from "../../ui/controlPanel/controlPanel";
import { USER_ROLE } from "../../../constants";
import { isBrowser, isMobile } from "react-device-detect";
import Typography from "@material-ui/core/Typography";
import Footer from "../../ui/footer/footer";

export default class NotFound extends Component {
  constructor(props) {
    super(props);
    this.backgroundImg = "/assets/img/header.png";
  }
  renderTopContent = () => {
    return (
      <React.Fragment>
        <div
          id={"commonStructureTopContent"}
          className={`flexJustifyCenter mainBackgroundImgContainer positionRelative notFoundTopContent`}
          style={{
            backgroundImage: `url(${this.backgroundImg && this.backgroundImg})`,
          }}
        >
          <div className="signupLoginTopContent">
            <img
              src="/assets/img/logoArena.png"
              alt="arenaLogo"
              className="logo"
            />
          </div>
        </div>
      </React.Fragment>
    );
  };
  renderControlPanel = () => {
    let role = window.role || USER_ROLE.GUEST;
    let isGuest = Boolean(role === USER_ROLE.GUEST);
    if (isMobile) {
      return (
        <React.Fragment>
          <ControlPanel
            history={this.props.history}
            placement="top"
            showAuthControlPanel={isGuest ? true : false}
          />
        </React.Fragment>
      );
    }
    if (isBrowser) {
      return (
        <React.Fragment>
          <ControlPanel
            history={this.props.history}
            placement="left"
            showAuthControlPanel={isGuest ? true : false}
          />
        </React.Fragment>
      );
    }
  };

  renderBottomContent = () => {
    return (
      <React.Fragment>
        <div className="flexJustifyCenter arenaDarkBlack commonBottomContainer">
          <div className="notFoundMainContainer zIndex1 ">
            <Typography variant="h4" className="whiteColor">
              {window.t("error404NotFound")}
            </Typography>
            <img
              src="/assets/img/404.svg"
              alt="404"
              className="notFoundImage"
            />
            <Typography variant="body1" className="whiteColor">
              {window.t("sorryCheckURL")}
              <a href={"/"}>{window.t("homePage")}</a>.
            </Typography>
          </div>
        </div>
        {this.renderControlPanel()}
      </React.Fragment>
    );
  };
  render() {
    return (
      <React.Fragment>
        {this.renderTopContent()}
        {this.renderBottomContent()}
        <Footer {...this.props} />
      </React.Fragment>
    );
  }
}
