import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as Question } from "../../../icons/Question.svg";
import SvgIcon from "@material-ui/core/SvgIcon";

export default class HeadingWithImg extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="headingWithImgContainer reverse">
          <SvgIcon
            component={Question}
            viewBox="0 0 512 512"
            className="headingImg svgRedColor"
          />
          {/* <img src="/assets/img/question.png" alt="question" className="headingImg" /> */}
          <div style={{ marginRight: "16px" }}>
            <Typography
              role="heading"
              aria-level="2"
              variant="h5"
              className="whiteColor bold textAlignEnd reverse"
              tabIndex={0}
              aria-labelledBy={this.props.text}
            >
              {this.props.text}
            </Typography>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
