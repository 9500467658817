import React, { Component } from "react";
import { loadCSS } from "fg-loadcss";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import SvgIcon from "@material-ui/core/SvgIcon";
import Button from "@material-ui/core/Button";

export default class ShareAndMsg extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
  }

  getLabel = () => {
    if (this.props.istext) {
      return `${this.props.value} ${this.props.text}`;
    }
    return `${this.props.text}`;
  };

  render() {
    return (
      <React.Fragment>
        <Button
          role="button"
          tabIndex="0"
          aria-label={this.getLabel()}
          id={this.props.id}
          className={`shareAndMsgContainer ${
            !this.props.onClick && "pointerEventsNone"
          }`}
          onClick={this.props.onClick && this.props.onClick}
          style={{ background: `${this.props.backgroundColor}` }}
        >
          {this.props.istext ? (
            <Typography className="blueColor" variant="h4">
              <Box fontWeight={500}>{this.props.value}</Box>
            </Typography>
          ) : (
            <IconButton
              tabIndex="-1"
              aria-hidden="true"
              className="shareAndMsgButton"
            >
              <SvgIcon
                className="svgBlueColor"
                style={{ fill: "#65D2FC", fontSize: "28px" }}
                component={this.props.value}
                viewBox="0 0 512 512"
              />
            </IconButton>
          )}
          <Typography
            aria-hidden="true"
            className="whiteColor bold lineHeightOne"
            variant="body1"
          >
            {this.props.text}
          </Typography>
        </Button>
      </React.Fragment>
    );
  }
}
