import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import SvgIcon from "@material-ui/core/SvgIcon";
import Button from "@material-ui/core/Button";

export default class CommentActionButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Button
          aria-label="0"
          tabIndex={0}
          className={`shareAndMsgContainer ${this.props.extraClass}`}
          onClick={this.props.onClick}
          style={{ background: `${this.props.backgroundColor}` }}
          {...this.props}
        >
          {this.props.text ? (
            <div className="redColor  bold commentActionCount">
              {this.props.value}
            </div>
          ) : (
            <IconButton tabIndex={-1} className="commentIconButton">
              <SvgIcon
                id="heartIcon"
                className="svgBlueColor"
                viewBox={this.props.viewBox || "0 0 512 512"}
                component={this.props.value}
              />
            </IconButton>
          )}
          {this.props.text ? (
            <div style={{ color: "white", fontSize: "16px" }}>
              {this.props.text}
            </div>
          ) : null}
        </Button>
      </React.Fragment>
    );
  }
}
