import React, { Component } from "react";
import { loadCSS } from "fg-loadcss";
import Typography from "@material-ui/core/Typography";
import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as RegUser } from "../../../icons/RegUser.svg";

export default class UserPill extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
  }

  render() {
    let svgColor =
      this.props.iconColor === "#FEA030" ? "svgGoldenColor" : "svgWhiteColor";
    return (
      <React.Fragment>
        <div
          className="userPillContainer reverse"
          style={{ background: `${this.props.backgroundColor}` }}
        >
          <SvgIcon
            className={svgColor}
            component={RegUser}
            fontSize="small"
            viewBox="0 0 512 512"
            style={{
              color: `${this.props.iconColor ? this.props.iconColor : "grey"}`,
              marginLeft: "10px",
            }}
          />
          <Typography
            role="heading"
            aria-label={`${window.t("from")} ${this.props.user}`}
            style={{
              color: `${
                this.props.iconColor ? this.props.iconColor : "#ECE9F1"
              }`,
            }}
            className="bold"
            variant="body1"
            tabIndex={0}
          >
            {this.props.user}
          </Typography>
        </div>
      </React.Fragment>
    );
  }
}
