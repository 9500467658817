import { Typography } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import Link from "@material-ui/core/Link";
import React from "react";
import IconAndText from "../categoryMsgAndShare/shareAndMsg";
import DownloadReport from "../downloadReport/downloadReport";
import ProgressBar from "../progressBar/progressBar";

export default class StatisticsData extends React.Component {
  state = {
    statsData: null,
    expanded: true,
    showMore: false,
    loading: true,
  };

  async componentDidMount() {
    await this.fetchStatsData();
  }

  fetchStatsData = async () => {
    try {
      let statsData = await window.Arena.database.getSubjectStats(
        this.props.subjectId
      );
      this.setState({
        statsData: statsData.data,
        loading: false,
      });
    } catch (e) {
      console.error(e);
      this.setState({
        loading: false,
      });
      window.NotificationUtils.showError(window.t("somethingWentWrong"));
    }
  };

  renderStatisticsData = () => {
    return (
      <React.Fragment>
        <ProgressBar
          value={100}
          text={window.t("views")}
          data={this.state.statsData?.views}
        />
        <ProgressBar
          value={Math.round(
            ((this.state.statsData?.comments?.total -
              this.state.statsData?.comments?.deleted) /
              this.state.statsData?.views) *
              100
          )}
          text={window.t("replies")}
          data={
            this.state.statsData?.comments?.total -
            this.state.statsData?.comments?.deleted
          }
        />
        <ProgressBar
          value={Math.round(
            (this.state.statsData?.activity?.likeAndComment /
              this.state.statsData?.views) *
              100
          )}
          text={window.t("commentAndLikes")}
          data={this.state.statsData?.activity?.likeAndComment}
        />
        <ProgressBar
          value={Math.round(
            (this.state.statsData?.comments?.replies /
              this.state.statsData?.views) *
              100
          )}
          text={window.t("repliesToComments")}
          data={this.state.statsData?.comments?.replies}
        />
        <ProgressBar
          value={Math.round(
            (this.state.statsData?.activity?.onlyLike /
              this.state.statsData?.views) *
              100
          )}
          text={window.t("onlyLikes")}
          data={this.state.statsData?.activity?.onlyLike}
        />
        <ProgressBar
          value={Math.round(
            (this.state.statsData?.activity?.commentAndChat /
              this.state.statsData?.views) *
              100
          )}
          text={window.t("commentAndMessage")}
          data={this.state.statsData?.activity?.commentAndChat}
        />
        <ProgressBar
          value={Math.round(
            (this.state.statsData?.comments?.deleted /
              this.state.statsData?.views) *
              100
          )}
          text={window.t("deletedComments")}
          data={this.state.statsData?.comments?.deleted}
        />
      </React.Fragment>
    );
  };

  renderStatsData = () => {
    return (
      <React.Fragment>
        <div>
          <Typography
            variant="h6"
            className="redColor bold textAlignEnd marginBottom8 reverse"
          >
            {window.t("statistics")}
          </Typography>
          <div
            className={`${
              this.state.showMore ? "height" : "constrainedStatsHeight"
            }`}
            id="progressBarContainer"
          >
            {this.state.statsData && this.renderStatisticsData()}
          </div>
          <div id="readMoreAndIcon" onClick={this.onClickShowMore}>
            <Icon
              className={`displayFlex alignItemsCenter justifyContentCenter marginLeft4
                ${
                  this.state.showMore ? "fas fa-angle-up" : "fas fa-angle-down"
                }`}
              style={{ color: "#FD4f42" }}
              fontSize="small"
            ></Icon>
            <Typography
              className="textAlignEnd redColor reverse"
              variant="body1"
            >
              <Link>
                {this.state.showMore
                  ? window.t("showLess")
                  : window.t("showMore")}
              </Link>
            </Typography>
          </div>
        </div>
      </React.Fragment>
    );
  };

  onClickShowMore = () => {
    let seeMore = false;
    if (this.state.showMore) {
      seeMore = false;
    } else {
      seeMore = true;
    }
    this.setState({
      showMore: seeMore,
    });
  };

  onClickCounterExpansion = () => {
    let expansion = false;
    if (this.state.expanded) {
      expansion = false;
    } else {
      expansion = true;
    }
    this.setState({
      expanded: expansion,
    });
  };

  renderCounter = () => {
    return (
      <React.Fragment>
        <div className="marginBottom16">
          <div id="readMoreAndIcon" onClick={this.onClickCounterExpansion}>
            <Icon
              className={`displayFlex alignItemsCenter justifyContentCenter marginLeft4
                ${
                  this.state.expanded ? "fas fa-angle-up" : "fas fa-angle-down"
                }`}
              style={{ color: "#FD4f42" }}
              fontSize="small"
            ></Icon>
            <Typography
              className="textAlignEnd redColor reverse"
              variant="body1"
            >
              {window.t("counters")}
            </Typography>
          </div>
          <div
            id="counterContainer"
            className={`displayFlex justifyContentSpaceBetween ${
              this.state.expanded ? "height" : "counterConstrainedHeight"
            }`}
          >
            <IconAndText
              text={window.t("facebook")}
              istext={true}
              value={this.state.statsData?.share?.facebook || 0}
            />

            <IconAndText
              text={window.t("whatsApp")}
              istext={true}
              value={this.state.statsData?.share?.whatsapp || 0}
            />
            <IconAndText
              text={window.t("Twitter")}
              istext={true}
              value={this.state.statsData?.share?.twitter || 0}
            />
            <IconAndText
              text={window.t("LinkedIn")}
              istext={true}
              value={this.state.statsData?.share?.linkedin || 0}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  pieIconItems = () => {
    return (
      <React.Fragment>
        {this.renderStatsData()}
        {this.state.statsData && this.renderCounter()}
        <Typography
          variant="h6"
          className="redColor bold textAlignEnd marginBottom8 reverse"
        >
          {window.t("report")}
        </Typography>
        {
          <DownloadReport
            onClickDownloadReport={this.props.onClickDownloadReport}
          />
        }
      </React.Fragment>
    );
  };

  render() {
    return <React.Fragment>{this.pieIconItems()}</React.Fragment>;
  }
}
