import React, { Component } from "react";
import Footer from "../../ui/footer/footer";
import Spinner from "@material-ui/core/CircularProgress";
import Header from "../header";
import Button from "@material-ui/core/Button";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
const HEADER_FADE = "true";

export default class CommonStructure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showEmptyPlaceholder: false,
    };
    this.backgroundImg = "";
  }

  renderTopContent = () => {
    return (
      <div style={{ color: "white", fontSize: "30px" }}>Inside top content</div>
    );
  };

  renderBottomContent = () => {
    return (
      <div style={{ height: "70vh", color: "white" }}>
        Inside bottom content
      </div>
    );
  };

  topContentWithBackgroundFixed = () => {
    return (
      <React.Fragment>
        <div
          id={HEADER_FADE === "true" ? "commonStructureTopContent" : ""}
          className={`flexJustifyCenter mainBackgroundImgContainer positionFixed ${
            this.state.backgroundSticky &&
            "positionFixed zIndex1 backgroundImageHeight"
          }`}
          style={{
            backgroundImage: `url(${this.backgroundImg && this.backgroundImg})`,
          }}
        ></div>
        <div className="mainBackgroundImgContainer" id="topContainerContent">
          {!this.state.loading && this.renderTopContent()}
        </div>
      </React.Fragment>
    );
  };

  topContentWithoutBackgroundFixed = () => {
    return (
      <React.Fragment>
        <div
          id={HEADER_FADE === "true" ? "commonStructureTopContent" : ""}
          className={`flexJustifyCenter mainBackgroundImgContainer positionRelative`}
          style={{
            backgroundImage: `url(${this.backgroundImg && this.backgroundImg})`,
          }}
        >
          {!this.state.loading && this.renderTopContent()}
        </div>
      </React.Fragment>
    );
  };

  renderTop = () => {
    if (this.state.backgroundSticky) {
      return this.topContentWithBackgroundFixed();
    } else {
      return this.topContentWithoutBackgroundFixed();
    }
  };

  renderLoader() {
    return (
      <div style={{ position: "absolute", zIndex: 9 }}>
        <Spinner size="36px" style={{ color: "#65D2FC" }} />
      </div>
    );
  }

  renderEmptyPlaceholder() {
    return (
      <div style={{ position: "absolute", zIndex: 9, marginTop: 62 }}>
        <div style={{ color: "#65D2FC", fontSize: "18px" }}>
          {window.t("noDataToShow")}
        </div>
      </div>
    );
  }

  renderBottom = () => {
    return (
      <React.Fragment>
        <div
          aria-busy={this.state.loading}
          className="flexJustifyCenter arenaDarkBlack commonBottomContainer"
        >
          {this.state.loading
            ? this.renderLoader()
            : this.renderBottomContent()}
          {!this.state.loading &&
            this.state.showEmptyPlaceholder &&
            this.renderEmptyPlaceholder()}
        </div>
      </React.Fragment>
    );
  };

  goBack = (route) => {
    if (this.props.history.length > 3) {
      this.props.history.goBack();
    } else {
      this.props.history.replace(route);
    }
  };

  renderBackButton = () => {
    return (
      <Button
        id="backToCategoriesButton"
        onClick={this.onClickBackButton}
        size="small"
        variant="contained"
        color="primary"
        endIcon={<ArrowForwardIosIcon style={{ color: "white" }} />}
        className="reverse"
      >
        {window.t("back")}
      </Button>
    );
  };

  onClickBackButton = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <React.Fragment>
        {this.state.showHeader && <Header />}
        <div className="commonStructureContainer">
          {this.renderTop()}
          {this.renderBottom()}
        </div>
        <Footer {...this.props} />
      </React.Fragment>
    );
  }
}
