import React from "react";
import CommonStructure from "../../common/mainStructure/main";
import Typography from "@material-ui/core/Typography";
import PasswordField from "../../ui/arenaPasswordField/passwordField";
import SendButton from "../../ui/sendButton/sendButton";
import Auth from "../../common/auth";

export default class ResetPassword extends CommonStructure {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      token: "",
    };
    this.backgroundImg = "/assets/img/header.png";
  }

  componentDidMount() {
    let querySearchParams = this.props.history.location.search;
    let params = new URLSearchParams(querySearchParams);
    let token = params.get("token");
    window.addEventListener("keypress", this.keyPressed);

    if (!token) {
      this.props.history.push("/login");
    } else {
      this.setState({
        token: token,
      });
    }
  }

  keyPressed = (event) => {
    if (event.key === "Enter") {
      this.resetPassword();
    }
  };

  componentWillUnmount() {
    window.removeEventListener("keypress", this.keyPressed);
  }

  errorInPassword = () => {
    let passwordChecks = new RegExp(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$"
    );
    return !this.state.password || !passwordChecks.test(this.state.password);
  };

  checkErrors() {
    let passwordChecks = new RegExp(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$"
    );
    if (!this.state.password || !passwordChecks.test(this.state.password)) {
      window.NotificationUtils.showError(window.t("invalidPasswordLogin"));
      return true;
    }
    if (!this.state.token) {
      window.NotificationUtils.showError(window.t("tokenNotFound"));
      return true;
    }
    return false;
  }

  resetPassword = async () => {
    let accountDetails = Auth.getAccountDetails();
    this.setState({
      checkErrors: true,
    });
    if (this.checkErrors()) {
      return;
    }
    let data = {
      token: this.state.token,
      password: this.state.password,
    };

    try {
      let response = await window.Arena.database.resetPassword(data);
      if (response && response.data && response.data.success === true) {
        this.props.history.push(`/${accountDetails.slug}/login`);
        window.NotificationUtils.showSuccess(
          window.t("passwordResetSuccessful")
        );
      } else {
        window.NotificationUtils.showError(window.t("passwordResetFailed"));
        this.props.history.push(`/${accountDetails.slug}/login`);
      }
      this.setState({
        checkErrors: false,
      });
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError(window.t("passwordResetFailed"));
      this.props.history.push(`/${accountDetails.slug}/login`);
      this.setState({
        checkErrors: false,
      });
    }
  };

  onChangePasswordField = (event) => {
    this.setState({
      password: event.target.value,
    });
  };

  renderTopContent = () => {
    return (
      <div className="signupLoginTopContent">
        <img src="/assets/img/logoArena.png" alt="arenaLogo" className="logo" />
      </div>
    );
  };

  renderBottomContent = () => {
    return (
      <div className="resetPasswordOverlap">
        <div className="flexCenter loginToSystemText">
          <Typography variant="h4" className="whiteColor bold zIndex">
            {window.t("resetPassword")}
          </Typography>
        </div>
        <div
          className="flexCenter signupSocialNetworkText"
          style={{ marginBottom: "26px" }}
        >
          <Typography variant="body1" className="whiteColor">
            {window.t("insertNewPassword")}
          </Typography>
        </div>

        <div id="resetPasswordPageEmailLink" className="reverse">
          <Typography variant="body1" className="dullWhite">
            {window.t("password")}
          </Typography>
        </div>
        <div className="loginInputFieldContainer">
          <div style={{ width: "100%" }}>
            <PasswordField
              placeholder={"Password"}
              onChange={this.onChangePasswordField}
              error={this.state.checkErrors && this.errorInPassword()}
              value={this.state.password}
            />
          </div>
        </div>
        <div id="loginSendButtonContainer">
          <SendButton
            text={window.t("confirmResetPassword")}
            size={"small"}
            onClick={this.resetPassword}
            id={"resetPasswordButton"}
          />
        </div>
      </div>
    );
  };
}
