import React from "react";
import CommonStructure from "../../common/mainStructure/main";
import Typography from "@material-ui/core/Typography";
import ArenaInput from "../arenaInputField/arenaInput";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ArenaButton from "../arenaButton/arenaButton";
import ControlPanel from "../controlPanel/controlPanel";
import { isBrowser, isMobile } from "react-device-detect";

export default class EditProfile extends CommonStructure {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      name: "",
      getNotification: false,
    };
    this.backgroundImg = "/assets/img/header.png";
    this.onChange_name = this.onChange.bind(this, "name");
    this.onChange_email = this.onChange.bind(this, "email");
  }

  onChange(key, event, value) {
    let update = {};
    update[key] = event.target.value || (value ? value.newValue : "");
    this.setState(update);
  }

  handleCheckboxChange = () => {
    this.setState({
      getNotification: !this.state.getNotification,
    });
  };

  renderControlPanel = () => {
    if (isMobile) {
      return (
        <React.Fragment>
          <ControlPanel history={this.props.history} placement="top" />
        </React.Fragment>
      );
    }
    if (isBrowser) {
      return (
        <React.Fragment>
          <ControlPanel history={this.props.history} placement="left" />
        </React.Fragment>
      );
    }
  };

  renderTopContent = () => {
    return (
      <div
        className="editClientPageTopContent"
        style={{ color: "white", fontSize: "45px", display: "flex" }}
      >
        <div>
          <img
            src="/assets/img/logoArena.png"
            alt="arenaLogo"
            className="logo"
          />
        </div>
      </div>
    );
  };

  //TODO :- Confirm And REMOVE static height
  renderBottomContent = () => {
    return (
      <React.Fragment>
        <div
          id="editProfileMainContainer"
          style={{ width: "60%", height: "45vh" }}
        >
          <div id="editClientPageText">
            <Typography
              style={{ zIndex: "9", marginBottom: "16px" }}
              variant="h5"
              className="redColor bold"
            >
              {window.t("editProfile")}
            </Typography>
          </div>
          <div className="descriptionNameContainer">
            <div className="textAndFieldContainer editProfileEmailMargin">
              <div className="editClientFieldText">
                <Typography
                  style={{ marginTop: "16px" }}
                  variant="body1"
                  className="whiteColor textAlignEnd"
                >
                  {window.t("emailAddress")}
                </Typography>
              </div>
              <div id="editProfileEmail">
                <ArenaInput
                  placeholder={window.t("email")}
                  onChange={this.onChange_name}
                  value={this.state.email}
                />
              </div>
            </div>

            <div className="textAndFieldContainer editProfileNameMargin">
              <div className="editClientFieldText">
                <Typography variant="body1" className="whiteColor textAlignEnd">
                  {window.t("fullName")}
                </Typography>
              </div>
              <div id="editProfileName">
                <ArenaInput
                  placeholder={window.t("description")}
                  onChange={this.onChange_name}
                  value={this.state.name}
                />
              </div>
            </div>
          </div>
          <div className="checkBoxContainer">
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.getNotification}
                  onChange={this.handleCheckboxChange}
                  name="checkedB"
                  color="primary"
                />
              }
              label={window.t("agreeNotifications")}
            />
          </div>

          <div className="editProfileButtonsContainer">
            <div className="editProfileEmailMargin">
              <div id="editProfileButton">
                <ArenaButton text={window.t("default")} size={"small"} />
              </div>
            </div>

            <div className="editProfileNameMargin">
              <div>
                <ArenaButton text={window.t("default")} size={"small"} />
              </div>
            </div>
          </div>
        </div>
        {this.renderControlPanel()}
      </React.Fragment>
    );
  };
}
