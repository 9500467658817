import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import UserPill from "../userAndIcon/userPill";
import CommentActionButton from "../commentSectionButton/commentActionButton";
import ClientName from "../commentClientName/commentClientName";
import Divider from "@material-ui/core/Divider";
import Spinner from "@material-ui/core/CircularProgress";
import moment from "moment";
import { SUBJECT_STATUS } from "../../../constants";
export default class MyComment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount = async () => {
    let commentData = this.props.data && this.props.data;

    try {
      let subjectData = await window.Arena.database.fetchSubjectWithId(
        commentData.subject
      );
      let categoryResponse = await window.Arena.database.fetchCategoryWithId(
        subjectData.data && subjectData.data.category.id
      );
      let userDetails = await window.Arena.database.fetchUserWithId(
        commentData.user
      );
      if (categoryResponse?.data?.canAdminister) {
        window.canAdministerCategory = true;
      } else {
        window.canAdministerCategory = false;
      }
      if (subjectData?.data?.canAdminister) {
        window.canAdministerSubject = true;
      } else {
        window.canAdministerSubject = false;
      }
      this.setState({
        subjectData: subjectData.data,
        categoryData: categoryResponse.data,
        userDetails: userDetails.data,
        loading: false,
      });
    } catch (e) {
      this.setState({
        loading: false,
      });
    }
  };

  renderLoader() {
    return (
      <div className="textAlignCenter" id="commentIndividualLoader">
        <Spinner size="36px" style={{ color: "#65D2FC" }} />
      </div>
    );
  }

  onClickSubjectComment = (data) => {
    let date = moment.utc(new Date());
    let endDate = moment.utc(data.endDate);

    if (data.status === SUBJECT_STATUS.PUBLISHED && date < endDate) {
      return this.props.history.replace(`discuss-subject?subjectId=${data.id}`);
    } else {
      this.props.history.replace(`conclusion?subjectId=${data.id}`);
    }
  };

  render() {
    if (this.state.loading) {
      return this.renderLoader();
    }
    if (!this.state.subjectData) {
      return <> </>;
    }
    return (
      <React.Fragment>
        {
          <div
            className="myCommentContainer reverse"
            style={{ background: `${this.props.backgroundColor}` }}
          >
            <ClientName
              name={this.state.categoryData && this.state.categoryData.name}
              iconURL={
                this.state.categoryData &&
                this.state.categoryData.icon &&
                this.state.categoryData.icon.original
              }
            />
            <Divider aria-hidden="true" className="myCommetnListItemDivider" />
            <div
              className="marginBottom16 cursorPointer"
              onClick={() => this.onClickSubjectComment(this.state.subjectData)}
              role="button"
              aria-label={`${window.t("backTo")} ${
                this.state.subjectData && this.state.subjectData.name
              }`}
              tabIndex={0}
            >
              <Typography
                role="heading"
                aria-level="2"
                variant="body1"
                className="redColor bold"
              >
                {this.state.subjectData && this.state.subjectData.name}
              </Typography>
            </div>
            <div className="marginBottom16">
              <UserPill
                user={this.state.userDetails && this.state.userDetails.name}
              />
            </div>
            <Typography
              role="heading"
              aria-level="2"
              variant="body1"
              className="dullWhite textAlignEnd marginBottom16 reverse"
              aria-label={this.props.data.text}
              tabIndex={0}
            >
              {this.props.data.text}
            </Typography>
            <div className="myCommentCountContainer reverse">
              <div className="marginRight16">
                <CommentActionButton
                  extraClass="pointerEventsNone"
                  text={window.t("likes")}
                  value={this.props.data.likesCount}
                  aria-label={`${this.props.data.likesCount || 0} ${window.t(
                    "likes"
                  )}`}
                />
              </div>
              <CommentActionButton
                extraClass="pointerEventsNone"
                text={window.t("replies")}
                value={this.props.data.repliesCount}
                aria-label={`${this.props.data.likesCount || 0} ${window.t(
                  "replies"
                )}`}
              />
            </div>
          </div>
        }
      </React.Fragment>
    );
  }
}
