import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
export default class FooterComponent extends Component {
  render() {
    return <React.Fragment>{this.renderFooterComponent()}</React.Fragment>;
  }

  onClickAccessibilityDisclaimer = () => {
    this.props.history.push("/accessibility");
  };

  onClickTerms = () => {
    this.props.history.push("/terms");
  };
  renderFooterComponent = () => {
    return (
      <React.Fragment>
        <div id="footerComponentContainer">
          <Typography className="dullWhite">
            {window.t("footerPrimary")}
          </Typography>
          <Divider
            aria-hidden="true"
            className="marginRight8 marginLeft8 backgroundDullWhiteColor"
            orientation="vertical"
            flexItem
          />
          <Typography className="dullWhite">
            {window.t("footerSecondary")}
          </Typography>
          <Divider
            aria-hidden="true"
            className="marginRight8 marginLeft8 backgroundDullWhiteColor"
            orientation="vertical"
            flexItem
          />
          <Typography className="dullWhite footerLink">
            <Link onClick={this.onClickTerms}>{window.t("terms")}</Link>
          </Typography>
          <Divider
            aria-hidden="true"
            className="marginRight8 marginLeft8 backgroundDullWhiteColor"
            orientation="vertical"
            flexItem
          />
          <Typography className="dullWhite footerLink">
            <Link onClick={this.onClickAccessibilityDisclaimer}>
              {window.t("accessibilityDisclaimer")}
            </Link>
          </Typography>
        </div>
      </React.Fragment>
    );
  };
}
