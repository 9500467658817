import React, { Component } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import CommentNumber from "../commentNumber/commentNumber";
import Button from "@material-ui/core/Button";

export default class CategoryListItem extends Component {
  render() {
    return (
      <React.Fragment>
        <Button
          disableRipple={true}
          fullWidth={true}
          aria-label={`${this.props.data.name} with ${
            this.props.data.activityCount || 0
          } comments`}
          onClick={() => {
            this.props.onClickCategoryListItem &&
              this.props.onClickCategoryListItem(this.props.data);
          }}
        >
          <ListItem
            style={{ background: `${this.props.backgroundColor}` }}
            className="categoryListItemContainer pd-btm-0 reverse"
            {...this.props}
          >
            <ListItemAvatar aria-hidden="true">
              <Avatar
                alt={this.props.data.name}
                src={
                  (this.props.data.icon && this.props.data.icon.original) ||
                  "/public/assets/img/client.png"
                }
                className={`categoryListItemAvatar`}
              />
            </ListItemAvatar>
            <div className="listItemTextContainerCategory reverse">
              <ListItemText
                aria-hidden="true"
                className="categoryListItemText"
                primary={this.props.data.name}
              />
              <CommentNumber
                index={this.props.index}
                value={this.props.data.activityCount || 0}
                text={this.props.t("comments")}
              />
            </div>
          </ListItem>
        </Button>
      </React.Fragment>
    );
  }
}
