import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";

export default class ArenaInputField extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <TextField
          {...this.props}
          className={`arenaInputField ${this.props.className || ""}`}
          multiline={this.props.multiline}
          variant="outlined"
          placeholder={this.props.placeholder}
          onChange={this.props.onChange}
          value={this.props.value}
          inputProps={{
            "aria-label": `Arena Text-Field for ${this.props.ariaLabelText}`,
          }}
        />
      </React.Fragment>
    );
  }
}
