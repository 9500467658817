import React from "react";
import CommonStructure from "../../common/mainStructure/main";
import ClientAndComments from "../categoryListItem/categoryListItem";
import ControlPanel from "../controlPanel/controlPanel";
import { isBrowser, isMobile } from "react-device-detect";
import Auth from "../../common/auth";
import Typography from "@material-ui/core/Typography";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "@material-ui/core/CircularProgress";
export default class ClientsRoot extends CommonStructure {
  constructor(props) {
    super(props);
    this.state = {
      accountsData: [],
      loading: true,
      accountsCount: null,
    };
    this.backgroundImg = "/assets/img/header.png";
  }

  async componentDidMount() {
    let accountDetails = Auth.getAccountDetails();
    this.setState({
      accountDetails,
    });
    try {
      await this.fetchAccounts();
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError(window.t("somethingWentWrong"));
      this.props.history.replace("/404");
      this.setState({
        loading: false,
      });
    }
  }

  fetchAccounts = async () => {
    try {
      let offset = this.state.accountsData.length;

      let accountsResponse = await window.Arena.database.fetchAccounts(offset);
      let accountsCount = accountsResponse.data.count;

      let accountsData = accountsResponse.data.items || [];
      accountsData = this.state.accountsData.concat(accountsData);

      if (accountsData.length < 1) {
        this.setState({
          loading: false,
        });
        return;
      } else {
        let stateAccountData = await this.fetchAccountActivity(accountsData);
        this.setState({
          accountsData: stateAccountData,
          loading: false,
          accountsData: accountsData,
          accountsCount: accountsCount,
        });
      }
    } catch (e) {
      throw e;
    }
  };

  fetchAccountActivity = async (accountData) => {
    let data = [];
    for (let singleAccount of accountData) {
      let activityCount = await window.Arena.database.fetchAccountActivity(
        singleAccount.id
      );
      singleAccount.activityCount = activityCount.data.activityCount;
      data.push(singleAccount);
    }
    return data;
  };

  onClickListItem = async (item) => {
    if (!item.slug) {
      return;
    }
    let accountDetails = await this.getAccountDetails(item.slug);
    Auth.storeAccountDetails(accountDetails);

    this.props.history.push(`/${item.slug}`);
  };

  getAccountDetails = async (slug) => {
    try {
      let accountDetails = await window.Arena.database.fetchAccountDetails(
        slug
      );

      if (!accountDetails || accountDetails.data.items.length < 1) {
        //TODO Do something

        return;
      }
      let accountItems = [];
      for (let item of accountDetails.data.items) {
        if (item.slug === slug) {
          accountItems.push(item);
        }
      }
      return accountItems[0];
    } catch (e) {
      console.error(e);
    }
  };

  renderClientList = () => {
    if (!this.state.accountsData || this.state.accountsData.length < 1) {
      return (
        <div id="accountEmptyContainer">
          <Typography
            variant="h5"
            className="bold blueColor textAlignCenter"
            style={{ marginTop: "16px" }}
          >
            {this.props.t("noAccountsToShow")}
          </Typography>
        </div>
      );
    }
    return (
      <InfiniteScroll
        dataLength={this.state.accountsData.length} //This is important field to render the next data
        next={() => this.fetchAccounts()}
        hasMore={this.state.accountsCount > this.state.accountsData.length}
        loader={
          <div className="textAlignCenter">
            <Spinner
              style={{ marginBottom: "24px" }}
              size="22px"
              style={{ color: "#65D2FC" }}
            />
          </div>
        }
        endMessage={
          <Typography
            variant="subtitle1"
            className="bold blueColor textAlignCenter"
            style={{ marginBottom: "24px" }}
          >
            {window.t("noMoreSlugsToShow")}
          </Typography>
        }
      >
        {this.state.accountsData &&
          this.state.accountsData.map((item, index) => {
            return (
              <div key={index} className="clientsRootListItemContainer">
                <ClientAndComments
                  data={item}
                  onClickCategoryListItem={this.onClickListItem}
                  t={this.props.t}
                />
              </div>
            );
          })}
      </InfiniteScroll>
    );
  };

  renderControlPanel = () => {
    if (isMobile) {
      return (
        <React.Fragment>
          <ControlPanel
            history={this.props.history}
            placement="top"
            t={this.props.t}
          />
        </React.Fragment>
      );
    }
    if (isBrowser) {
      return (
        <React.Fragment>
          <ControlPanel
            history={this.props.history}
            placement="left"
            t={this.props.t}
          />
        </React.Fragment>
      );
    }
  };

  renderTopContent = () => {
    return (
      <React.Fragment>
        <div className="clientsRootTopContent">
          <img
            src="/assets/img/logoArena.png"
            alt="arenaLogo"
            className="logo"
          />
        </div>
      </React.Fragment>
    );
  };

  renderBottomContent = () => {
    return (
      <React.Fragment>
        <div className="clientsRootListContainer reverse">
          {this.renderClientList()}
        </div>
      </React.Fragment>
    );
  };
}
