import React, { Component } from "react";
import ControlPanelButton from "../controlPanelButton/controlPanelButton";
import Auth from "../../common/auth";
import { ReactComponent as EditCategory } from "../../../icons/EditCategory.svg";
import { ReactComponent as AddQuestion } from "../../../icons/AddQuestion.svg";

export default class AdminCreateEditPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountDetails: null,
    };
  }

  componentDidMount() {
    let accountDetails = Auth.getAccountDetails();
    this.setState({
      accountDetails,
    });
  }

  onClickCreateSubject = (e) => {
    e.stopPropagation();
    if (this.props.subjectText) {
      return this.props.openEditMode();
    }
    this.props.history.push(`/${this.state.accountDetails.slug}/edit-subject`);
  };

  onClickCreateCategory = (e) => {
    e.stopPropagation();
    if (this.props.categoryText) {
      return this.props.openEditMode();
    }
    this.props.history.push(
      `/${this.state.accountDetails.slug}/create-categorie`
    );
  };
  shoudlShowCreateCategory = () => {
    if (
      !this.props.subjectText &&
      (window.canAdministerCategory || window.canAdministerSubject)
    ) {
      return true;
    }
    return false;
  };
  shoudlShowSubject = () => {
    if (window.canAdministerCategory || window.canAdministerSubject) {
      return true;
    }
    return false;
  };
  render() {
    let categoryPresent = Boolean(window.categoryCount);
    return (
      <React.Fragment tabIndex={0}>
        <div
          id="userControlPanelContainer"
          style={{ background: `${this.props.backgroundColor}` }}
          className="reverse"
        >
          {this.shoudlShowCreateCategory() && (
            <ControlPanelButton
              history={this.props.history}
              onClick={this.onClickCreateCategory}
              text={
                this.props.categoryText === window.t("editCategory")
                  ? this.props.t("editCategory")
                  : this.props.t("createCategory")
              }
              value={EditCategory}
            />
          )}
          {this.shoudlShowSubject() && (
            <ControlPanelButton
              text={
                this.props.subjectText === window.t("editSubject")
                  ? this.props.t("editSubject")
                  : this.props.t("createSubject")
              }
              onClick={this.onClickCreateSubject}
              value={AddQuestion}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}
