import React, { Component } from "react";
import Pagination from "@material-ui/lab/Pagination";

export default class PaginationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPage: 1,
    };
  }

  handleChange = (event, value) => {
    this.props.handlePageChange(value);
    this.setState({
      selectedPage: value,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="arenaPagination">
          <Pagination
            count={this.props.pageCount}
            page={this.props.selectedPage}
            onChange={this.handleChange}
          />
        </div>
      </React.Fragment>
    );
  }
}
