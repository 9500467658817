import React, { Component } from "react";
import Button from "@material-ui/core/Button";

export default class ArenaButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Button
          className={`sendButtonBorderRadius blueColor ${this.props.extraClass}`}
          size={this.props.size}
          variant="outlined"
          onClick={this.props.onClick}
          {...this.props}
        >
          {this.props.text}
        </Button>
      </React.Fragment>
    );
  }
}
