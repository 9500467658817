import React, { Component } from "react";
import ControlPanelButton from "../controlPanelButton/controlPanelButton";
import Link from "@material-ui/core/Link";
import { ReactComponent as Statistics } from "../../../icons/Statistics.svg";

export default class DownloadReport extends Component {
  render() {
    return (
      <React.Fragment>
        <div
          id="userControlPanelContainer"
          className="downloadContainer"
          style={{ background: `${this.props.backgroundColor}` }}
        >
          <Link>
            <ControlPanelButton
              onClick={this.props.onClickDownloadReport}
              text={window.t("downloadReport")}
              value={Statistics}
            />
          </Link>
        </div>
      </React.Fragment>
    );
  }
}
