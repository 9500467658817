import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

export default class LandingPageContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div
          id="LandingPageContentContainer"
          style={{ background: `${this.props.backgroundColor}` }}
        >
          <Typography variant="h3" className="redColor bold">
            Welcome!
          </Typography>
          <div>
            <div id="paraOneLandingPage">
              <Typography variant="body1" className="dullWhite textAlignEnd">
                {this.props.data.paraOne}
              </Typography>
            </div>
            {this.props.data.paraTwo && (
              <div>
                <Typography variant="body1" className="dullWhite textAlignEnd">
                  {this.props.data.paraTwo}
                </Typography>
              </div>
            )}
            <div id="highlightedTextContainer">
              <Typography className="blueColor bold">
                <Box textAlign="center">{this.props.data.highlighted}</Box>
              </Typography>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
