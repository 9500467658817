import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

export default class SendButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Button
          size={this.props.size}
          variant="outlined"
          className={`sendButton sendButtonBorderRadius blueColor reverse ${this.props.extraClass}`}
          startIcon={<ArrowBackIosIcon />}
          onClick={this.props.onClick}
          {...this.props}
        >
          {this.props.text ? this.props.text : window.t("send")}
        </Button>
      </React.Fragment>
    );
  }
}
